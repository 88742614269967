import React, { Component } from "react";

import "./Home.css";

export default class Sitemap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  render() {
    return (
      <div id="xsg">
        <br />
        <div id="xsgHeader">
          <h1>FarmGround.com Sitemap</h1>
        </div>
        <br />
        <div id="xsgBody">
          <ul>
            <li>
              <a href="https://www.farmground.com/">Home - Featured Listings</a>
            </li>
            <br />
            <li>
              <a href="https://www.farmground.com/for-sale">
                For Sale - Farm, Hunting and Ranch For Sale Real Estate Listings
              </a>
            </li>
            <br />
            <li>
              <a href="https://www.farmground.com/for-rent">
                For Rent - Farm, Hunting and Ranch For Rent Real Estate Listings
              </a>
            </li>
            <br />
            <li>
              <a href="https://www.farmground.com/auctions">
                Auctions - Farm, Hunting and Ranch Online Auction Real Estate
                Listings
              </a>
            </li>
            <br />
            <li>
              <a href="https://www.farmground.com/agents">Real Estate Agents</a>
            </li>
            <br />
            <li>
              <a href="https://www.farmground.com/contactUs">
                Contact FarmGround.com
              </a>
            </li>
            <br />
          </ul>
        </div>
        <div id="xsgFooter">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
