import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3UploadWithId(id, file) {
  const filename = `${id}-${Date.now()}-${file.name}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Delete(filename) {
  await Storage.remove(filename)
    .then(result => console.log(result))
    .catch(err => console.log(err));
}
