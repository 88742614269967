import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { fgFarmByFarmSaleType as FgFarmByFarmSaleType } from "../graphql_custom/queries";
import ListingCardCols from "../components/ListingCardCols";
import "./Auctions.css";

export default class Auctions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      //userId: props.userId,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(FgFarmByFarmSaleType, {
          farmSaleType: "Online Auction",
          filter: {
            farmStatusNumber: {
              lt: 700,
            },
          },
          sortDirection: "DESC",
          limit: 36,
        })
      );

      const farms = graphQLResponse.data.fgFarmByFarmSaleType.items;

      this.setState({ farms });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  render() {
    return (
      <div className="Auctions">
        <h4 className="mb-3">Online Auctions</h4>
        <div className="well well-sm">
          <a href={`/dashboard`}>Create your listing on FarmGround.com!</a>
        </div>
        <hr />
        <Container>
          {!this.state.isLoading ? (
            this.state.farms.length > 0 ? (
              <ListingCardCols
                farms={this.state.farms}
                edit={false}
              ></ListingCardCols>
            ) : (
              <Row className="show-grid">
                <span className="bold">
                  <i>
                    No farms listed yet! Be the first and list your farm now!
                  </i>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              </Row>
            )
          ) : (
            ""
          )}
        </Container>
      </div>
    );
  }
}
