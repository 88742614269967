import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./containers/Home";
import Agents from "./containers/Agents";
import Agent from "./containers/Agent";
import AboutUs from "./containers/AboutUs";
import AboutUsEdit from "./containers/AboutUsEdit";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Auctions from "./containers/Auctions";
import ForSale from "./containers/ForSale";
import ForSaleFilter from "./containers/ForSaleFilter";
import ForRent from "./containers/ForRent";
import ContactUs from "./containers/ContactUs";
import ContactUsConfirm from "./containers/ContactUsConfirm";
import Dashboard from "./containers/Dashboard";
import Farm from "./containers/Farm";
import FarmMap from "./containers/FarmMap";
import FarmAuction from "./containers/FarmAuction";
import FarmAuctionEdit from "./containers/FarmAuctionEdit";
import FarmAuctionBid from "./containers/FarmAuctionBid";
import FarmAuctionBidConfirm from "./containers/FarmAuctionBidConfirm";
import FarmSell from "./containers/FarmSell";
import FarmSellEdit from "./containers/FarmSellEdit";
import FarmRent from "./containers/FarmRent";
import FarmRentEdit from "./containers/FarmRentEdit";
import FarmRentOffer from "./containers/FarmRentOffer";
import FarmRentOfferConfirm from "./containers/FarmRentOfferConfirm";
import NewListing from "./containers/NewListingFree";
import Login from "./containers/Login";
import Seller from "./containers/Seller";
import Signup from "./containers/Signup";
import ForgotPassword from "./containers/ForgotPassword";
import OrgEdit from "./containers/OrgEdit";
import UserEdit from "./containers/UserEdit";
import NotFound from "./containers/NotFound";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AdminRoute from "./components/AdminRoute";
import DashboardAdmin from "./containers/DashboardAdmin";
import TermsConditions from "./containers/TermsConditions";
import Sitemap from "./containers/Sitemap";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/home" exact component={Home} props={childProps} />
    <AppliedRoute
      path="/index.html"
      exact
      component={Home}
      props={childProps}
    />
    <AppliedRoute path="/agents" exact component={Agents} props={childProps} />
    <AppliedRoute
      path="/agent/:id"
      exact
      component={Agent}
      props={childProps}
    />
    <AppliedRoute
      path="/aboutUs"
      exact
      component={AboutUs}
      props={childProps}
    />
    <AdminRoute
      path="/aboutUs/edit"
      exact
      component={AboutUsEdit}
      props={childProps}
    />
    <AppliedRoute
      path="/contactUs"
      exact
      component={ContactUs}
      props={childProps}
    />
    <AppliedRoute
      path="/contactUs/confirm"
      exact
      component={ContactUsConfirm}
      props={childProps}
    />
    <AppliedRoute
      path="/privacy-policy"
      exact
      component={PrivacyPolicy}
      props={childProps}
    />
    />
    <AppliedRoute
      path="/terms-and-conditions"
      exact
      component={TermsConditions}
      props={childProps}
    />
    <AppliedRoute
      path="/auctions"
      exact
      component={Auctions}
      props={childProps}
    />
    <AppliedRoute
      path="/for-sale"
      exact
      component={ForSale}
      props={childProps}
    />
    <AppliedRoute
      path="/for-sale/:id"
      exact
      component={ForSaleFilter}
      props={childProps}
    />
    <AppliedRoute
      path="/for-rent"
      exact
      component={ForRent}
      props={childProps}
    />
    <AppliedRoute
      path="/farm/view/:id"
      exact
      component={Farm}
      props={childProps}
    />
    <AppliedRoute
      path="/listing/:id"
      exact
      component={Farm}
      props={childProps}
    />
    <AppliedRoute
      path="/listing/map/:id"
      exact
      component={FarmMap}
      props={childProps}
    />
    <AppliedRoute
      path="/agent/user/:id"
      exact
      component={Seller}
      props={childProps}
    />
    <AdminRoute
      path="/farm/auction"
      exact
      component={FarmAuction}
      props={childProps}
    />
    <AdminRoute
      path="/farm/auction/:id"
      exact
      component={FarmAuctionEdit}
      props={childProps}
    />
    <AdminRoute
      path="/farm/auction/bid/:id"
      exact
      component={FarmAuctionBid}
      props={childProps}
    />
    <AdminRoute
      path="/farm/auction/bid/confirm/:id"
      exact
      component={FarmAuctionBidConfirm}
      props={childProps}
    />
    <AdminRoute
      path="/farm/sell"
      exact
      component={FarmSell}
      props={childProps}
    />
    <AdminRoute
      path="/farm/sell/:id"
      exact
      component={FarmSellEdit}
      props={childProps}
    />
    <AdminRoute
      path="/farm/rent"
      exact
      component={FarmRent}
      props={childProps}
    />
    <AdminRoute
      path="/new/listing"
      exact
      component={NewListing}
      props={childProps}
    />
    <AdminRoute
      path="/farm/rent/:id"
      exact
      component={FarmRentEdit}
      props={childProps}
    />
    <AdminRoute
      path="/farm/rent/offer/:id"
      exact
      component={FarmRentOffer}
      props={childProps}
    />
    <AdminRoute
      path="/farm/rent/offer/confirm/:id"
      exact
      component={FarmRentOfferConfirm}
      props={childProps}
    />
    <AdminRoute
      path="/agency/profile"
      exact
      component={OrgEdit}
      props={childProps}
    />
    <AdminRoute
      path="/user/profile"
      exact
      component={UserEdit}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signUp"
      exact
      component={Signup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/forgot-password"
      exact
      component={ForgotPassword}
      props={childProps}
    />
    <AdminRoute
      path="/dashboard"
      exact
      component={Dashboard}
      props={childProps}
    />
    <AdminRoute
      path="/dashboard/admin"
      exact
      component={DashboardAdmin}
      props={childProps}
    />
    <AppliedRoute
      path="/sitemap"
      exact
      component={Sitemap}
      props={childProps}
    />
    <Route path="*" status={404} component={Home} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
