import React from "react";
import { Form, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import AWS from "aws-sdk";
import config from "../config";
import "./ContactSeller.css";
import ReCAPTCHA from "react-google-recaptcha";

class ContactSellerFarm extends React.Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    var preName = "Seller";
    if (this.props.agent) {
      preName = "Agent";
    }

    this.state = {
      isLoading: null,
      isSent: false,
      name: "",
      phone: "",
      email: "",
      message: "",
      signUp: "",
      preName,
    };
  }

  validateForm() {
    return (
      this.state.name.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.email.length > 0 &&
      this.state.message.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    // Initialize the Amazon Cognito credentials provider
    AWS.config.region = config.REGION; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.IDENTITY_POOL_ID,
    });

    if (this.recaptchaRef) {
      const recaptchaValue = this.recaptchaRef.current.getValue();

      if (!recaptchaValue || recaptchaValue == null) {
        alert("Please complete the reCaptcha dialog! - " + recaptchaValue);
        this.setState({ isLoading: false });
        return;
      }
    }

    // Create sendEmail params
    var sesParams = {
      Destination: {
        /* required */
        // CcAddresses: [
        //   "EMAIL_ADDRESS"
        //   /* more items */
        // ],
        ToAddresses: [this.props.farm.farmAgentEmail],
        BccAddresses: ["contact@farmground.com"],
      },
      Message: {
        /* required */
        Body: {
          /* required */
          Html: {
            Charset: "UTF-8",
            Data:
              "<html><body><h2>You have a new message!</h2>" +
              "<h3>Please use the details below to respond to the person that is interested in your listing that they found on FarmGround.com.</h3>" +
              " Listing: " +
              '<a href="https://www.farmground.com/listing/' +
              this.props.farm.farmKey +
              '">https://www.farmground.com/listing/' +
              this.props.farm.farmKey +
              "</a>" +
              "<br />" +
              " Name: " +
              this.state.name +
              "<br />" +
              " Phone: " +
              this.state.phone +
              "<br />" +
              " Email: " +
              this.state.email +
              "<br />" +
              " Message: " +
              this.state.message +
              "<br /><br />" +
              "</body></html>",
          },
          Text: {
            Charset: "UTF-8",
            Data:
              "Great news!  You have a new message!\n\n" +
              "Please use the details below to respond to the person that is interested in your listing that they found on FarmGround.com.\n\n" +
              " Listing: " +
              "https://www.farmground.com/listing/" +
              this.props.farm.farmKey +
              "\n\n" +
              " Name: " +
              this.state.name +
              "\n" +
              " Phone: " +
              this.state.phone +
              "\n" +
              " Email: " +
              this.state.email +
              "\n" +
              " Message: " +
              this.state.message +
              "\n\n",
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data:
            config.ORG_NAME +
            " - Contact " +
            this.state.preName +
            " - " +
            this.props.farm.farmName,
        },
      },
      Source: config.ORG_FROM_EMAIL /* required */,
      ReplyToAddresses: [
        this.state.email,
        /* more items */
      ],
    };

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
      .sendEmail(sesParams)
      .promise();

    // Handle promise's fulfilled/rejected states
    sendPromise
      .then(function (data) {
        console.log(data.MessageId);
      })
      .catch(function (err) {
        console.error(err, err.stack);
      });

    this.setState({ isLoading: false, isSent: true });
  };

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  render() {
    return (
      <div className="ContactSeller">
        <div className="seller-section scrollToForm">
          <div className="section-title">Contact {this.state.preName}</div>
          <hr />
          <p className="contact-seller-name">
            {this.props.farm.farmAgentName + " "}
            {this.props.agent ? (
              <span>
                (
                <a
                  className="actionLinkClient"
                  href={"/agent/" + this.props.farm.orgKey}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Agency
                </a>
                )
              </span>
            ) : (
              ""
            )}
          </p>

          {this.state.isSent ? (
            <strong>
              You message has been sent!
              <br />
              <br />
            </strong>
          ) : (
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>Your Name:</Form.Label>
                <FormControl
                  type="text"
                  value={this.state.name}
                  placeholder="Enter your name"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Phone:</Form.Label>
                <FormControl
                  type="text"
                  value={this.state.phone}
                  placeholder="Enter your phone number"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email Address:</Form.Label>
                <FormControl
                  type="text"
                  value={this.state.email}
                  placeholder="Enter your email address"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Message:</Form.Label>
                <FormControl
                  as="textarea"
                  rows="3"
                  value={this.state.message}
                  placeholder="Enter your message"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <ReCAPTCHA
                sitekey={config.RECAPTCHA_SITE_KEY}
                ref={this.recaptchaRef}
              />
              <LoaderButton
                block
                bsstyle="primary"
                bssize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Send Message"
                loadingText="Sending..."
              />
            </Form>
          )}

          <div className="contactFormRow">
            <p className="contact-legal-disclaimer">
              By clicking the button, you agree to FarmGround's{" "}
              <a href="/terms-and-conditions">Terms of Use</a> and{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactSellerFarm;
