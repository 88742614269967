import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { listFgOrgs as ListFgOrgs } from "../graphql_custom/queries";
import "./Agents.css";

export default class Agents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(ListFgOrgs, {})
      );

      // Sort by Rank DESC
      graphQLResponse.data.listFgOrgs.items.sort(function (a, b) {
        return a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0;
      });

      const orgs = graphQLResponse.data.listFgOrgs.items;
      this.setState({ orgs });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  renderFarmsCols(orgs) {
    return [{}].concat(orgs).map((org, i) =>
      i !== 0 ? (
        <Col xs={12} md={4} className="card-col" key={org.id}>
          <a href={`/agent/${org.key}`} title="Click to view agent details...">
            <Card key={i}>
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(org.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                    src={this.getImageUrl(org.attachment)}
                    alt="View more details..."
                    responsive="true"
                  />
                ) : (
                    <Card.Img variant="top" src="./images/carousel.png" alt="" />
                  )}
              </span>
              <Card.Body>
                <hr />
                <span className="bold">
                  <h6>{org.name}</h6>
                  <span>{org.description}</span>
                </span>
                <hr />
                <span className="property-card--address-pin-and-address">
                  <span className="property-card--address-pin"></span>
                  <span className="property-card--address">
                    <address className="location">
                      <span>
                        {org.address1}
                        <br />
                        {org.city + ", " + org.state + " " + org.zip}
                      </span>
                    </address>
                  </span>
                  <span>{"(Licensed In: " + org.stateLicensedIn + ")"}</span>
                </span>
              </Card.Body>
            </Card>
          </a>
        </Col>
      ) : (
          ""
        )
    );
  }

  render() {
    return (
      <div className="Agents">
        <h4 className="mb-3">Our Preferred Agents</h4>
        <div className="well well-sm">
          <a href={`/dashboard`}>Add your Agency to our preferred list now, It's FREE!</a>
        </div>
        <hr />
        <Container>
          <Row className="show-grid">
            {!this.state.isLoading && this.renderFarmsCols(this.state.orgs)}
          </Row>
        </Container>
        <br />
      </div>
    );
  }
}
