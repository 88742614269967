import React, { Component } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { getFgFarm as GetFgFarm } from "../graphql/queries";
import { getFgUser as GetFgUser } from "../graphql/queries";
import { getFgState as GetFgState } from "../graphql/queries";
import { updateFgFarm as UpdateFgFarm } from "../graphql/mutations";
import { s3UploadWithId } from "../libs/awsLib";
import {
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Multiselect } from "multiselect-react-dropdown";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./FarmRent.css";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export default class FarmSellEdit extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.multiselectFarmTypeRef = React.createRef();

    this.state = {
      isLoading: false,
      isDeleting: null,
      editorState: EditorState.createEmpty(),
      counties: [],
      attachment: null,
      farmName: "",
      farmTags: [],
      farmTypeOptions: [
        { name: "Tillable", id: 1 },
        { name: "Pasture", id: 2 },
        { name: "Hunting", id: 3 },
        { name: "Recreational", id: 4 },
        { name: "Timber", id: 5 },
        { name: "Pond/Lake", id: 6 },
        { name: "Creak/River", id: 11 },
        { name: "Home", id: 7 },
        { name: "Shed/Buildings", id: 8 },
        { name: "Grain Bin(s)", id: 9 },
        { name: "Commercial", id: 10 },
        { name: "Tiled", id: 12 },
        { name: "Terraces", id: 13 },
      ],
      farmDetails:
        "<p><strong>Description:</strong></p><p>text goes here or remove heading</p><p><strong>Legal Description:</strong> text goes here or remove heading</p><p><strong>Home Description:</strong> text goes here or remove heading</p><p><strong>Building Desc:</strong> text goes here or remove heading</p><p><strong>Crop History:</strong> text goes here or remove heading</p><p><strong>Lease Info:</strong> text goes here or remove heading</p><p><strong>Tillable Acres:</strong> text goes here or remove heading</p><p><strong>FSA Information:</strong> text goes here or remove heading</p><p><strong>CRP Acres:</strong> text goes here or remove heading</p><p><strong>CRP Rent/Acre:</strong> text goes here or remove heading</p><p><strong>CSR2:</strong> text goes here or remove heading</p><p><strong>Soil Productivity:</strong> text goes here or remove heading</p><p><strong>Directions:</strong> text goes here or remove heading</p><p><strong>Township/Sctn#:</strong> text goes here or remove heading</p><p><strong>Possession Info:</strong> text goes here or remove heading</p><p><strong>Closing Info: </strong>text goes here or remove heading</p><p><strong>Earnest Money:</strong> text goes here or remove heading</p><p><strong>Other Info:</strong> text goes here or remove heading</p>",
      farmTotalAcres: "",
      farmAddress: "",
      farmCity: "",
      farmState: "",
      farmPostalCd: "",
      farmCounty: "",
      farmGoogleMapLink: "",
      farmStatusNumber: 0,
      farmViewed: "",
      attachments: null,
      attachmentURL: null,
      farmSaleType: "", // Auction, For Sale, For Rent
      farmListingNumber: "",
      farmStartDateTime: "",
      farmEndDateTime: "",
      farmAgentName: "",
      farmAgentLicensedIn: [],
      farmAgentWorkPhone: "",
      farmAgentCellPhone: "",
      farmAgentEmail: "",
      farmAgentAddress: "",
      farmPhotos: null,
      farmPhotoFiles: null,
      farmDocFiles: null,
      farmDocs: null,
      farmVideoFiles: null,
      farmVideos: null,
      orgId: "",
      orgKey: "",
      userId: "",
      userKey: "",
    };
  }

  async UNSAFE_componentWillMount() {
    try {
      this.setState({ isLoading: true });

      let attachmentURL;

      const graphQLResponse = await API.graphql(
        graphqlOperation(GetFgFarm, { id: this.props.match.params.id })
      );
      const frm = graphQLResponse.data.getFgFarm;

      var {
        farmName,
        farmType,
        farmTags,
        farmDetails,
        farmTotalAcres,
        farmAddress,
        farmCity,
        farmState,
        farmPostalCd,
        farmCounty,
        farmGoogleMapLink,
        farmStatusNumber,
        farmViewed,
        attachment,
        farmListingNumber,
        farmStartDateTime,
        farmEndDateTime,
        farmAgentName,
        farmAgentLicensedIn,
        farmAgentWorkPhone,
        farmAgentCellPhone,
        farmAgentEmail,
        farmAgentAddress,
        farmPhotos,
        farmDocs,
        farmVideos,
        adLevel,
        orgId,
        orgKey,
        userId,
        userKey,
      } = frm;

      if (this.isEmptyOrSpaces(farmName)) {
        const userResponse = await API.graphql(
          graphqlOperation(GetFgUser, { id: this.props.userId })
        );

        if (userResponse.data.getFgUser !== null) {
          const usr = userResponse.data.getFgUser;

          var {
            key,
            email,
            phone,
            givenName,
            familyName,
            address1,
            city,
            state,
            zip,
          } = usr;

          orgId = usr.orgId;
          orgKey = usr.orgKey;

          farmDetails =
            "<p><strong>Description:</strong></p><p>text goes here or remove heading</p><p><strong>Legal Description:</strong> text goes here or remove heading</p><p><strong>Home Description:</strong> text goes here or remove heading</p><p><strong>Building Desc:</strong> text goes here or remove heading</p><p><strong>Crop History:</strong> text goes here or remove heading</p><p><strong>Lease Info:</strong> text goes here or remove heading</p><p><strong>Tillable Acres:</strong> text goes here or remove heading</p><p><strong>FSA Information:</strong> text goes here or remove heading</p><p><strong>CRP Acres:</strong> text goes here or remove heading</p><p><strong>CRP Rent/Acre:</strong> text goes here or remove heading</p><p><strong>CSR2:</strong> text goes here or remove heading</p><p><strong>Soil Productivity:</strong> text goes here or remove heading</p><p><strong>Directions:</strong> text goes here or remove heading</p><p><strong>Township/Sctn#:</strong> text goes here or remove heading</p><p><strong>Possession Info:</strong> text goes here or remove heading</p><p><strong>Closing Info: </strong>text goes here or remove heading</p><p><strong>Earnest Money:</strong> text goes here or remove heading</p><p><strong>Other Info:</strong> text goes here or remove heading</p>";
          farmAgentName = givenName + " " + familyName;
          farmAgentWorkPhone = this.isEmptyOrSpaces(phone) ? "" : phone;
          farmAgentCellPhone = this.isEmptyOrSpaces(phone) ? "" : phone;
          farmAgentEmail = this.isEmptyOrSpaces(email) ? "" : email;
          farmAgentAddress = this.isEmptyOrSpaces(address1)
            ? ""
            : address1 + ", " + city + ", " + state + " " + zip;
          userKey = key;
          farmStatusNumber = 200;
        }
      }

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      if (adLevel && adLevel === "MAX") {
        var farmPhotosAllowed = 32;
        if (farmPhotos && Array.isArray(farmPhotos)) {
          farmPhotosAllowed = farmPhotosAllowed - farmPhotos.length;
        }

        var farmDocsAllowed = 12;
        if (farmDocs && Array.isArray(farmDocs)) {
          farmDocsAllowed = farmDocsAllowed - farmDocs.length;
        }

        var farmVideosAllowed = 1;
        if (farmVideos && Array.isArray(farmVideos)) {
          farmVideosAllowed = farmVideosAllowed - farmVideos.length;
        }
      } else if (adLevel && adLevel === "PLUS") {
        var farmPhotosAllowed = 18;
        if (farmPhotos && Array.isArray(farmPhotos)) {
          farmPhotosAllowed = farmPhotosAllowed - farmPhotos.length;
        }

        var farmDocsAllowed = 6;
        if (farmDocs && Array.isArray(farmDocs)) {
          farmDocsAllowed = farmDocsAllowed - farmDocs.length;
        }

        var farmVideosAllowed = 0;
        if (farmVideos && Array.isArray(farmVideos)) {
          farmVideosAllowed = farmVideosAllowed - farmVideos.length;
        }
      } else {
        var farmPhotosAllowed = 0;
        var farmDocsAllowed = 0;
        var farmVideosAllowed = 0;
      }

      var selectedFarmTags = [];
      if (farmTags) {
        for (var i = 0; i < farmTags.length; i++) {
          var obj = farmTags[i];

          var ion = obj.indexOf("name=") + 5;
          var ioi = obj.indexOf(", id=") + 5;

          var name = obj.substring(ion, ioi - 5);
          var id = obj.substring(ioi, obj.length - 1);

          var jsonObj = { name: name, id: parseInt(id) };

          selectedFarmTags.push(jsonObj);
        }

        farmTags = selectedFarmTags;
      }

      if (farmState && farmState.length > 1) {
        const stateResponse = await API.graphql(
          graphqlOperation(GetFgState, { id: farmState })
        );

        if (stateResponse.data.getFgState !== null) {
          const state = stateResponse.data.getFgState;

          const { counties } = state;

          this.setState({
            counties,
          });
        }
      }

      //Editor
      const blocksFromHtml = htmlToDraft(farmDetails);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      this.setState({
        editorState: editorState,
        frm,
        farmName: this.isEmptyOrSpaces(farmName) ? "" : farmName,
        farmType: this.isEmptyOrSpaces(farmType) ? "" : farmType,
        farmTags: this.isEmptyOrSpaces(farmTags) ? [] : selectedFarmTags,
        farmDetails: this.isEmptyOrSpaces(farmDetails) ? "" : farmDetails,
        farmTotalAcres: this.isEmptyOrSpaces(farmTotalAcres)
          ? ""
          : farmTotalAcres,
        farmAddress: this.isEmptyOrSpaces(farmAddress) ? "" : farmAddress,
        farmCity: this.isEmptyOrSpaces(farmCity) ? "" : farmCity,
        farmState: this.isEmptyOrSpaces(farmState) ? "" : farmState,
        farmPostalCd: this.isEmptyOrSpaces(farmPostalCd) ? "" : farmPostalCd,
        farmCounty: this.isEmptyOrSpaces(farmCounty) ? "" : farmCounty,
        farmGoogleMapLink: this.isEmptyOrSpaces(farmGoogleMapLink)
          ? ""
          : farmGoogleMapLink,
        farmViewed: this.isEmptyOrSpaces(farmViewed) ? "" : farmViewed + 1,
        farmSaleType: "For Rent",
        farmStatusNumber: this.isEmptyOrSpaces(farmStatusNumber)
          ? 200
          : farmStatusNumber,
        farmListingNumber: this.isEmptyOrSpaces(farmListingNumber)
          ? ""
          : farmListingNumber,
        farmStartDateTime: this.isEmptyOrSpaces(farmStartDateTime)
          ? ""
          : farmStartDateTime,
        farmEndDateTime: this.isEmptyOrSpaces(farmEndDateTime)
          ? ""
          : farmEndDateTime,
        farmAgentName: this.isEmptyOrSpaces(farmAgentName) ? "" : farmAgentName,
        farmAgentLicensedIn: this.isEmptyOrSpaces(farmAgentLicensedIn)
          ? ""
          : farmAgentLicensedIn,
        farmAgentWorkPhone: this.isEmptyOrSpaces(farmAgentWorkPhone)
          ? ""
          : farmAgentWorkPhone,
        farmAgentCellPhone: this.isEmptyOrSpaces(farmAgentCellPhone)
          ? ""
          : farmAgentCellPhone,
        farmAgentEmail: this.isEmptyOrSpaces(farmAgentEmail)
          ? ""
          : farmAgentEmail,
        farmAgentAddress: this.isEmptyOrSpaces(farmAgentAddress)
          ? ""
          : farmAgentAddress,
        farmPhotos: this.isEmptyOrSpaces(farmPhotos) ? "" : farmPhotos,
        farmDocs: this.isEmptyOrSpaces(farmDocs) ? "" : farmDocs,
        farmVideos: this.isEmptyOrSpaces(farmVideos) ? "" : farmVideos,
        farmPhotosAllowed,
        farmDocsAllowed,
        farmVideosAllowed,
        adLevel: this.isEmptyOrSpaces(adLevel) ? "" : adLevel,
        attachmentURL,
        orgId,
        userId,
        userKey,
        orgKey,
      });

      this.setState({ isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  validateForm() {
    return (
      this.state.farmName.length > 0 &&
      this.state.farmTags &&
      this.state.farmTags.length > 0 &&
      this.state.farmTotalAcres.length > 0 &&
      this.state.farmCounty.length > 0 &&
      this.state.farmAddress.length > 0 &&
      this.state.farmCity.length > 0 &&
      this.state.farmState.length > 0 &&
      this.state.farmPostalCd.length > 0 &&
      this.state.farmAgentName.length > 0 &&
      this.state.farmAgentEmail.length > 0 &&
      this.state.farmStatusNumber > 0 &&
      this.state.farmStartDateTime &&
      this.state.farmStartDateTime.length > 0 &&
      this.state.farmEndDateTime &&
      this.state.farmEndDateTime.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleStateChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });

    try {
      API.graphql(
        graphqlOperation(GetFgState, { id: event.target.value })
      ).then((stateResponse) => {
        if (stateResponse.data.getFgState !== null) {
          const state = stateResponse.data.getFgState;

          const { counties } = state;

          this.setState({
            counties,
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleFileChange = (event) => {
    this.file = event.target.files[0];
  };

  handleMultiSelectChange = (e) => {
    var options = e.target.options;

    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [e.target.id]: value });
  };

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  handleInit() {}

  handleSubmit = async (farm) => {
    let attachment;
    farm.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    if (this.state.farmPhotoFiles && this.state.farmPhotoFiles.length > 0) {
      var photoAttchmnts = [this.state.farmPhotoFiles.length];
      var p;
      for (p = 0; p < this.state.farmPhotoFiles.length; p++) {
        if (this.state.farmPhotoFiles[p]) {
          photoAttchmnts[p] = await s3UploadWithId(
            this.props.match.params.id.replace(/[^0-9]/g, ""),
            this.state.farmPhotoFiles[p]
          );
        }
      }
      if (this.state.farmPhotos && this.state.farmPhotos.length > 0) {
        this.state.farmPhotos = this.state.farmPhotos.concat(photoAttchmnts);
      } else {
        this.state.farmPhotos = photoAttchmnts;
      }
    }

    if (this.state.farmDocFiles && this.state.farmDocFiles.length > 0) {
      var docAttchmnts = [this.state.farmDocFiles.length];
      var d;
      for (d = 0; d < this.state.farmDocFiles.length; d++) {
        if (this.state.farmDocFiles[d]) {
          docAttchmnts[d] = await s3UploadWithId(
            this.props.match.params.id.replace(/[^0-9]/g, ""),
            this.state.farmDocFiles[d]
          );
        }
      }
      if (this.state.farmDocs && this.state.farmDocs.length > 0) {
        this.state.farmDocs = this.state.farmDocs.concat(docAttchmnts);
      } else {
        this.state.farmDocs = docAttchmnts;
      }
    }

    if (this.state.farmVideoFiles && this.state.farmVideoFiles.length > 0) {
      var vidAttchmnts = [this.state.farmVideoFiles.length];
      var v;
      for (v = 0; v < this.state.farmVideoFiles.length; v++) {
        if (this.state.farmVideoFiles[v]) {
          vidAttchmnts[v] = await s3UploadWithId(
            this.props.match.params.id.replace(/[^0-9]/g, ""),
            this.state.farmVideoFiles[v]
          );
        }
      }
      if (this.state.farmVideos && this.state.farmVideos.length > 0) {
        this.state.farmVideos = this.state.farmVideos.concat(vidAttchmnts);
      } else {
        this.state.farmVideos = vidAttchmnts;
      }
    }

    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    const markup = draftToHtml(rawContentState);

    try {
      if (this.file) {
        attachment = this.file
          ? await s3UploadWithId(
              this.props.match.params.id.replace(/[^0-9]/g, ""),
              this.file
            )
          : null;
      }

      await API.graphql(
        graphqlOperation(UpdateFgFarm, {
          input: {
            id: this.props.match.params.id,
            attachment,
            farmName: this.isEmptyOrSpaces(this.state.farmName)
              ? null
              : this.state.farmName,
            farmTags:
              this.multiselectFarmTypeRef.current.getSelectedItems() &&
              this.multiselectFarmTypeRef.current.getSelectedItems().length
                ? this.multiselectFarmTypeRef.current.getSelectedItems()
                : null,
            farmTotalAcres: this.isEmptyOrSpaces(this.state.farmTotalAcres)
              ? null
              : this.state.farmTotalAcres,
            farmDetails: this.isEmptyOrSpaces(markup) ? null : markup,
            farmAddress: this.isEmptyOrSpaces(this.state.farmAddress)
              ? null
              : this.state.farmAddress,
            farmCity: this.isEmptyOrSpaces(this.state.farmCity)
              ? null
              : this.state.farmCity,
            farmState: this.isEmptyOrSpaces(this.state.farmState)
              ? null
              : this.state.farmState,
            farmPostalCd: this.isEmptyOrSpaces(this.state.farmPostalCd)
              ? null
              : this.state.farmPostalCd,
            farmCounty: this.isEmptyOrSpaces(this.state.farmCounty)
              ? null
              : this.state.farmCounty,
            farmGoogleMapLink: this.isEmptyOrSpaces(
              this.state.farmGoogleMapLink
            )
              ? null
              : this.state.farmGoogleMapLink,
            farmStatusNumber: this.isEmptyOrSpaces(this.state.farmStatusNumber)
              ? null
              : this.state.farmStatusNumber,
            farmSaleType: "For Rent",
            farmListingNumber: this.isEmptyOrSpaces(
              this.state.farmListingNumber
            )
              ? null
              : this.state.farmListingNumber,
            farmStartDateTime: this.isEmptyOrSpaces(
              this.state.farmStartDateTime
            )
              ? null
              : this.state.farmStartDateTime,
            farmEndDateTime: this.isEmptyOrSpaces(this.state.farmEndDateTime)
              ? null
              : this.state.farmEndDateTime,
            farmAgentName: this.isEmptyOrSpaces(this.state.farmAgentName)
              ? null
              : this.state.farmAgentName,
            farmAgentLicensedIn: this.isEmptyOrSpaces(
              this.state.farmAgentLicensedIn
            )
              ? null
              : this.state.farmAgentLicensedIn,
            farmAgentWorkPhone: this.isEmptyOrSpaces(
              this.state.farmAgentWorkPhone
            )
              ? null
              : this.state.farmAgentWorkPhone,
            farmAgentCellPhone: this.isEmptyOrSpaces(
              this.state.farmAgentCellPhone
            )
              ? null
              : this.state.farmAgentCellPhone,
            farmAgentEmail: this.isEmptyOrSpaces(this.state.farmAgentEmail)
              ? null
              : this.state.farmAgentEmail,
            farmAgentAddress: this.isEmptyOrSpaces(this.state.farmAgentAddress)
              ? null
              : this.state.farmAgentAddress,
            farmPhotos: this.isEmptyOrSpaces(this.state.farmPhotos)
              ? null
              : this.state.farmPhotos,
            farmDocs: this.isEmptyOrSpaces(this.state.farmDocs)
              ? null
              : this.state.farmDocs,
            farmVideos: this.isEmptyOrSpaces(this.state.farmVideos)
              ? null
              : this.state.farmVideos,
            farmKey: this.state.farmName
              ? (
                  this.state.farmName
                    .replace(/[^A-Za-z0-9\s\/]/g, "")
                    .replace(/\s+/g, "-")
                    .replace(/\//g, "-") +
                  "-" +
                  this.props.match.params.id.replace(/[^0-9]/g, "")
                ).replace(/,/g, "")
              : null,
            userKey: this.isEmptyOrSpaces(this.state.userKey)
              ? null
              : this.state.userKey,
            orgId: this.isEmptyOrSpaces(this.state.orgId)
              ? null
              : this.state.orgId,
            orgKey: this.isEmptyOrSpaces(this.state.orgKey)
              ? null
              : this.state.orgKey,
            lastUpdatedAt: new Date().toISOString(),
          },
        })
      );

      this.props.history.push("/dashboard");
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  deletePhoto(fileName) {
    const farmPhotos = this.state.farmPhotos.filter((i) => i !== fileName);
    const farmPhotosAllowed = this.state.farmPhotosAllowed + 1;
    this.setState({ farmPhotos, farmPhotosAllowed });
  }

  deleteDoc(docName) {
    const farmDocs = this.state.farmDocs.filter((i) => i !== docName);
    const farmDocsAllowed = this.state.farmDocsAllowed + 1;
    this.setState({ farmDocs, farmDocsAllowed });
  }

  deleteVideo(vidName) {
    const farmVideos = this.state.farmVideos.filter((i) => i !== vidName);
    const farmVideosAllowed = this.state.farmVideosAllowed + 1;
    this.setState({ farmVideos, farmVideosAllowed });
  }

  renderOptions(items) {
    return [{}].concat(items).map((item, i) =>
      i !== 0 ? (
        <option value={item} key={item}>
          {item}
        </option>
      ) : (
        ""
      )
    );
  }

  renderFarmPhotos(photos) {
    return [{}].concat(photos).map((photo, i) =>
      i !== 0 ? (
        <Col key={i} xs={6} md={3}>
          <a
            href={this.getImageUrl(photo)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              key={i}
              src={this.getImageUrl(photo)}
              variant="top"
              className="img-fluid img-max-h-300"
              alt={photo}
              responsive="true"
            />
          </a>
          <br />
          <Button
            type="button"
            onClick={this.deletePhoto.bind(this, photo)}
            variant="danger"
            size="sm"
            className="buttonSpace photo-delete-btn"
          >
            Delete
          </Button>
        </Col>
      ) : (
        ""
      )
    );
  }

  renderFarmDocs(docs) {
    return [{}].concat(docs).map((doc, i) =>
      i !== 0 ? (
        <Col key={i} xs={4} md={2} className="txt-center">
          <a
            href={this.getImageUrl(doc)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              key={i}
              src={"/images/PDF-download-icon.jpg"}
              variant="top"
              className="img-fluid img-center"
              alt={doc}
              responsive="true"
            />
            <small>{doc.split("-", 3)[2]}</small>
          </a>
          <br />
          <Button
            type="button"
            onClick={this.deleteDoc.bind(this, doc)}
            variant="outline-danger"
            size="sm"
            className="buttonSpace"
          >
            Delete
          </Button>
        </Col>
      ) : (
        ""
      )
    );
  }

  renderFarmVideos(vids) {
    return [{}].concat(vids).map((vid, i) =>
      i !== 0 ? (
        <Col key={i} xs={6} md={3} className="txt-center">
          <a
            href={this.getImageUrl(vid)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              key={i}
              src={"/images/mp4.png"}
              variant="top"
              className="img-fluid img-center"
              width="80"
              alt={vid}
              responsive="true"
            />
            <small>{vid.split("-", 3)[2]}</small>
          </a>
          <br />
          <Button
            type="button"
            onClick={this.deleteVideo.bind(this, vid)}
            variant="outline-danger"
            size="sm"
            className="buttonSpace"
          >
            Delete
          </Button>
        </Col>
      ) : (
        ""
      )
    );
  }

  render() {
    return (
      <div className="FarmRent">
        <h3>For Rent - {this.state.farmName}</h3>
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm>
                <Form.Group controlId="farmName">
                  <Form.Label>*Listing Name:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmName}
                    placeholder="Enter the farm name"
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    Example: 150 Acre Farm with Modern 4 Bedroom Home
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmStatusNumber">
                  <Form.Label>*Listing Status:</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.farmStatusNumber}
                    onChange={this.handleChange}
                  >
                    <option value="0">Choose one...</option>
                    <option value="200">For Rent</option>
                    <option value="400">Rental Pending</option>
                    <option value="600">Rented</option>
                    <option value="700">Hide Listing</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmTags">
                  <Form.Label>*Tags:</Form.Label>
                  <Multiselect
                    options={this.state.farmTypeOptions}
                    selectedValues={this.state.farmTags}
                    displayValue="name"
                    ref={this.multiselectFarmTypeRef}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmTotalAcres">
                  <Form.Label>*Total Acres:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmTotalAcres}
                    placeholder="Enter the farm total acres"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="farmType">
                  <Form.Label>*Listing Details:</Form.Label>
                  <Form.Text className="text-muted">
                    Below is a sample of some items you might want to include.
                    Please update, add and delete as you feel fit!
                  </Form.Text>
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "link",
                        "remove",
                        "history",
                      ],
                      blockType: {
                        inDropdown: true,
                        options: ["Normal", "H6", "Blockquote"],
                      },
                      fontSize: {
                        options: [10, 11, 12, 14, 16, 18],
                      },
                      fontFamily: {
                        options: ["Arial", "Verdana"],
                      },
                      list: {
                        inDropdown: true,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                        options: ["left", "center", "right", "justify"],
                      },
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmAddress">
                  <Form.Label>*Street Address:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmAddress}
                    placeholder="Enter the street address"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmCity">
                  <Form.Label>*City:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmCity}
                    placeholder="Enter city the farm is located"
                    onChange={this.handleChange}
                  />
                </Form.Group>{" "}
              </Col>
              <Col sm>
                <Form.Group controlId="farmState">
                  <Form.Label>*State:</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.farmState}
                    onChange={this.handleStateChange}
                  >
                    <option value="">Select US State</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmPostalCd">
                  <Form.Label>*Postal Code:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmPostalCd}
                    placeholder="Enter the postal code"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmCounty">
                  <Form.Label>*County:</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.farmCounty}
                    onChange={this.handleChange}
                  >
                    <option value="">
                      Select {this.state.farmState} County
                    </option>
                    {this.state.counties &&
                      this.renderOptions(this.state.counties)}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmGoogleMapLink">
                  <Form.Label>Google Map Link:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmGoogleMapLink}
                    placeholder="Enter the listing Google Map link"
                    onChange={this.handleChange}
                  />{" "}
                  <Form.Text className="text-muted">
                    Example:
                    https://www.google.com/maps/@40.7150055,-94.2380251,1574m/data=!3m1!1e3
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmListingNumber">
                  <Form.Label>Listing Number:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmListingNumber}
                    placeholder="Enter the farm listing number"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmStartDateTime">
                  <Form.Label>*Listing Start Date Time:</Form.Label>
                  <FormControl
                    type="datetime-local"
                    value={this.state.farmStartDateTime}
                    placeholder="Enter the Listing Start Date Time"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmEndDateTime">
                  <Form.Label>*Listing End Date Time:</Form.Label>
                  <FormControl
                    type="datetime-local"
                    value={this.state.farmEndDateTime}
                    placeholder="Enter the Listing Start End Time"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmAgentName">
                  <Form.Label>*Contact/Agent Name:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmAgentName}
                    placeholder="Enter the agent name"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmAgentEmail">
                  <Form.Label>*Contact Email:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmAgentEmail}
                    placeholder="Enter the agent email address"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Form.Group controlId="farmAgentWorkPhone">
                  <Form.Label>Contact Work Phone:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmAgentWorkPhone}
                    placeholder="Enter the agent work phone"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group controlId="farmAgentCellPhone">
                  <Form.Label>Contact Cell Phone:</Form.Label>
                  <FormControl
                    type="text"
                    value={this.state.farmAgentCellPhone}
                    placeholder="Enter the agent cell phone number"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm>
                {!this.isEmptyOrSpaces(this.state.orgId) && (
                  <Form.Group controlId="farmAgentLicensedIn">
                    <Form.Label>State(s) Licensed In:</Form.Label>
                    <Form.Control
                      as="select"
                      multiple
                      value={this.state.farmAgentLicensedIn}
                      onChange={this.handleMultiSelectChange}
                    >
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Form.Control>{" "}
                    <Form.Text className="text-muted">
                      You may Ctrl-click (Windows) or command-click (Mac) to
                      select more than one
                    </Form.Text>
                  </Form.Group>
                )}
              </Col>
              <Col sm></Col>
            </Row>
            <Row>
              <Col sm>
                {this.isEmptyOrSpaces(this.state.attachmentURL) && (
                  <Form.Group>
                    <Form.Label>Listing Primary Photo:</Form.Label>
                  </Form.Group>
                )}
                <Form.Group controlId="file">
                  {!this.isEmptyOrSpaces(this.state.attachmentURL) && (
                    <Form.Group>
                      <Form.Label>Primary Photo: </Form.Label>
                      <br />
                      <img
                        src={this.getImageUrl(this.state.frm.attachment)}
                        alt={this.state.farmName}
                      />
                    </Form.Group>
                  )}
                  <FormControl onChange={this.handleFileChange} type="file" />
                  <Form.Text className="text-muted">
                    Image should be 900 x 500 pixels for best results
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>{" "}
            {(this.state.adLevel === "MAX" ||
              this.state.adLevel === "PLUS") && (
              <Row>
                <Col sm>
                  <hr />
                  <strong>Upload New JPEG Photos:</strong>
                  <br />
                  {this.state.farmPhotosAllowed !== 0 ? (
                    <FilePond
                      allowMultiple={true}
                      allowReplace={true}
                      allowDrop={true}
                      allowBrowse={true}
                      maxFiles={this.state.farmPhotosAllowed}
                      maxFileSize="750KB"
                      acceptedFileTypes="image/jpeg"
                      labelMaxFileSizeExceeded="File is too large"
                      labelMaxFileSize="Maximum file size is {filesize}"
                      imageResizeTargetHeight={500}
                      imageResizeTargetWidth={900}
                      imageCropAspectRatio="9:5"
                      allowFileSizeValidation={true}
                      allowFileTypeValidation={true}
                      allowImageTransform={true}
                      allowImageResize={true}
                      allowImageCrop={true}
                      ref={(ref) => (this.pond = ref)}
                      files={this.state.farmPhotoFiles}
                      oninit={() => this.handleInit()}
                      onupdatefiles={(fileItems) => {
                        // Set current file objects to this.state
                        this.setState({
                          farmPhotoFiles: fileItems.map(
                            (fileItem) => fileItem.file
                          ),
                        });
                      }}
                    ></FilePond>
                  ) : (
                    "You have reached your max photos allowed. You must delete an image before you can upload a new one."
                  )}
                </Col>
              </Row>
            )}
            {this.state.farmPhotos && (
              <Row>{this.renderFarmPhotos(this.state.farmPhotos)}</Row>
            )}{" "}
            {(this.state.adLevel === "MAX" ||
              this.state.adLevel === "PLUS") && (
              <Row>
                <Col sm>
                  <hr />
                  <strong>Upload new PDF Files:</strong>
                  <br />
                  {this.state.farmDocsAllowed !== 0 ? (
                    <FilePond
                      ref={(ref) => (this.pond = ref)}
                      files={this.state.farmDocFiles}
                      allowMultiple={true}
                      maxFileSize="750KB"
                      maxFiles={this.state.farmDocsAllowed}
                      acceptedFileTypes="application/pdf"
                      oninit={() => this.handleInit()}
                      onupdatefiles={(fileItems) => {
                        // Set current file objects to this.state
                        this.setState({
                          farmDocFiles: fileItems.map(
                            (fileItem) => fileItem.file
                          ),
                        });
                      }}
                    ></FilePond>
                  ) : (
                    "You have reached your max documents allowed. You must delete an document before you can upload a new one."
                  )}
                </Col>
              </Row>
            )}
            {this.state.farmDocs && (
              <Row>{this.renderFarmDocs(this.state.farmDocs)}</Row>
            )}{" "}
            {(this.state.adLevel === "MAX" ||
              this.state.adLevel === "PLUS") && (
              <Row>
                <Col sm>
                  <hr />
                  <strong>Upload new MP4 Video Files:</strong>
                  <br />
                  {this.state.farmVideosAllowed !== 0 ? (
                    <FilePond
                      ref={(ref) => (this.pond = ref)}
                      files={this.state.farmVideoFiles}
                      allowMultiple={true}
                      maxFileSize={1}
                      maxFiles={this.state.farmDocsAllowed}
                      acceptedFileTypes="video/mp4"
                      oninit={() => this.handleInit()}
                      onupdatefiles={(videoItems) => {
                        // Set current file objects to this.state
                        this.setState({
                          farmVideoFiles: videoItems.map(
                            (videoItems) => videoItems.file
                          ),
                        });
                      }}
                    ></FilePond>
                  ) : (
                    "You have reached your max videos allowed. You must delete a video before you can upload a new one."
                  )}
                </Col>
              </Row>
            )}
            {this.state.farmVideos && (
              <Row>{this.renderFarmVideos(this.state.farmVideos)}</Row>
            )}
            <Row>
              <Col sm>
                <hr />
                <LoaderButton
                  block
                  bsstyle="primary"
                  bssize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Update Listing"
                  loadingText="Updating.....Please wait..."
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}
