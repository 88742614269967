import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { fgFarmByAdLevel as FgFarmByAdLevel } from "../graphql_custom/queries";
import { Container, Row } from "react-bootstrap";
import ListingCardCols from "../components/ListingCardCols";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(FgFarmByAdLevel, {
          adLevel: "MAX",
          filter: {
            farmStatusNumber: {
              lt: 700,
            },
          },
          sortDirection: "DESC",
          limit: 36,
        })
      );

      console.log("graphQLResponse", graphQLResponse);

      let farms = graphQLResponse.data.fgFarmByAdLevel.items;

      this.setState({ farms: farms });

      if (farms && farms.length > 0) {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  render() {
    return (
      <div className="Home">
        <h3>Welcome to FarmGround.com!</h3>
        <p>
          Browse our active listings today to find the property you are looking
          for. If you are a land owner or real estate agent, reach thousands of
          viewers every day by adding your lisiting to FarmGround.com.
        </p>
        <h5>Featured Listings:</h5>
        <Container>
          <Row className="show-grid">
            {!this.state.isLoading && (
              <ListingCardCols
                farms={this.state.farms}
                edit={false}
              ></ListingCardCols>
            )}
          </Row>
        </Container>
        <br /> <br />
      </div>
    );
  }
}
