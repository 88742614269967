import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { fgOrgByKey as FgOrgByKey } from "../graphql_custom/queries";
import { listFgFarms as ListFgFarms } from "../graphql_custom/queries";
import { Col, Card, Row } from "react-bootstrap";
import ContactSellerAgent from "../components/ContactSellerAgent";
import "./Agent.css";

export default class Agent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const graphQLOrgResponse = await API.graphql(
        graphqlOperation(FgOrgByKey, {
          key: this.props.match.params.id,
        })
      );

      const orgs = graphQLOrgResponse.data.fgOrgByKey.items;

      const {
        id,
        key,
        attachment,
        orgType,
        email,
        phone,
        name,
        description,
        stateLicensedIn,
        address1,
        address2,
        city,
        state,
        zip,
      } = orgs[0];

      let attachmentURL;
      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        id,
        key,
        attachment,
        orgType,
        email,
        phone,
        name,
        description,
        stateLicensedIn,
        address1,
        address2,
        city,
        state,
        zip,
        attachmentURL,
      });

      const graphQLResponse = await API.graphql(
        graphqlOperation(ListFgFarms, {
          filter: {
            orgKey: {
              eq: this.props.match.params.id,
            },
            farmStatusNumber: {
              lt: 700,
            },
          },
          limit: 48,
        })
      );

      const farms = graphQLResponse.data.listFgFarms.items;

      this.setState({ farms: farms });

      this.setState({ isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://hbqbwpq9hf.execute-api.us-east-1.amazonaws.com/production/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  renderFarmsCols(farms) {
    return [{}].concat(farms).map((farm, i) =>
      i !== 0 ? (
        <Col xs={12} md={4} className="card-col" key={farm.id}>
          <a href={`/listing/${farm.farmKey}`} title="Click to view listing...">
            <Card key={i}>
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(farm.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid card-img-top-sm"
                    src={this.getImageUrl(farm.attachment)}
                    alt={"View more details..."}
                    responsive="true"
                  />
                ) : (
                  <Card.Img variant="top" src="./images/carousel.png" alt="" />
                )}
              </span>
              <Card.Body>
                <span className="bold">
                  <h6>
                    {farm.farmStatusNumber === 600
                      ? farm.farmSaleType === "For Rent"
                        ? "Rented"
                        : "Sold"
                      : farm.farmStatusNumber === 400
                      ? farm.farmSaleType === "For Rent"
                        ? "Rental Pending"
                        : "Sale Pending"
                      : farm.farmSaleType === "For Rent" ||
                        farm.farmSaleType === "Online Auction"
                      ? farm.farmSaleType.trim()
                      : farm.farmTotalPrice}
                  </h6>
                  {this.isEmptyOrSpaces(farm.farmTotalAcres)
                    ? "?"
                    : farm.farmTotalAcres.trim()}{" "}
                  Acres
                </span>
                <hr />
                <span className="property-card--address-pin-and-address">
                  <span className="property-card--address-pin"></span>
                  <span className="property-card--address">
                    <span className="location">
                      {farm.farmAddress}
                      <br />
                      {farm.farmCity +
                        ", " +
                        farm.farmState +
                        " " +
                        farm.farmPostalCd}
                      <br />
                      {"(" + farm.farmCounty + " County)"}
                    </span>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </a>
        </Col>
      ) : (
        ""
      )
    );
  }

  renderNoFarms() {
    return (
      <Row>
        <Col xs={12} md={12}>
          No events are currently scheduled for this team.
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className="Agent">
        <Row className="show-grid">
          <Col xs={12} md={8}>
            {!this.state.isLoading && (
              <Card key="agent">
                <span className="card-img-top">
                  {!this.isEmptyOrSpaces(this.state.attachment) ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src={this.getImageUrl(this.state.attachment)}
                      alt={
                        this.isEmptyOrSpaces(this.state.name)
                          ? "View more details..."
                          : "View more details about " + this.state.name.trim()
                      }
                      responsive="true"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      src="./images/carousel.png"
                      alt=""
                    />
                  )}
                </span>
                <Card.Body>
                  <hr />
                  <h6>{this.state.name}</h6>
                  <span className="bold">
                    About:
                    <br />
                  </span>
                  <span>{this.state.description}</span>
                  <hr />
                  <span className="property-card--address-pin-and-address">
                    <span className="property-card--address-pin"></span>
                    <span className="property-card--address">
                      <address className="location">
                        <span>
                          {this.state.address1}
                          <br />
                          {this.state.city +
                            ", " +
                            this.state.state +
                            " " +
                            this.state.zip}
                        </span>
                      </address>
                    </span>
                    <span>
                      <span className="bold">Phone: </span>
                      {" " + this.state.phone + ""}
                      <br />
                      {"(Licensed In: " + this.state.stateLicensedIn + ")"}
                    </span>
                  </span>
                </Card.Body>
              </Card>
            )}
            <Row>
              <Col xs={12} md={12}>
                <br />
                <h5>Agency's Listings:</h5>
              </Col>
              {!this.state.isLoading && this.renderFarmsCols(this.state.farms)}
            </Row>
          </Col>
          <Col xs={12} md={4}>
            {!this.state.isLoading && (
              <ContactSellerAgent
                toEmail={this.state.email}
                toKey={this.state.key}
                toName={this.state.name}
              ></ContactSellerAgent>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
