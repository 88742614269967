import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "./Home.css";

export default class TermsConditions extends Component {
  render() {
    return (
      <div className="Home">
        <div className="padd-top-25">
          <Col xs={12} md={12} className="card-col" key={4}>
            <h1>Welcome to FarmGround.com</h1>
            <hr />

            <div className="elementor-row">
              <div className="elementor-text-editor elementor-clearfix">
                <h1>FarmGround.com Terms and Conditions</h1>
                <p>
                  <em>Last updated: April 5, 2019</em>
                </p>
              </div>
            </div>

            <p>
              By using or accessing FarmGround.com or any subdomain of any such
              websites, any mobile application for such websites or any other
              website operated by us on which these Terms and Conditions are
              posted via a link or otherwise (each referred to herein as a “
              <strong>Site</strong>”), you acknowledge and agree that you are
              subject to the following terms and conditions, as well as our
              Privacy Policy, which also governs your use of the Site, and is
              incorporated by reference (These Terms and Conditions with
              the&nbsp;
              <a href="https://www.FarmGround.com/privacy-policy">
                Privacy Policy
              </a>
              &nbsp;shall be referred to as the “<strong>Terms</strong>“).
              Please read these Terms carefully, as they contain important
              information about limitations of liability and resolution of
              disputes through arbitration rather than court.
            </p>
            <p>
              A farmer’s (Lessee) use of a FarmGround.comsite is bound by the
              terms and conditions on the FarmGround.com Site where finalized
              (and not necessarily the Site or affiliated FarmGround.comsite on
              which the property was originally listed). If there are any
              conflicts between the terms and conditions of the Site you found
              the property on and the terms and conditions of the FarmGround.com
              site you finalize on the terms and conditions of the
              FarmGround.comsite will govern. If you do not fully agree to these
              Terms and any other terms and conditions posted or linked to any
              Site, you are not authorized to access or otherwise use the Site.
              Under these Terms, “<strong>use</strong>” or “
              <strong>access</strong>” of the Site specifically includes any
              direct or indirect access or use of the Site or any cached version
              of the Site and any direct or indirect access or use of any
              information or content on the Site, regardless of how obtained and
              the term “<strong>Site</strong>” includes, without limitation, any
              cached version thereof.
            </p>
            <p>
              Each Site is operated by FarmGround.com, LLC unless otherwise
              specified, the entity controlling the Site you are accessing is
              referred to herein as “FarmGround.com” “<strong>we</strong>,” “
              <strong>us</strong>” or “<strong>our</strong>”. &nbsp;The term “
              <strong>you</strong>” refers to the user visiting the Site,
              listing a property, searching for property on this Site, or our
              customer service agents. &nbsp;You should read through all the
              Terms carefully. The Terms constitute a legally binding agreement
              between you and FarmGround.com. You are not authorized to use this
              Site unless you are at least 18 years of age and able to enter
              into legally binding contracts. &nbsp;We do not knowingly collect
              the information of anyone under the age of 18. If you arrived on
              the Site after having been redirected or otherwise clicking on
              another website, you agree that these Terms shall govern your use
              of this Site.
            </p>
            <ol>
              <li>
                <strong>
                  The Site is a Venue and We are Not a Party to any Lease
                  Agreement or other Transaction Between Users of the Site.
                </strong>
              </li>
            </ol>
            <p>
              We urge all users to be responsible about their use of this Site
              and any transaction entered into as a result of either listing a
              property, selling a property or renting a property. &nbsp;We do
              not own or manage, nor can we contract for, any property listed on
              a Site. The Site provides an online marketplace to allow
              Landowners and property managers who advertise on the Site (each,
              a “<strong>member</strong>”) to offer for sale, rent and/or Lease
              in a variety of pricing formats, a specific property to potential
              buyers/renters or buyers/renters (each, a “<strong>farmer</strong>
              ” and, collectively with a member, the “<strong>users</strong>”).
              “Members” may also include property owners or managers who
              originally advertised their properties on another website and
              their listings have been redistributed on the Site. We also may
              offer other tools or services to allow users to communicate with
              each other and enter into agreements or other transactions.
            </p>
            <p>
              We are not a party to any agreement between users. &nbsp;As a
              result, any part of an actual or potential transaction between a
              farmer and a member, including the quality, condition, safety or
              legality of the properties advertised, the truth or accuracy of
              the listings (including the content thereof or any review relating
              to any farmer or property), the ability of members to buy or rent
              a property or the ability of farmer to contract for properties are
              solely the responsibility of each user. You acknowledge and agree
              that you may be required to enter into one or more separate
              agreements(i.e. agency or real estate brokerage), waivers or terms
              and conditions before listing a property or making an offer for
              one, or entering an agreement or purchasing a product or service
              and FarmGround.comor one of its affiliates may place additional
              restrictions on your Lease, product or service.
            </p>
            <p>
              Users agree that they are responsible for, and agree to abide by,
              all laws, rules and regulations applicable to their use of the
              Site, their use of any tool, service or product offered on the
              Site and any transaction they enter into on the Site or in
              connection with their use of the Site.
            </p>
            <p>
              Members further agree that they are responsible for and agree to
              abide by all laws, rules, ordinances, or regulations applicable to
              the listing of their property and the conduct of their business,
              including but not limited to any and all laws, rules, ordinances,
              regulations or other requirements relating to taxes, credit cards,
              data and privacy, permits or license requirements, zoning
              ordinances, safety compliance and compliance with all
              anti-discrimination and fair housing laws, as applicable. Please
              be aware that, even though we are not a party to any Lease
              transaction and assume no liability for legal or regulatory
              compliance pertaining to properties listed on the Site, there may
              be circumstances where we are nevertheless legally obligated (as
              we may determine in our sole discretion) to provide information
              relating to your listing in order to comply with requests from
              governmental bodies in relation to investigations, litigation or
              administrative proceedings, and we may choose to comply with such
              obligations in our sole discretion.
            </p>
            <p>
              Members who obtain personal information from farmers agree to
              properly handle and safeguard all such information in accordance
              with applicable legal and regulatory requirements and best
              practices.
            </p>
            <p>
              While we do take certain measures with a goal to assist users to
              avoid potentially fraudulent or other illegal activity of which we
              become aware, we assume no liability or obligation to take any
              such measures or actions. &nbsp;When we provide warnings or
              messages to users about any such activity, we do not warrant that
              such messages are accurate or that such messages will reach any or
              all users they should have reached in a timely manner or at all or
              that such messages or measures will prevent any harm or otherwise
              have any impact.
            </p>
            <ol start="2">
              <li>
                <strong>Limited License to Use the Site.</strong>
              </li>
            </ol>
            <p>
              Users are granted a limited, revocable non-exclusive license to
              access the Site and the content and services provided on the Site
              solely for the purpose of advertising a property, searching for a
              property, Leasing, purchasing or researching (for the purpose of
              inquiring about Leasing or purchasing) any of the products or
              services offered on any Site, participating in an interactive area
              hosted on the Site or for any other purpose clearly stated on a
              Site, all in accordance with the Terms. &nbsp;Any use of the Site
              that is not for one of these purposes or otherwise in accordance
              with the Terms or as otherwise authorized by us in writing is
              expressly prohibited.
            </p>
            <ol start="3">
              <li>
                <strong>Unauthorized Uses of the Site. &nbsp;</strong>
              </li>
            </ol>
            <p>
              The license to use the Site only extends to the uses expressly
              described herein. &nbsp;The license to use the Site granted to
              users in these Terms does not include any right of collection,
              aggregation, copying, scraping, duplication, display or any
              derivative use of the Site nor any right of use of data mining,
              robots, spiders or similar data gathering and extraction tools
              without our prior written permission; provided, however, that a
              limited exception from the foregoing exclusion is provided to
              general purpose internet search engines that use tools to gather
              information for the sole purpose of displaying hyperlinks to the
              Site, provided they each do so from a stable IP address or range
              of IP addresses using an easily identifiable agent and comply with
              our robots.txt file. “General purpose internet search engines” do
              not include a website or search engine or other service that
              provides classified listings or property advertisements, or any
              subset of the same or which is in the business of providing
              property services or other services that compete with us.
            </p>
            <p>
              Unauthorized uses of the Site also include, without limitation,
              those listed below. &nbsp;You agree not to do any of the
              following, unless otherwise previously and specifically agreed to
              by us:
            </p>
            <ul>
              <li>
                Any commercial use of the Site or any content on the Site, other
                than by members in good standing, or by members under a valid
                license to software offered on the Site (a “
                <strong>valid license</strong>“);
              </li>
              <li>
                Any use of the Site the results of which is a payment, lease
                agreement or any part of transaction for a listed property that
                FarmGround.comis capable of performing, takes place outside of
                the FarmGround.complatform (“offsite”).
              </li>
              <li>
                Any use of the Site or the tools and services on the Site for
                the purpose of committing to or soliciting a property other than
                a property listed under a valid subscription;
              </li>
              <li>
                Copy, reproduce, upload, post, display, republish, distribute or
                transmit any part of the content in any form whatsoever;
              </li>
              <li>
                Reproduce any portion of the Site on your website or otherwise,
                using any device including, but not limited to, use of a frame
                or border environment around the Site, or other framing
                technique to enclose any portion or aspect of the Site, or
                mirror or replicate any portion of the Site;
              </li>
              <li>
                Deep-link to any portion of the Site without our express written
                permission;
              </li>
              <li>
                Modify, translate into any language or computer language or
                create derivative works from, any content or any part of the
                Site;
              </li>
              <li>Reverse engineer any part of the Site;</li>
              <li>
                Sell, offer for sale, transfer or license any portion of the
                Site in any form to any third parties;
              </li>
              <li>
                Use the Site and its inquiry or other types of functionality of
                the Site other than to advertise and/or research rentals, to
                make legitimate inquiries to our members or any other use
                expressly authorized on the Site;
              </li>
              <li>
                Use the Site to post or transmit information that is in any way
                false, fraudulent, or misleading, including making any
                commitment or inquiry under false pretenses, or taking any
                action that may be considered phishing or that would give rise
                to criminal or civil liability;
              </li>
              <li>
                Post or transmit any unlawful, threatening, abusive, libelous,
                defamatory, obscene, vulgar, indecent, inflammatory, sexually
                explicit, pornographic or profane material;
              </li>
              <li>
                Violate, plagiarize or infringe the rights of us or third
                parties including, without limitation, copyright, trademark,
                patent, trade secrets, rights of publicity or privacy or any
                other intellectual or proprietary rights; or
              </li>
              <li>
                Use or access the Site in any way that, in our sole discretion,
                adversely affects, or could adversely affect, the performance or
                function of the Site or any other system used by us or the Site.
              </li>
            </ul>
            <p>
              If you are aware of, or experience, any content, activity or
              communication through or in connection with the Site that appears
              to be in violation of the above restrictions, or in violation of
              any other provision of these Terms, we ask that you please inform
              us by contacting us as set forth under “Contact Us,” below.
            </p>
            <ol start="4">
              <li>
                <strong>
                  Proprietary Rights and Downloading of Information from the
                  Site.
                </strong>
              </li>
            </ol>
            <p>
              The Site and all content and information on the Site is the
              property of FarmGround.com, LLC. &nbsp;You agree to abide by any
              and all notices, information or restrictions contained in or
              relating to any content on the Site. Copying, storing or otherwise
              accessing the Site or any content on the Site other than for your
              personal, non-commercial use (other than in accordance with a
              valid listing) is expressly prohibited without prior written
              permission from us. As part of the inquiry or commitment process,
              for your own personal, noncommercial use and not for further
              distribution, you may download, display and/or print one copy of
              any portion of the Site. You may not modify the same, and you must
              reproduce our copyright notice in the form displayed on the
              relevant portion(s) of the Site that you desire to download,
              display or print.
            </p>
            <ol start="5">
              <li>
                <strong>
                  Your E-mail Address and Data; Our Privacy Policy; Data
                  Transmittal.
                </strong>
              </li>
            </ol>
            <p>
              When you provide your email address, name or other information to
              us in connection with your use or access to the Site, any service
              or tool provided on the Site or otherwise, you agree to allow the
              Site and its affiliated websites to add your email address, name
              or other information provided to our database of users. You may
              receive one or more promotional emails from either the Site or a
              website of one of Tillable’s affiliates. You are welcome to opt
              not to receive such promotional e-mails from the Site or such
              affiliates’ websites at any time. Please review our Privacy Policy
              for more information regarding our email and other data collection
              practices and safeguards, and how to opt not to receive such
              emails. Your use of the Site signifies your acknowledgment of, and
              agreement with, our&nbsp;
              <a href="https://www.FarmGround.com/privacy-policy">
                Privacy Policy
              </a>
              . &nbsp;We adhere to strong principles of privacy. You agree that
              we may access and use your user-contributed content in accordance
              with these Terms or our&nbsp;
              <a href="https://www.FarmGround.com/privacy-policy">
                Privacy Policy
              </a>
              &nbsp;and we agree that we will only disclose your
              user-contributed content in accordance with these Terms and
              our&nbsp;
              <a href="https://www.FarmGround.com/privacy-policy">
                Privacy Policy
              </a>
              .
            </p>
            <ol start="6">
              <li>
                <strong>Identity Verification.</strong>
              </li>
            </ol>
            <p>
              User verification on the Internet is difficult and we cannot, and
              do not assume any responsibility for, the confirmation of each
              user’s purported identity. We encourage you to communicate
              directly with a farmer or member through the tools available on
              the Site, though even this does not assure you of the identity of
              the person with which you are communicating. We further encourage
              you to take other reasonable measures to assure yourself of the
              other person’s identity and, for farmers, of the property and
              relevant details of your commitment or proposed commitment.
            </p>
            <p>
              You agree to (i) keep your password and online ID for both your
              account with us and your email account secure and strictly
              confidential, providing it only to authorized users of your
              accounts, (ii) instruct each person to whom you give your online
              ID and password that he or she is not to disclose it to any
              unauthorized person, (iii) notify us immediately and select a new
              online ID and password if you believe your password for either
              your account with us or your email account may have become known
              to an unauthorized person, and (iv) notify us immediately if you
              are contacted by anyone requesting your online ID and password.
              &nbsp;Further, if we suspect any unauthorized access to your
              account, upon our request, you agree to promptly change your ID
              and password and take any other related action as we may
              reasonably request.
            </p>
            <p>
              We discourage you from giving anyone access to your online ID and
              password for your account with us and your email account.
              &nbsp;However, if you do give someone your online ID and online
              password, or if you fail to adequately safeguard such information,
              you are responsible for any and all transactions that the person
              performs while using your account with us and/or your email
              account, even those transactions that are fraudulent or that you
              did not intend or want performed.
            </p>
            <p>
              EACH USER ACKNOWLEDGES AND AGREES THAT: (1) NEITHER TILLABLE NOR
              ANY OF ITS AFFILIATES WILL HAVE ANY LIABILITY TO ANY USER FOR ANY
              UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ID OR PASSWORD; AND
              (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD FOR YOUR
              FarmGround.comACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO
              INCUR LIABILITY TO BOTH TILLABLE AND OTHER USERS.
            </p>
            <p>
              Further, we may, without notice to you, suspend or cancel your
              listing at any time even without receiving notice from you if we
              suspect, in our sole discretion, that your account with us or your
              email account is being used in an unauthorized or fraudulent
              manner.
            </p>
            <ol start="7">
              <li>
                <strong>
                  Limitations on Communications and Use of Other Users’
                  Information; No Spam.
                </strong>
              </li>
            </ol>
            <p>
              You agree that, with respect to other users’ personal information
              that you obtain directly or indirectly from or through the Site or
              through any Site-related communication, transaction or software,
              we have granted to you a license to use such information only for:
              (i) Site-related communications that are not unsolicited
              commercial messages, (ii) using services offered through the Site,
              and (iii) inquiring about or otherwise facilitating a financial
              transaction between you and the other user related to the purpose
              of the Site (such as inquiring about or committing to a property).
              Any other purpose will require express permission from the user.
              &nbsp;You may not use any such information for any unlawful
              purpose or with any unlawful intent.
            </p>
            <p>
              In all cases, you must give users an opportunity to remove their
              information from your address book or database or other records
              and a chance to review what information you have collected about
              them. In addition, under no circumstances, except as defined in
              this provision, may you disclose personal information about
              another user to any third party without the consent of the other
              user. You agree that other users may use your personal information
              to communicate with you in accordance with this provision.
              Further, you agree that you will protect other users’ personal
              information with the same degree of care that you protect your own
              confidential information (using at minimum a reasonable standard
              of care), and you assume all liability for the misuse, loss or
              unauthorized transfer of such information.
            </p>
            <p>
              We do not tolerate spam or unsolicited commercial electronic
              communications of any kind. Therefore, without limiting the
              foregoing, you are not licensed to add a Site user, even a user
              who has rented a property from you or to you, to your mailing list
              (email or physical mail) without the user’s express consent. You
              may not use any tool or service on the Site to send spam or
              unsolicited commercial electronic communications of any kind or in
              any other way that would violate these Terms.
            </p>
            <ol start="8">
              <li>
                <strong>
                  Responsibility for Property Listings, Reviews and Other
                  User-contributed content; Participation in Interactive Forums.
                </strong>
              </li>
            </ol>
            <p>
              We have no duty to pre-screen content posted on the Site by
              members, farmers or other users, whether directly contributed by
              the user or contributed by us or a third party on behalf of the
              user (including, without limitation, property listings, reviews of
              property or a farmer, participation in an interactive community,
              forum or blog (each an “<strong>Interactive Forum</strong>”) or
              any other content provided by a user to the Site), (collectively,
              “<strong>user-contributed content</strong>”). &nbsp;We are not
              responsible for user-contributed content. “
              <strong>User-contributed content</strong>” also includes
              information that a user or any other person provided to a third
              party website or mobile application that is then provided to our
              Site by a tool we offer or any other exchange of user-contributed
              content we have authorized.
            </p>
            <p>
              We reserve the right to decline to permit the posting on the Site
              of or to remove from the Site any user-contributed content that
              fails to meet our&nbsp;Content Guidelines, which are incorporated
              by reference into these Terms, any other guidelines posted on a
              Site or if it otherwise violates these Terms, each as determined
              in our discretion. We may also remove user-contributed content if
              it is brought to our attention, such as by notice given to us by a
              user or any third party that any part of these Terms, or any other
              requirements governing the posting of such content, have been
              apparently breached in respect of such content, as determined in
              our discretion. &nbsp;Finally, we reserve the right, but do not
              assume the obligation, to edit a member’s content or
              user-contributed content in a non-substantive manner solely to
              cause the content to comply with our&nbsp;Content
              Guidelines&nbsp;or formatting requirements or to provide services
              to members to create or improve listings (such as translation
              services), in accordance with information we have about the
              property listed. &nbsp;Users remain responsible for reviewing
              their user-contributed content to ensure it is accurate and not
              misleading.
            </p>
            <p>
              At a minimum, user-contributed content must (as determined by us
              in our discretion):
            </p>
            <ul>
              <li>
                not infringe anyone’s rights, including but limited to copyright
                and rights of publicity and privacy, violate the law or
                otherwise be inappropriate;
              </li>
              <li>
                not include personal information of another that can be used to
                identify or contact any person;
              </li>
              <li>
                not include unsolicited promotional content, advertising,
                political campaigns, contests, raffles or solicitations;
              </li>
              <li>
                be directly related to the Site, business service, product or
                forum where the content is submitted;
              </li>
              <li>not be obscene, abusive, discriminatory or illegal; or</li>
              <li>not be false or misleading.</li>
            </ul>
            <p>
              All property listings on the Site are the sole responsibility of
              the member (who may be the owner or a property manager or duly
              authorized agent of the owner) and we specifically disclaim any
              and all liability arising from the alleged accuracy of the
              listings, reviews or any alleged breaches of contract on a user’s
              part. &nbsp;Members are solely responsible for keeping their
              property information up-to-date on the Site, including, but not
              limited to, any and all representations about any property, its
              unique characteristics, location, price and its availability for a
              specific date or range of dates. We do not represent or warrant
              that any of the copy, content, farmer or property reviews,
              property location, suitability, pricing or availability
              information published on the Site is accurate or up-to-date even
              in the case where farmers have searched for specific dates or
              types of properties.
            </p>
            <p>
              We also may from time to time create new descriptions or otherwise
              change the location or geographic descriptions we use to identify
              properties in their listings and search results.
              &nbsp;Consequently, we may change the location or geographic
              description associated with any property listing at any time
              without notice. However, we assume no responsibility to verify
              property listing content or the accuracy of the location. Members
              are solely responsible for ensuring the accuracy of location,
              geographic and other content and location or geographic
              descriptions and agree to promptly correct (or contact us to
              correct) any inaccuracy and farmers are solely responsible for
              verifying the accuracy of such content and descriptions.
            </p>
            <p>
              All other user-contributed content is the sole responsibility of
              the user who contributed such content, whether such user
              contributed the content directly or through a third-party website.
              &nbsp;Users are solely responsible for their user-contributed
              content and we specifically disclaim all liability for
              user-contributed content. The user represents and warrants that
              the user owns or otherwise controls and has all necessary legal
              rights to the user’s submission and the name or other identifier
              used in connection with such submission including, but not limited
              to, all the rights necessary to provide, post, upload, input or
              submit the user-contributed content. We reserve the right to
              request a proof of ownership or permission, and to refuse to post
              user generated content without such proof or if such proof is, in
              our sole discretion, insufficient.
            </p>
            <p>
              By submitting or authorizing user-contributed content, you grant
              to us and our affiliates a perpetual, worldwide, irrevocable,
              unrestricted, non-exclusive, royalty-free and fully paid-up
              license to use, copy, license, sublicense (through multiple
              tiers), adapt, distribute, display, publicly perform, reproduce,
              transmit, modify, edit and otherwise exploit the copy, the
              photographs and the likenesses (if any) of any of your
              user-contributed content, in connection with our business or the
              business of our affiliates. Notwithstanding the foregoing,
              following the termination or expiration of a property listing, we
              will not continue to display the user-contributed content that was
              displayed in such listing.
            </p>
            <p>
              You further grant us and our affiliates the ability to register
              copyright in and protect the user-contributed content, including
              the images, copy, and content available via any member’s listing,
              from the unauthorized use of the user-contributed content by
              unaffiliated third parties who may, from time to time, attempt to
              pirate such information via electronic or other means. &nbsp;This
              includes, but is not limited to, the right to file suit to seek
              injunctive relief to protect such material on behalf of and in
              your name. You further agree to appear and assist us—at our
              expense and control—with protecting such copyrighted material from
              unauthorized redistribution.
            </p>
            <p>
              You agree that we may sublicense all the rights granted to us
              under these Terms to one or more third parties we may contract
              with to display all or part of the member’s property listing or
              otherwise provide promotional or other services related to our
              business. &nbsp;In the event that you retain any rights of
              attribution, integrity or any other moral rights in any
              user-contributed content, you hereby waive your right to assert
              these or to require that any personally identifying information be
              used in connection with the user-contributed content or any
              derivative works thereof and affirm that you have no objection to
              the publication, use, modification, deletion or exploitation of
              the user-contributed content by us or our affiliates.
            </p>
            <ol start="9">
              <li>
                <strong>Service Fee Payable by farmers.</strong>
              </li>
            </ol>
            <p>
              We reserve the right to charge a service fee payable by farmers
              who commit to &nbsp;a property on the Site. The service fee covers
              the use of the Site, including such features as 24/7 user support,
              and may be calculated as a variable percentage of the total Lease
              amount, not to exceed 5% or some other fixed charge methodology.
              &nbsp;The service fee may or may not include additional fees,
              taxes and damage deposits. Depending on the laws of the
              jurisdiction of the farmer and/or member, VAT may be charged on
              top of the service fee. The service fee plus applicable VAT will
              be charged after both the farmer and member accept the Lease. The
              service fee is non-refundable. &nbsp;Any taxes alleged to be owed
              by any taxing authority on the service fee are the responsibility
              of FarmGround.comand members have no responsibility for any such
              claimed tax liability. Members agree not to encourage or advise a
              farmer to avoid or circumvent the service fee charged by
              FarmGround.com.
            </p>
            <ol start="10">
              <li>
                <strong>Social Media or Third-Party Websites.</strong>
              </li>
            </ol>
            <p>
              If the Site advertises through or offers a tool or service that
              allows us to access or use any profile or other information about
              you that you have provided to Facebook or another third-party
              website (each a “Social Media Site”) and you decide to respond to
              or use such tool or service, you acknowledge and agree that:
            </p>
            <ul>
              <li>
                The information or content that is a part of your Social Media
                Site profile, which you have designated as “public” (or a
                similar designation) (with such information or content and
                referred to herein as “<strong>Social Media Content</strong>
                ”), may be accessed and used by us in connection with the Site;
              </li>
              <li>
                The Social Media Content will be considered user-generated
                content under these Terms and both you and we shall have the
                same rights and responsibilities as you that we have with
                respect to user-generated content under these Terms;
              </li>
              <li>
                In the event that the Social Media Content was for any reason
                misclassified with a public or similar designation or is
                otherwise inaccurate or to which you do not agree with for any
                reason, you agree to work with the Social Media Site to make any
                changes or resolve any disputes and acknowledge that we will not
                be able to provide you with recourse; and
              </li>
              <li>
                The operation of your profile and account with and on the Social
                Media Site shall continue to be governed by the terms and
                conditions and privacy policy of such Social Media Site.
              </li>
            </ul>
            <ol start="11">
              <li>
                <strong>Translations and Maps.</strong>
              </li>
            </ol>
            <p>
              If any user-contributed content created by members or users is
              translated for display on the Site or any site of any affiliate of
              FarmGround.com, we cannot guarantee the accuracy or quality of
              such translation and the user is solely responsible for the
              review, verification and accuracy of such translation. &nbsp;Maps
              provided on the Site that are provided by Google are subject to
              the Google Maps terms and conditions located at:
            </p>
            <p>
              <a href="http://www.google.com/intl/en_us/help/terms_maps.html">
                http://www.google.com/intl/en_us/help/terms_maps.html
              </a>
              .
            </p>
            <ol start="12">
              <li>
                <strong>Notification of Infringement; DMCA Policy.</strong>
              </li>
            </ol>
            <p>
              We respect the intellectual property rights of others, and
              FarmGround.comdoes not permit, condone or tolerate the posting of
              any content on the Site that infringes any person’s copyright.
              &nbsp;FarmGround.comwill terminate, in appropriate circumstances,
              a member or farmer who is the source of repeat infringement of
              copyright.
            </p>
            <ol start="13">
              <li>
                <strong>Unsolicited Ideas and Feedback.</strong>
              </li>
            </ol>
            <p>
              From time to time, users submit to us ideas or suggestions
              pertaining to our business, such as ideas for new or improved
              products or technologies, website or tool enhancements, processes,
              materials, marketing plans or new product names. &nbsp;We are
              under no obligation to review or consider them. If you choose to
              submit any ideas, original creative artwork, suggestions or other
              works (“<strong>submissions</strong>”) in any form to us, then
              regardless of what you say, write or provide to us in connection
              with your submissions, the following terms shall apply. The sole
              purpose of this policy is to avoid potential misunderstandings or
              disputes in the event that any part of our business, such as our
              products, websites, technologies or marketing strategies, seems
              similar to any of your submissions. If you provide any submissions
              to us, you agree that: (i) your submission and its contents will
              automatically become the property of FarmGround.com, without any
              compensation to you; (ii) FarmGround.commay use or redistribute
              any such submission and its contents for any purpose and in any
              way; (iii) there is no obligation for FarmGround.comto review any
              submission; and (iv) there is no obligation to keep any submission
              confidential.
            </p>
            <p>
              Please provide only specific feedback on our Site and services.
              &nbsp;Keep in mind that we assume no obligation to keep any
              feedback you provide confidential and we reserve the right to use
              or disclose such information in any manner.
            </p>
            <ol start="14">
              <li>
                <strong>Software Available on the Site.</strong>
              </li>
            </ol>
            <p>
              The Site is controlled and operated by FarmGround.comor an
              affiliate of FarmGround.comin the United States. &nbsp;Software
              available on the Site (the “<strong>Software</strong>”) is subject
              to United States export controls. &nbsp;No Software available on
              the Site or software available on any other site operated by
              FarmGround.comor an affiliate of FarmGround.comin the United
              States may be downloaded or otherwise exported or re-exported (i)
              into (or to a resident of) Cuba, Iraq, Libya, North Korea, Iran,
              Syria or any other country to which the United States has
              embargoed goods, or (ii) anyone on the United States Treasury
              Department’s list of Specially Designated Nationals or the United
              States Commerce Department’s Table of Deny Orders. &nbsp;By using
              the Site, you represent and warrant that you are not located in,
              under the control of, or a national or resident of any such
              country or on any such list.
            </p>
            <p>
              All Software is the property of FarmGround.com, an affiliate of
              FarmGround.comor an identified third party. &nbsp;Your use of such
              Software is governed by these Terms and the terms of any
              additional license agreement that accompanies or is included with
              such Software. &nbsp;If the Software is not accompanied by an
              additional license agreement, we hereby grant you a limited,
              personal and nontransferable license to use the Software for
              viewing and using this Site in accordance with these Terms and for
              no other purpose.
            </p>
            <p>
              THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS
              OF THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. &nbsp;COPYING
              OR REPRODUCING ANY SOFTWARE AVAILABLE ON THIS SITE IS EXPRESSLY
              PROHIBITED, EXCEPT AS SPECIFICALLY PROVIDED FOR IN A LICENSE
              AGREEMENT ACCOMPANYING SUCH SOFTWARE.
            </p>
            <ol start="15">
              <li>
                <strong>Links to Third-Party Sites.</strong>
              </li>
            </ol>
            <p>
              This Site may contain links and pointers to other Internet sites,
              resources and sponsors of the Site. Links to and from the Site to
              other third-party sites, maintained by third parties, do not
              constitute an endorsement by us of any third parties, the
              third-party sites or the contents thereof. &nbsp;We may also
              provide tools to allow interaction between the Site and a
              third-party site, such as a Social Media Site. We are not
              responsible in any way for such third-party sites or resources and
              your use of such sites and resources will not be governed by these
              Terms.
            </p>
            <ol start="16">
              <li>
                <strong>
                  Limitation of Liability. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
              </li>
            </ol>
            <p>
              <strong>
                IN NO EVENT WILL TILLABLE, OR ITS PARENT COMPANY, SUBSIDIARIES,
                AFFILIATES, OFFICERS, DIRECTORS, CONSULTANTS, AGENTS AND/OR
                EMPLOYEES (COLLECTIVELY, THE “TILLABLE GROUP”), OR ANY
                THIRD-PARTY PROVIDER OF A SERVICE OR TOOL OFFERED ON ANY SITE OF
                A MEMBER OF THE TILLABLE GROUP (EACH A “THIRD-PARTY PROVIDER”),
                BE LIABLE FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL,
                SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED
                ON, OR RESULTING FROM (A) OUR SITE, (B) THESE TERMS, (C) ANY
                BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE OF THE
                SITE, TOOLS OR SERVICES WE PROVIDE, OR ANY THIRD PARTY PROVIDER
                PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU
                OR ANY THIRD PARTY (E) ANY USER-CONTRIBUTED CONTENT, (F)
                INTERACTION BETWEEN OUR SITE AND ANY THIRD PARTY SITE, INCLUDING
                WITHOUT LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A TOOL OR
                SERVICE ON OUR SITE AND/OR (G) ANY ACTUAL OR ATTEMPTED
                COMMUNICATION OR TRANSACTION, INCLUDING WITHOUT LIMITATION, ANY
                PAYMENT TRANSACTION (EVEN IF WE OR ANY THIRD PARTY PROVIDER
                RECEIVES A FEE IN CONNECTION THEREWITH) BETWEEN USERS, IN EACH
                CASE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. &nbsp;THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT
                REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF CONTRACT,
                (2) BREACH OF WARRANTY, (3) STRICT LIABILITY, (4) TORT, (5)
                NEGLIGENCE, OR (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM
                EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY
                APPLICABLE LAW.
              </strong>
            </p>
            <p>
              <strong>
                IF YOU ARE DISSATISFIED WITH THE SITE, YOU DO NOT AGREE WITH ANY
                PART OF THE TERMS, OR HAVE ANY OTHER DISPUTE OR CLAIM WITH OR
                AGAINST US, ANY THIRD PARTY PROVIDER OR ANY USER OF THE SITE
                WITH RESPECT TO THESE TERMS OR THE SITE, THEN YOUR SOLE AND
                EXCLUSIVE REMEDY AGAINST US IS TO DISCONTINUE USING THE SITE. IN
                ALL EVENTS, OUR LIABILITY, AND THE LIABILITY OF ANY MEMBER OF
                THE TILLABLE GROUP, TO YOU OR ANY THIRD PARTY IN ANY
                CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS
                LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO US
                IN THE TWELVE MONTHS PRIOR TO THE ACTION GIVING RISE TO
                LIABILITY OR (B) $100.00 IN THE AGGREGATE FOR ALL CLAIMS.
                &nbsp;&nbsp;
              </strong>
            </p>
            <ol start="17">
              <li>
                <strong>Disclaimers. &nbsp;&nbsp;</strong>
              </li>
            </ol>
            <p>
              <strong>
                THE SITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS
                AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE,
                IS PROVIDED “AS IS.” TO THE FULLEST EXTENT PERMISSIBLE BY LAW,
                WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER
                FOR THE CONTENT ON THE SITE OR THE MATERIALS, INFORMATION AND
                FUNCTIONS MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED
                THROUGH THE SITE, FOR ANY PRODUCTS OR SERVICES OR HYPERTEXT
                LINKS TO THIRD PARTIES OR FOR ANY BREACH OF SECURITY ASSOCIATED
                WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SITE
                OR ANY LINKED SITE, EVEN IF WE BECOME AWARE OF ANY SUCH
                BREACHES. FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED
                WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY.
                WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE OR
                ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED
                OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE
                OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS. &nbsp;&nbsp;YOU ACKNOWLEDGE AND AGREE
                THAT ANY TRANSMISSION TO AND FROM THIS SITE IS NOT CONFIDENTIAL
                AND YOUR COMMUNICATIONS OR USER-CONTRIBUTED CONTENT MAY BE READ
                OR INTERCEPTED BY OTHERS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT
                BY SUBMITTING COMMUNICATIONS OR USER-CONTRIBUTED CONTENT TO US
                AND BY POSTING INFORMATION ON THE SITE, INCLUDING PROPERTY
                LISTINGS, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR
                OTHER RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN
                PURSUANT TO THESE TERMS.
              </strong>
            </p>
            <p>
              <strong>
                YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO HOLD
                US OR ANY THIRD PARTY PROVIDER RESPONSIBLE FOR THE CONTENT
                PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION, ANY
                TRANSLATION THEREOF, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT
                WE ARE NOT A PARTY TO ANY TRANSACTION OR OTHER TRANSACTION
                BETWEEN USERS OF THE SITE. &nbsp;WE HAVE NO CONTROL OVER AND DO
                NOT GUARANTEE (OTHER THAN PURSUANT TO ANY GUARANTEE THAT MAY BE
                OFFERED ON THE SITE) THE SAFETY OF ANY TRANSACTION, RENTAL
                PROPERTY OR THE TRUTH OR ACCURACY OF ANY LISTING OR OTHER
                CONTENT PROVIDED ON THE SITE.
              </strong>
            </p>
            <p>
              <strong>
                YOU FURTHER ACKNOWLEDGE THAT BY DISPLAYING INFORMATION OR
                PROPERTY LISTINGS IN PARTICULAR DESTINATIONS, WE DO NOT
                REPRESENT OR WARRANT THAT TRAVEL TO SUCH DESTINATIONS IS WITHOUT
                RISK AND ARE NOT LIABLE FOR DAMAGES WITH RESPECT TO TRAVEL TO
                ANY DESTINATION.
              </strong>
            </p>
            <ol start="18">
              <li>
                <strong>Release; Indemnification. &nbsp;&nbsp;</strong>
              </li>
            </ol>
            <p>
              <strong>
                IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER
                USERS OF THE SITE (INCLUDING, WITHOUT LIMITATION, ANY DISPUTE
                BETWEEN USERS REGARDING ANY TRANSACTION OR USER-CONTRIBUTED
                CONTENT) OR ANY THIRD PARTY PROVIDER OR ANY THIRD PARTY WEBSITE
                THAT MAY BE LINKED TO OR FROM OR OTHERWISE INTERACT WITH THE
                SITE, INCLUDING WITHOUT LIMITATION ANY SOCIAL MEDIA SITE, YOU
                HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE EACH
                MEMBER OF THE TILLABLE GROUP, EACH OF THEIR RESPECTIVE AGENTS,
                DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR
                ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS,
                DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES,
                OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE
                WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER
                ARISE FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR
                YOUR USE OF THE SITE.
              </strong>
            </p>
            <p>
              <strong>
                IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL
                CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT
                EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH,
                IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
                WITH THE DEBTOR.” &nbsp;&nbsp;&nbsp;
              </strong>
            </p>
            <p>
              <strong>
                YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD EACH MEMBER OF
                THE TILLABLE GROUP (COLLECTIVELY, THE “INDEMNIFIED PARTIES”)
                HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY AND COSTS
                INCURRED BY THE INDEMNIFIED PARTIES IN CONNECTION WITH ANY CLAIM
                ARISING OUT OF YOUR USE OF THE SITE OR OTHERWISE RELATING TO THE
                BUSINESS WE CONDUCT ON THE SITE (INCLUDING, WITHOUT LIMITATION,
                ANY POTENTIAL OR ACTUAL COMMUNICATION, TRANSACTION OR DISPUTE
                BETWEEN YOU AND ANY OTHER USER OR THIRD PARTY), ANY CONTENT
                POSTED BY YOU OR ON YOUR BEHALF OR POSTED BY OTHER USERS OF YOUR
                ACCOUNT TO THE SITE, ANY USE OF ANY TOOL OR SERVICE PROVIDED BY
                A THIRD PARTY PROVIDER, ANY USE OF A TOOL OR SERVICE OFFERED BY
                US THAT INTERACTS WITH A THIRD PARTY WEBSITE, INCLUDING WITHOUT
                LIMITATION ANY SOCIAL MEDIA SITE OR ANY BREACH BY YOU OF THESE
                TERMS OR THE REPRESENTATIONS, WARRANTIES AND COVENANTS MADE BY
                YOU HEREIN, INCLUDING WITHOUT LIMITATION, ATTORNEYS’ FEES AND
                COSTS. YOU SHALL COOPERATE AS FULLY AS REASONABLY REQUIRED IN
                THE DEFENSE OF ANY CLAIM.
              </strong>
            </p>
            <p>
              <strong>
                WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE
                EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO
                INDEMNIFICATION BY YOU AND YOU SHALL NOT IN ANY EVENT SETTLE ANY
                MATTER WITHOUT OUR WRITTEN CONSENT. &nbsp;&nbsp;
              </strong>
            </p>
            <ol start="19">
              <li>
                <strong>Disputes; Arbitration.</strong>
              </li>
            </ol>
            <p>
              Tillable’s right to amend these Terms, in whole or in part, at any
              time as set forth below in Section 22 does not apply to this
              “Disputes; Arbitration” section. The version of this “Disputes;
              Arbitration” section in effect on the date you last accepted the
              Terms controls.
            </p>
            <p>
              FarmGround.comis committed to customer satisfaction, so if you
              have a problem or dispute, we will try to resolve your concerns.
              But if we are unsuccessful, you may pursue claims as explained in
              this section. You agree to give us an opportunity to resolve any
              disputes or claims relating in any way to the Site, any dealings
              with our customer experience agents, any services or products
              provided, any representations made by us, or our&nbsp;
              <a href="https://www.FarmGround.com/privacy-policy">
                Privacy Policy
              </a>
              &nbsp;(“<strong>Claims</strong>”) by contacting FarmGround.com
              Customer Support.. If we are not able to resolve your Claims
              within 60 days, you may seek relief through arbitration or in
              small claims court, as set forth below.
            </p>
            <p>
              <strong>
                Any and all Claims will be resolved by binding arbitration,
                rather than in court,
              </strong>
              &nbsp;except you may assert Claims on an individual basis in small
              claims court if they qualify. This includes any Claims you assert
              against us, our subsidiaries, users or any companies offering
              products or services through us (which are beneficiaries of this
              arbitration agreement). This also includes any Claims that arose
              before you accepted these Terms, regardless of whether prior
              versions of the Terms required arbitration.
            </p>
            <p>
              <strong>
                There is no judge or jury in arbitration, and court review of an
                arbitration award is limited. However, an arbitrator can award
                on an individual basis the same damages and relief as a court
                (including statutory damages, attorneys’ fees and costs), and
                must follow and enforce these Terms as a court would.
              </strong>
            </p>
            <p>
              Arbitrations will be conducted by the American Arbitration
              Association (AAA) under its rules, including the AAA Arbitration
              Consumer Rules (together, the “AAA Rules”). Payment of all filing,
              administration and arbitrator fees will be governed by the AAA’s
              rules, except as provided in this section. You may choose to have
              an arbitration conducted by telephone, based on written
              submissions, or in person in the state where you live or at
              another mutually agreed upon location.
            </p>
            <p>
              By agreeing to arbitration under the AAA Rules, the parties agree,
              among other things, that the arbitrator, and not any federal,
              state, or local court or agency, shall have the exclusive power to
              rule on any objections with respect to the existence, scope, or
              validity of the arbitration agreement or to the arbitrability of
              any claim or counterclaim
            </p>
            <p>
              To begin an arbitration proceeding, you must send a letter
              requesting arbitration and describing your Claims to
              FarmGround.com. If we request arbitration against you, we will
              give you notice at the email address or street address you have
              provided. The AAA’s rules and filing instructions are available at
              www.adr.org.
            </p>
            <p>
              <strong>
                Any and all proceedings to resolve Claims will be conducted only
                on an individual basis and not in a class, consolidated or
                representative action.
              </strong>
              &nbsp;If for any reason a Claim proceeds in court rather than in
              arbitration&nbsp;
              <strong>we each waive any right to a jury trial</strong>. The
              Federal Arbitration Act and federal arbitration law apply to these
              Terms. An arbitration decision may be confirmed by any court with
              competent jurisdiction.
            </p>
            <ol start="20">
              <li>
                <strong>
                  Additional Terms and Conditions Applicable to Online
                  Commitments.
                </strong>
              </li>
            </ol>
            <p>
              In addition to being bound by the other terms set forth herein,
              users who use any tools provided by us or a third-party provider
              on the Site enabling users to commit to and/or pay for the of
              properties online on one or more of the Sites (collectively, the “
              <strong>Commitment Services</strong>”) are also bound by the
              following terms, which are in addition to any other terms
              applicable in connection with using the Site.
            </p>
            <p>
              In order to use the payment functionality of the FarmGround.com
              application, you must open a “Dwolla Platform” account provided by
              Dwolla, Inc. and you must accept the&nbsp;D​wolla Terms of
              Service​ and&nbsp;Privacy Policy​. Any funds held in the Dwolla
              account are held by Dwolla’s financial institution partners as set
              out in the ​Dwolla Terms of Service.​ You authorize FarmGround.com
              to share your identity and account data with Dwolla for the
              purposes of opening and supporting your Dwolla account, and you
              are responsible for the accuracy and completeness of that data.
              You understand that you will access and manage your Dwolla account
              through the FarmGround.comapplication, and Dwolla account
              notifications will be sent by FarmGround.com, not Dwolla.
              FarmGround.com will provide customer support for your Dwolla
              account activity, and can be reached at&nbsp;
              <a href="https://www.FarmGround.com/contact">Customer Support</a>,
              contact@FarmGround.com and/or (833) 845-5225.
            </p>
            <p>
              In addition, if such Commitment Services include payment or other
              services provided by a third-party provider, such services are
              subject to the additional terms and conditions and privacy
              policies of such third-party providers.
            </p>
            <p>
              We provide Commitment Services to manage inquiries, quotes, and
              agreements and to allow for payments to be made relating to the
              Lease. &nbsp;Please review the following terms and the terms and
              conditions of any third-party provider carefully. If you do not
              agree to these or such third-party provider’s terms, you have no
              right to obtain information from or otherwise continue using our
              Commitment Services. Failure to use our Commitment Services in
              accordance with the following terms of use may subject you to
              severe civil and criminal penalties and other liability.
            </p>
            <p>
              By utilizing an agreement as part of the Commitment Services or
              otherwise displaying terms as part of the online commitment
              process (including such terms that we may require), the farmer and
              member each agree to the terms and conditions set forth in the
              agreement or other such terms displayed in the commitment process
              effective as of the date that the user indicates acceptance of the
              agreement, as applicable. You hereby acknowledge and agree that
              (i) you are fully responsible for such terms and conditions, (ii)
              any Lease agreement used, whether a sample provided by the Site or
              a Lease agreement copied and pasted in the Site (or other online
              commitment tool on the Site) by either party, is used solely at
              their own risk and expense, (iii) nothing contained in the
              Commitment Services, these Terms or any sample agreement is a
              substitute for the advice of an attorney and (iv) that you have
              been hereby advised to obtain local legal counsel to prepare,
              review and revise as necessary any agreements to ensure compliance
              with federal, state and local law and their particular
              circumstances, and to revise the agreement as necessary to
              accurately represent the property, rules, features, etc.
            </p>
            <p>
              There are some members, typically property managers, who may use
              software provided by an affiliate, or a third party. Such software
              (“<strong>Other Commitment Services</strong>”) may be governed by
              terms provided by the third parties or members making such Other
              Commitment Services available. &nbsp;Users who use such Other
              Reservation Services are responsible for complying with such terms
              in addition to our Terms.
            </p>
            <ol start="21">
              <li>
                <strong>
                  Responsibility for Property and Farmer Liability.
                </strong>
              </li>
            </ol>
            <p>
              We do not provide liability insurance protection for owners,
              property managers or farmers, regardless of whether a user obtains
              insurance coverage through one of our third-party providers. Users
              are solely responsible for obtaining insurance coverage sufficient
              to protect their properties. &nbsp;Members agree that they have or
              will obtain the appropriate insurance coverage sufficient to cover
              the properties they list on the Site and will maintain adequate
              insurance coverage throughout the term of the agreement. Further,
              members agree to provide us with copies of relevant proof of
              coverage upon request. Farmers will maintain insurance coverage as
              defined and agree to provide us with copies of relevant proof of
              coverage upon request.
            </p>
            <ol start="22">
              <li>
                <strong>&nbsp;GENERAL</strong>
              </li>
            </ol>
            <p>To Contact Us for any reason, users can visit FarmGround.com.</p>
            <p>
              These Terms are governed by the Federal Arbitration Act, 9 U.S.C.
              § 1 et seq. (“FAA”), AAA Rules, federal arbitration law, and for
              U.S. residents, the laws of the state in which you reside (as
              determined by the billing address you have provided us), without
              regard to conflict of laws principles. It is the intent of the
              parties that the FAA and AAA Rules shall preempt all state laws to
              the fullest extent permitted by law.
            </p>
            <p>
              Our relationship is that of independent contractors, and no
              agency, partnership, joint venture, employee-employer or
              franchisor-franchisee relations is intended or created by these
              Terms or your use of the Site. Except as explicitly stated
              otherwise, any notices to us shall be given by postal mail to:
              FarmGround.com, LLC., Attn: Legal Department
            </p>
            <p>
              When we need to send you notice, it will be sent to the email
              address you provide to the Site during the registration or
              commitment or inquiry process or as later updated in your account
              (if applicable). Notice shall be deemed given upon receipt or 24
              hours after an email is sent, unless the sending party is notified
              that the email address is invalid. Alternatively, we may give you
              notice by certified mail, postage prepaid and return receipt
              requested, to any physical address provided to us during the
              registration process or as later updated in your account (if
              applicable). Notice shall be deemed given three days after the
              date of mailing to a physical address and one day after mailing to
              an electronic address.
            </p>
            <p>
              We may change, suspend or discontinue any aspect of the Site at
              any time, including the availability of any Site features,
              database or content. We may also impose limits on certain features
              or services or restrict your access to parts or the entire Site
              without notice or liability. &nbsp;This version of the Terms
              became effective on the date set forth above and this version
              amends the version effective before such date. We reserve the
              right, in our sole discretion, to amend these Terms, in whole or
              in part, at any time. Notification of any amendment will be posted
              on the Site by the indication of the last amendment date at the
              top of these Terms and will be effective immediately. When members
              renew subscriptions, the terms in effect at the time of renewal
              will govern, provided that such terms may change as described
              above.
            </p>
            <p>
              We also reserve the right, in our sole discretion and from time to
              time, to offer programs, products or services with unique terms
              and conditions that are separate from and may supersede or
              supplement in certain respects these Terms. &nbsp;In such cases,
              your use of the Site with respect to such special program is
              governed by these Terms together with the terms and conditions of
              such program, product or service. We reserve the right, but assume
              no obligation, to agree to different or conflicting terms and
              conditions with respect to any user. Any such terms and conditions
              will not be enforceable unless specifically agreed to by us.
            </p>
            <p>
              Subscription rates and fees (including any commissions) that may
              be charged for any listing that is not subscription based may be
              set at the time of a user or member’s purchase of the subscription
              or renewal or sign up for the non-subscription-based listing, as
              applicable. &nbsp;Such rates and fees are subject to change
              without notice or approval. For subscription listings, the rates
              in effect at the time of the member’s next subscription renewal,
              new listing or a member’s upgrade or any other additional or new
              order of any product or service will govern for such renewal or
              other order.
            </p>
            <p>
              The types of products and services (including the features, terms
              and operation thereof) offered at the time of a member’s
              subscription or sign up for a non-subscription-based listing are
              subject to the descriptions displayed at the time of use and/or
              purchase and are subject to change without notice or approval.
              &nbsp;&nbsp;We further reserve the right to offer additional
              products, services or features for purchase at any time.
            </p>
            <p>
              We do not separately file the Terms entered into by each user of
              the Site. Please make a copy of these Terms for your records by
              printing and/or saving a downloaded copy of the Terms on your
              personal computer. We may immediately terminate any user’s access
              to or use of the Site due to such user’s breach of these Terms or
              any other unauthorized use of the Site. &nbsp;However, we do not
              guarantee that we will take action against all breaches of these
              Terms. Our failure to take immediate action with respect to a
              breach by you or others does not waive our right to act with
              respect to such breach or any other breach.
            </p>
            <p>
              These Terms constitute the entire agreement between us and you
              with respect to the matters set forth herein, and supersede any
              prior agreement between us and you with respect to your use of the
              Site. &nbsp;Headings in these Terms are for reference only and do
              not limit the scope or extent of such section. In the event of any
              conflict between these Terms and any other terms and conditions
              applicable to a product, tool or service offered on our Site, the
              Terms herein shall prevail. &nbsp;If any portion of these Terms is
              found to be invalid or unenforceable by any court of competent
              jurisdiction, the other provisions of these Terms shall remain in
              full force and effect. Further, any provision of these Terms held
              invalid or unenforceable only in part or degree will remain in
              full force and effect to the extent not held invalid or
              unenforceable.
            </p>
            <p>
              We may assign these Terms in our sole discretion. &nbsp;Users must
              obtain our prior written consent to assign these Terms, which may
              be granted or withheld by us in our sole discretion.
            </p>
            <p>
              <strong>
                ADDITIONAL TERMS AND CONDITIONS APPLICABLE TO MEMBERS
              </strong>
            </p>
            <p>
              In addition to being bound by the terms set forth above, members
              who list properties on the Site are also bound by the following
              terms, which are in addition to any other terms agreed to in
              connection with purchasing or renewing a listing.
            </p>
            <ol start="23">
              <li>
                <strong>
                  Member Eligibility; Accuracy of Information; Listing Practice
                  Requirements.
                </strong>
              </li>
            </ol>
            <p>
              Our services may only be used by members who can form legally
              binding contracts under applicable law. If you are registering as
              a business entity, you represent that you have the authority to
              bind the entity to these Terms. Each member represents and
              covenants that all information submitted to us and to the Site
              during such member’s registration with the Site shall be true and
              correct. Each member further agrees to promptly provide notice to
              the Site by contacting us as provided above under “Contact Us”
              regarding any updates to any such contact information previously
              submitted by such member to the Site.
            </p>
            <p>
              In order to list a property on a Site, members must comply with
              Tillable’s Marketplace Standards found at FarmGround.com.
              &nbsp;These Standards outline certain requirements including but
              not limited to:
            </p>
            <ul>
              <li>
                Members must execute agency and/or brokerage agreements with
                FarmGround.comas may be required by individual state real estate
                regulations.
              </li>
              <li>
                Members must maintain an accurate calendar on their listing(s);
              </li>
              <li>
                Members must use commercially reasonable efforts to respond to
                all requests from farmers within 60 hours of receipt of a
                request for commitment;
              </li>
              <li>
                Members agree to to cause all farmers payments to be processed
                through the FarmGround.complatform. within 24 hours of the
                agreed upon date with the farmer;
              </li>
              <li>
                Members must respond to and accept a material number of
                inquiries and commitment requests received; and
              </li>
              <li>
                Members are prohibited from cancelling a material number of
                accepted commitments.
              </li>
            </ul>
            <ol start="24">
              <li>
                <strong>Additional Member Representations.</strong>
              </li>
            </ol>
            <p>
              Each member represents and covenants that: (i) it owns and/or has
              all necessary rights and authority to offer for the property
              listed by such member; (ii) it will not wrongfully withhold a
              deposit in breach of the underlying agreement; (iii) it will
              accurately describe the subject property, will not fail to
              disclose a material defect in, or material information about,
              property and will upon request, or otherwise from time to time,
              review the property listing content and location or geographic
              description to ensure it is accurate and not misleading ; (iv) it
              will not wrongfully deny access to the listed property; and (v) it
              will not fail to provide a refund when due as defined in the
              agreement (if any). In addition, if FarmGround.comis required to
              compensate a farmer for a loss caused by acts or omissions
              attributable to a member, FarmGround.comreserves the right to
              pursue the member for the amount paid or contributed towards the
              loss.
            </p>
            <p>
              Upon our request, each member agrees to promptly provide to us
              such proof of personal identification, proof that the condition,
              location, or unique characteristics associated with the property
              are accurately described in the listing, proof of ownership of the
              property listed on the Site, and/or proof of authority to list the
              property.
            </p>
            <ol start="25">
              <li>
                <strong>Appearance in Search Results. &nbsp;</strong>
              </li>
            </ol>
            <p>
              We cannot guarantee that your listing will appear in any specific
              order in search results on the Site. Search order will fluctuate
              based on a variety of factors such as search parameters,
              subscription level purchased, listing quality, how frequently a
              calendar is updated, farmer preferences, member response time,
              history, online commitment capability, compliance with posted
              listing practice requirements, and other factors that we may deem
              important to the user experience from time to time. &nbsp;Listing
              appearance or order in any particular search result may also vary
              depending on the search criteria used by the particular farmer. We
              reserve the right to apply various search algorithms or to use
              methods to optimize results for particular farmers’ needs and the
              overall marketplace. Listings placed on a non-subscription basis,
              may not always appear in search results. Listings distributed on
              third-party sites are not guaranteed to display on such
              third-party site in any particular order or at all. &nbsp;Search
              results and order may appear different on Tillable’s mobile
              application than they appear on the Site. To optimize the search
              experience for both members and farmers and improve the match
              process, FarmGround.comretains the right to run occasional tests
              that will be limited in duration but may alter how we display
              listings and search results.
            </p>
            <ol start="26">
              <li>
                <strong>Content, Layout and Copy.</strong>
              </li>
            </ol>
            <p>
              All content and copy edits submitted by members are subject to
              review and approval by us in our sole discretion. &nbsp;We reserve
              the right to refuse to publish any content that we determine in
              our sole discretion does not meet these Terms or is otherwise
              unacceptable to us. &nbsp;However, we assume no duty to review
              content and we shall not have any liability for any loss or damage
              resulting from the design or positioning of the copy, properties,
              content and/or photographs or any change made to any content,
              photograph or copy submitted by any member. All content must meet
              these Terms and our&nbsp;Content Guidelines. We reserve the right
              to edit content submitted to the Site in a non-substantive manner
              solely to ensure that the content complies with our&nbsp;Content
              Guidelines &nbsp;or formatting requirements. &nbsp;&nbsp;Members
              are responsible for reviewing and ensuring that any content
              displayed on the Site appears as the member intended.
            </p>
            <ol start="27">
              <li>
                <strong>Photographs.</strong>
              </li>
            </ol>
            <p>
              Photographs should depict the property as the main subject of the
              photograph. Any information or images that would violate the
              privacy rights, intellectual property rights (including but not
              limited to copyright rights) or any other rights of a third party
              is prohibited. We reserve the right to not display or to remove
              any photographs that we determine, in our sole discretion, do not
              meet these Terms or are otherwise unacceptable to us.
            </p>
            <p>
              By submitting a photograph to us, the member represents and
              warrants that (a) it holds all intellectual property rights
              (including but not limited to all copyright rights), (b) that any
              people in the photograph have given permission for their likeness
              to be displayed in an online advertisement on the Site, (c) that
              the photograph accurately and fairly represents the subject of the
              photograph and has not been altered in any manner that would
              mislead a viewer of that photograph, and (d) that it will
              indemnify and hold harmless the Site and any member of the
              FarmGround.comGroup from any cause of action arising from any
              misrepresentation with respect to any and all photographs so
              submitted or in the event of claims that the use of a photograph
              violates another party’s copyright.
            </p>
            <p>
              It is the member’s responsibility to obtain any and all
              permissions required to use, post and grant the foregoing rights
              in all photographic and other material used in its listings.
              &nbsp;The member warrants that it is the owner of the copyright in
              such material or is authorized by the owner thereof to grant to us
              the rights therein contained and agrees to provide any proof of
              such rights to us that we may request. Each member further agrees
              that we may reproduce in whole or in part any photographic
              material supplied by such member in the promotion of either such
              member’s property or the Site.
            </p>
            <ol start="28">
              <li>
                <strong>Uses of Our Trademarks or Logos.</strong>
              </li>
            </ol>
            <p>
              There are limited ways in which a member may use our trademarks,
              service marks or logos in connection with a listing without
              specific prior written authorization. &nbsp;The following are
              general guidelines. It is usually permissible for you to refer to
              FarmGround.comor the name of one of our affiliate websites on
              which you list your property in a descriptive manner in your
              listing on the Site or in other permissible communications.
              &nbsp;However, you may not refer to FarmGround.comor any of our
              affiliates in any way that might lead someone to believe that your
              company, property, listing or site is sponsored by, affiliated
              with, or endorsed by FarmGround.comor one of our affiliates. You
              may not use the FarmGround.comname or one of our affiliates’ names
              on any other website that lists land rentals without our prior
              written authorization.
            </p>
            <p>
              The FarmGround.comname and logo and those of the
              FarmGround.comGroup and our affiliates are service marks,
              trademarks or registered trademarks in the United States and other
              jurisdictions around the world. &nbsp;We generally do not permit
              the use of our names and logos, other than as described above or
              with our prior written authorization. If you want permission to
              use our name and/or logo in any other manner, including, without
              limitation, on any website, business card, signage, t-shirt, etc.,
              or if you have other questions, you may visit FarmGround.com.
            </p>
            <ol start="29">
              <li>
                <strong>Hypertext Links. &nbsp;</strong>
              </li>
            </ol>
            <p>
              We reserve the right to refuse hypertext links to, or addresses
              of, other websites from members’ pages, and to remove links or web
              addresses without notice at our sole discretion. Further, we
              reserve the right to charge for hypertext links at any time.
            </p>
            <ol start="30">
              <li>
                <strong>
                  Substitution of Properties; Advertising More Than One
                  Property; Property Managers.
                </strong>
              </li>
            </ol>
            <p>
              Each listing must relate to an individual and uniquely identified
              property, unless you purchased a listing package that expressly
              allows for substitution of properties. This means that:
            </p>
            <ul>
              <li>
                The property in a listing may not be substituted for another
                property without our consent. &nbsp;We may approve a request in
                our discretion if the property manager’s contract for the
                property was terminated and the member provides sufficient
                proof, as requested by us, and completes any additional forms we
                may request. &nbsp;The type and term of the listing for any
                substituted property shall be the same as the type and term of
                the originally-listed property (i.e., the term will not be
                extended past the original term). If a member submits changes to
                an existing listing that, if approved, would substantially alter
                the listing to make it that of another property, then we have
                the right to terminate the listing and may choose, in our sole
                discretion, to retain any fees associated previously existing
                listing as compensation for the violation of this condition.
              </li>
              <li>
                The listing cannot be a mere example of properties in a given
                area. &nbsp;Only one property can appear on each listing, unless
                it is a property with multiple units and additional listings are
                purchased. &nbsp;We reserve the right to amend the copy or
                remove any listing when more than one property is described in
                such listing, and may choose, in our sole discretion to retain
                any fees associated such non-conforming listing as compensation
                for the violation of this condition.
              </li>
            </ul>
            <ol start="31">
              <li>
                <strong>Payment Method Requirements;</strong>
              </li>
            </ol>
            <p>
              No member may request any farmer to mail cash, or utilize any
              instant-cash wire transfer service such as Western Union or
              MoneyGram in payment for all or part of a property Lease
              transaction.&nbsp;
              <strong>
                All payments will flow through the payments sections of the
                FarmGround.complatform.
              </strong>
              &nbsp;&nbsp;Any violation of this term or any other unacceptable
              payment methods that may be posted on the Site may result in the
              immediate removal of the non-conforming listing from the Site
              without notice to the member and without refund. &nbsp;From time
              to time, we may become aware of users attempting to conduct a
              transaction that would involve an unauthorized payment method or a
              fraudulent payment method. Ideally, we hope to be able to assist
              users in avoiding such transactions, but we assume no liability or
              responsibility to do so or to inform users of any such actual or
              suspected activity.
            </p>
            <ol start="32">
              <li>
                <strong>
                  Subscription Payments; Automatic Renewal of Subscription
                  Payments.
                </strong>
              </li>
            </ol>
            <p>
              Should FarmGround.comundertake a subscription payment system at
              any time, payment for subscription listings must be made to us in
              U.S. Dollars. &nbsp;&nbsp;Payments could be made by major credit
              or debit card, PayPal, or a check drawn on a U.S. bank at
              Tillable’s sole discretion. For any subscription paid for by
              credit card or PayPal, such subscription shall automatically renew
              at the expiration of the then-current term for an additional term
              of the same duration (as the previous term) and at the
              then-current non-promotional subscription rate. If such
              subscription was purchased by check or another form of payment
              other than by credit card or PayPal (if such other payment form
              was permitted), such subscription shall not be automatically
              renewed. &nbsp;The automatic renewal feature allows your service
              to remain uninterrupted at the expiration of your then-current
              term. If you wish to turn off auto-renewal, you must log on to
              your account and manually turn off auto-renewal in your owner
              dashboard at least five (5) days prior to expiration of the
              then-current term. Upon any such turning off of auto-renewal, your
              subscription will remain active through the expiration of your
              then-current subscription term; however your subscription will not
              be automatically renewed upon the expiration of your then-current
              term. If your subscription does not auto-renew or expires at the
              end of your then-current subscription term and you desire to renew
              your subscription, you will be required to pay the then-current
              non-promotional subscription rate to renew your subscription or to
              activate a new subscription.
            </p>
            <p>
              If you do not turn off auto-renewal and you continue to use our
              subscription service, you re-affirm and authorize us to charge
              your form of payment at the end of each subscription term for an
              additional term of the same duration as the initial term and at
              the then-current, non-promotional subscription rate for the same
              product or service.
            </p>
            <p>
              If the product or service that you last purchased has changed in
              any way or is no longer offered, you agree and authorize us to
              charge your form of payment at the renewal of your subscription
              term for a product or service that is the most similar, as
              determined by us, to the product or service that you previously
              purchased, even if the price of such product or service is not the
              same as the prior product or service that you purchased. You agree
              to be responsible for any such charges, and we reserve the right
              to obtain payment directly from you if necessary. If you wish to
              change your form of payment to be charged or if your form of
              payment information otherwise changes, see FarmGround.com for
              information on updating the payment information in your owner
              dashboard, as applicable or to provide the new or different form
              of payment information.
            </p>
            <p>
              If a Site enables you to list your property on a basis other than
              by subscription, you agree to pay us compensation as described to
              you in the registration process, which compensation may be changed
              by us from time to time without notice by us displaying the
              compensation on the Site on which you registered for the listing.
              &nbsp;The registration process and additional notices you may
              receive from us may also provide additional terms and conditions
              for such listings.
            </p>
            <ol start="33">
              <li>
                <strong>Subscription Term and Refund Requests.</strong>
              </li>
            </ol>
            <p>
              All subscription listings are sold to run the full term that is
              chosen by the member. The term starts on the date that the member
              submits the full or initial (as applicable) payment and expires
              one year thereafter. For example, for an annual subscription term,
              if the member submits payment for the subscription on July 1st,
              the subscription would expire on June 30 of the following year. If
              you renew your subscription listing, or if your subscription
              listing automatically renews, your listing will remain online for
              the new subscription period without refund.
            </p>
            <p>
              Generally, no refunds are available unless a member qualifies for
              a refund under any special program we may have in effect. &nbsp;If
              you believe you qualify for a refund, you may contact customer
              support by sending your request to the address listed under
              “Contact Us” above and include your listing number, and your
              reason for requesting a refund. &nbsp;We will then determine, in
              our sole discretion, whether any refund is due. If you sell your
              property and no longer wish for the listing to remain online,
              please contact us and we can remove the listing; however, no
              refund will be owed.
            </p>
            <ol start="34">
              <li>
                <strong>
                  Distribution of Listings to Third Party Websites.
                </strong>
              </li>
            </ol>
            <p>
              To enable members to obtain broader distribution of their
              properties, we may provide your listing information and content,
              or otherwise provide for the distribution of your listing on a
              third party website. &nbsp;Additional terms and conditions may
              apply to such distributions, as we may notify you of via your
              owner dashboard or email.
            </p>
            <ol start="35">
              <li>
                <strong>Termination of Listings; Other Remedies.</strong>
              </li>
            </ol>
            <p>
              If, in our sole discretion, any member submits unsuitable material
              to our Site or into our database, is not abiding by local
              regulations and is creating a nuisance in its community, misuses
              the Site or our online system or is in material breach of these
              Terms, we reserve the right to limit the member’s use of the Site,
              impact the member’s listing(s) search position, and/or terminate
              such member’s listing immediately without refund. In addition, if
              we become aware of or receive a complaint or a series of
              complaints from any user or other third party regarding a member’s
              listing or practices that, in our sole discretion, warrants the
              immediate removal of such member’s listing from the Site, then we
              may immediately terminate such member’s listing(s) or
              subscription(s) without notice to the member and without refund.
              &nbsp;We assume no duty to investigate complaints. Finally, if any
              member is abusive or offensive to any employee or representative
              of the FarmGround.comGroup, we reserve the right to terminate such
              member’s listing(s) or subscription(s) immediately without refund.
            </p>
            <p>
              In addition to reserving the right to terminate any listing,
              FarmGround.comreserves all rights to respond to any violation of
              the Terms or misuse of the Site by, including, but not limited to,
              hiding a listing from the search results, marking a member as “out
              of office,” and removing or changing information that may be false
              or misleading.
            </p>
            <p>
              Any part of transaction between a member and farmer that relates
              to a listed property on FarmGround.comthat is transacted “off
              Site” or outside of the FarmGround.complatform in which
              FarmGround.com had the capability of performing the task is
              strictly prohibited. Member and farmer are subject to maximum
              penalties as provided by the state of Iowa and will be liable for
              the legal fees undertaken by FarmGround.comto defend its rights.
            </p>
            <p>
              Additionally, you agree to defend and indemnify FarmGround.com,
              its affiliated companies, and any of their officers, directors,
              employees and agents from and against any claims, causes of
              action, demands, recoveries, losses, damages, fines, penalties or
              other costs or expenses of any kind or nature including but not
              limited to reasonable legal and accounting fees, brought by third
              parties as a result of:
            </p>
            <ul>
              <li>
                your breach of these Terms or the documents referenced herein;
              </li>
              <li>
                your violation of any law or the rights of a third party; or
              </li>
              <li>your use of this Site.</li>
            </ul>
          </Col>
        </div>
      </div>
    );
  }
}
