import React, { Component } from "react";
import "./FarmAuctionBidConfirm.css";

export default class FarmAuctionBidConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null
    };
  }
  render() {
    return (
      <div className="FarmAuctionBidConfirm">
        <br />
        <h2>Bid Confirmation</h2>
        <br />
        <h4>Congratulations, You are currently the high bidder!</h4>
        <br />
        <p>
          <strong>
            Your bid has been received for this Auction! Thank you for bidding
            on this farm. Please check the website regularly to make sure you
            are not out bidded by an another bidder.
          </strong>
        </p>
        <p>
          <strong>
            If you happen to win this aution, you will be contacted within 1-2
            days after the auction ended. Good Luck!
          </strong>
        </p>
        <br />
        <br />
        <strong>- FarmGround.com Staff</strong>
        <br />
        <br />
      </div>
    );
  }
}
