import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { listFgFarms as ListFgFarms } from "../graphql/queries";
import { fgFarmByFarmKey as ListFgFarmByFarmKey } from "../graphql_custom/queries";
import { fgFarmAuctionBidByFarmKey as FgFarmAuctionBidByFarmKey } from "../graphql_custom/queries";
import { updateFgFarm as UpdateFgFarm } from "../graphql/mutations";
import { onUpdateFgFarm as OnUpdateFgFarm } from "../graphql/subscriptions";
import { onCreateFgFarmAuctionBid as OnCreateFgFarmAuctionBid } from "../graphql/subscriptions";
import { createFgFarmAuctionBid as CreateFgFarmAuctionBid } from "../graphql/mutations";
import {
  Form,
  FormControl,
  Container,
  Card,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import DateTimeLocal from "../components/DateTimeLocal.js";
import Countdown from "../components/Countdown.js";
import CurrencyInput from "react-currency-input";
import LoaderButton from "../components/LoaderButton";
import uuid from "uuid/v4";
import "./FarmAuctionBid.css";

const FARM_ID = uuid();

export default class FarmAuctionBid extends React.Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: true,
      bidAmountMax: "",
      bidderInitials: ""
    };
  }

  async componentDidMount() {
    try {
      this.updateVisit(this.props.match.params.id);
    } catch (e) {
      alert(e);
    }
  }

  updateVisit(farmKey) {
    return API.put("events", "/fg-farm/visit/" + this.props.match.params.id, {
      body: {
        farmVisits: "1"
      }
    });
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = farm => {
    this.setState({
      [farm.target.id]: farm.target.value
    });
  };

  handleBidAmountMaxChange = event => {
    this.setState({
      bidAmountMax: event.target.value
    });
  };

  handleFileChange = farm => {
    this.file = farm.target.files[0];
  };

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://s3.amazonaws.com/fg-file-storage/public/" + attachment;
    return picUrl;
  }

  validateForm() {
    return (
      this.state.bidAmountMax &&
      !isNaN(this.state.bidAmountMax) &&
      this.state.bidderInitials &&
      this.state.bidderInitials.length > 1
    );
  }

  handleSubmit = async farm => {
    farm.preventDefault();

    this.setState({ isLoading: true });

    try {
      API.graphql(
        graphqlOperation(ListFgFarms, {
          filter: {
            farmKey: {
              eq: this.props.match.params.id
            }
          }
        })
      ).then(graphQLResponse => {
        const farms = graphQLResponse.data.listFgFarms.items;

        var farmItem = null;
        if (Array.isArray(farms)) {
          farmItem = farms[0];
        } else {
          farmItem = farms;
        }

        const { id, farmEndDateTime, farmAuctionBidAmount } = farmItem;

        if (
          Date.parse(new Date(farmEndDateTime)) > Date.parse(new Date()) &&
          parseInt(farmAuctionBidAmount) + 25 <=
            parseInt(this.state.bidAmountMax).toFixed(2)
        ) {
          //Pass validation
          // Add 5 minutes to end time
          var date1 = new Date(farmEndDateTime);
          var date2 = date1.getTime();
          var date3 = date2 + 5 * 60000;

          API.graphql(
            graphqlOperation(UpdateFgFarm, {
              input: {
                id: id,
                farmAuctionBidAmount: parseInt(this.state.bidAmountMax).toFixed(
                  2
                ),
                farmAuctionBidderId: this.props.userId.replace(/[^0-9]/g, ""),
                farmEndDateTime: new Date(date3).toISOString()
              }
            })
          );

          API.graphql(
            graphqlOperation(CreateFgFarmAuctionBid, {
              input: {
                id: FARM_ID,
                bidAmountMax: parseInt(this.state.bidAmountMax).toFixed(2),
                bidderInitials: this.state.bidderInitials,
                bidderId: this.props.userId.replace(/[^0-9]/g, ""),
                farmId: id,
                farmKey: this.props.match.params.id,
                userId: this.props.userId
              }
            })
          );

          this.props.history.push(
            "/farm/auction/bid/confirm/" + this.props.match.params.id
          );
        } else {
          alert("Failed Validation!  Please review your entry and try again");
          return;
        }
      });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  createBid(bid) {
    return API.post("events", "/fg-farm-auction/auction/bid", {
      body: bid
    });
  }

  updateFarmBid(farm) {
    return API.put(
      "events",
      `/fg-farm/farm/updateAuctionBid/${this.state.Id}`,
      {
        body: farm
      }
    );
  }

  renderAuctionBids(bids) {
    return [{}].concat(bids).map((bid, i) =>
      i !== 0 ? (
        <Row key={i}>
          <Col xs={4} md={4}>
            <DateTimeLocal date={bid.createdAt} />
          </Col>
          <Col xs={4} md={4}>
            ${bid.bidAmountMax}
          </Col>
          <Col xs={4} md={4}>
            {bid.bidderId.replace(/[^1-7]/g, "").replace(/[3-5]/g, "*")}
          </Col>
        </Row>
      ) : (
        ""
      )
    );
  }

  onCreatedFgFarmAuctionBid = (prevQuery, newData) => {
    let data = {
      listFgFarmAuctionBids: {
        items: []
      }
    };
    if (
      prevQuery &&
      prevQuery.listFgFarmAuctionBids &&
      prevQuery.listFgFarmAuctionBids.items
    ) {
      let newQuery = prevQuery.listFgFarmAuctionBids.items.concat(
        newData.onCreateFgFarmAuctionBid
      );
      data.listFgFarmAuctionBids.items = newQuery;
      return data;
    }
  };

  renderBid(farm) {
    return (
      <Container>
        <h3>Make an Auction Bid</h3>
        <Row className="show-grid">
          <Col xs={12} md={12} className="card-col">
            <Card>
              <Card.Header as="h6">
                {this.isEmptyOrSpaces(farm.farmSaleType)
                  ? ""
                  : farm.farmSaleType.trim() + ": "}
                {this.isEmptyOrSpaces(farm.farmName)
                  ? "No Content"
                  : farm.farmName.trim()}
              </Card.Header>
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(farm.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                    src={this.getImageUrl(farm.attachment)}
                    alt={
                      this.isEmptyOrSpaces(farm.farmName)
                        ? "Farm Land"
                        : farm.farmName.trim()
                    }
                    responsive="true"
                  />
                ) : (
                  <Card.Img variant="top" src="./images/carousel.png" alt="" />
                )}
              </span>
              <Card.Body>
                <Card.Text>
                  <Container>
                    {this.props.userId &&
                    farm.farmAuctionBidderId ===
                      this.props.userId.replace(/[^0-9]/g, "") ? (
                      <Row className="show-grid">
                        <Col xs={12} md={12} className="card-col">
                          <Alert key={1} variant="success">
                            You are currently the high bidder!
                          </Alert>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row className="show-grid">
                      <Col xs={12} md={12} className="card-col">
                        <h5>
                          {this.isEmptyOrSpaces(farm.farmSaleType)
                            ? ""
                            : farm.farmSaleType.trim() + ": "}
                          {this.isEmptyOrSpaces(farm.farmName)
                            ? "No Content"
                            : farm.farmName.trim()}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={12} md={7} className="card-col">
                        <Row className="row">
                          <Col xs={12} md={4}>
                            <strong>County:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            {farm.farmCounty + " County"}
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>State:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            {farm.farmState}
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>Total Acres:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            {this.isEmptyOrSpaces(farm.farmTotalAcres)
                              ? "No Content"
                              : farm.farmTotalAcres.trim() + " M/L"}
                          </Col>
                        </Row>
                        <Row className="row">
                          <Col xs={12} md={12}>
                            <hr />
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>Current High Bid:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            {"$" + farm.farmAuctionBidAmount}
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>Bidder Id:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            {farm.farmAuctionBidderId
                              .replace(/[^1-7]/g, "")
                              .replace(/[3-5]/g, "*")}
                            {farm.isHighBidder ? " (You)" : ""}
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>End Date/Time:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            <DateTimeLocal date={farm.farmEndDateTime} />
                          </Col>
                          <Col xs={12} md={4}>
                            <strong>Time Left:</strong>
                          </Col>
                          <Col xs={12} md={8}>
                            <Countdown date={farm.farmEndDateTime} />
                          </Col>
                        </Row>
                        <Col xs={12} md={12}>
                          <hr />
                        </Col>
                        <Row>
                          <Col xs={12} md={12}>
                            <Row>
                              <Col xs={12} md={12}>
                                <strong>Bid History</strong>
                              </Col>
                              <Col xs={12} md={12}>
                                <br />
                              </Col>
                              <Col xs={4} md={4}>
                                <strong>Date/Time</strong>
                              </Col>
                              <Col xs={4} md={4}>
                                <strong>Bid Amount / Acre</strong>
                              </Col>
                              <Col xs={4} md={4}>
                                <strong>Bidder Id</strong>
                              </Col>
                            </Row>
                            <Connect
                              query={graphqlOperation(
                                FgFarmAuctionBidByFarmKey,
                                {
                                  farmKey: this.props.match.params.id,
                                  sortDirection: "DESC"
                                }
                              )}
                              subscription={graphqlOperation(
                                OnCreateFgFarmAuctionBid,
                                {
                                  filter: {
                                    farmKey: {
                                      eq: this.props.match.params.id
                                    }
                                  }
                                }
                              )}
                              onSubscriptionMsg={this.onCreatedFgFarmAuctionBid}
                            >
                              {({ data, loading, errors }) => {
                                if (loading) {
                                  return <div>Loading...</div>;
                                }
                                if (!data.fgFarmAuctionBidByFarmKey) return;
                                return this.renderAuctionBids(
                                  data.fgFarmAuctionBidByFarmKey.items
                                );
                              }}
                            </Connect>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={5} className="card-col offer-col">
                        {Date.parse(new Date(farm.farmEndDateTime)) >
                        Date.parse(new Date()) ? (
                          <Form onSubmit={this.handleSubmit}>
                            <Row>
                              <Col>
                                <br />
                                <h5>Auction Bid Form</h5>
                                <hr />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm>
                                <Form.Group controlId="bidAmountMax">
                                  <Form.Label>Bid Amount Per Acre:</Form.Label>
                                  <CurrencyInput
                                    value={this.state.bidAmountMax}
                                    className="form-control"
                                    onChangeEvent={
                                      this.handleBidAmountMaxChange
                                    }
                                    aria-label="Amount (to the nearest dollar)"
                                  />
                                  <Form.Text className="text-muted">
                                    Enter a dollar amount that you will pay per
                                    acre (i.e.{" "}
                                    {parseInt(farm.farmAuctionBidAmount) +
                                      100.0}
                                    ). Must be at least 25 dollars more than
                                    current high bid. Must be a whole number.
                                  </Form.Text>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <strong>Terms:</strong>
                              </Col>
                              <Col xs={12} md={12}>
                                <small className="text-muted form-text">
                                  Immediately upon conclusion of the auction the
                                  high bidder will enter into a real estate
                                  contract and deposit with the seller of this
                                  land or other approved holding account the
                                  proper non-refundable earnest deposit. The
                                  Seller(s) will provide a current abstract or
                                  other title conveyance instrument at their own
                                  expense unless otherwise noted. The sale is
                                  not contingent upon buyer financing, all bids
                                  are cash bids. This sale is subject to all
                                  easements, covenants, leases, and restrictions
                                  of record. Buyers must do their due diligence
                                  prior to bidding. All property is sold on an
                                  "As is – Where is" basis with no warranties or
                                  guarantees, expressed or implied, made by the
                                  Auctioneer or Seller unless specifically
                                  stated. You agree by bidding that you will
                                  enroll any CRP acres in your name upon gaining
                                  ownership or abide by continuing requires of
                                  any government programs or conservation
                                  programs on the farm or pay any necessary
                                  penalties should you choose not to. The seller
                                  reserves the right to accept or reject any and
                                  all bids. The successful bidder acknowledges
                                  that they are representing themselves or have
                                  hired representation using their own financial
                                  means to complete the auction sales
                                  transaction. Any updated made will take
                                  precedence over any previous material or oral
                                  statements. The Auctioneer may accept
                                  absentee, phone or Internet bids from bidders
                                  who can not bid on behalf of the seller to
                                  advance toward any reserve until the reserve
                                  is achieved. Bidding remotely does not
                                  guarantee anonymity. All decisions of the
                                  seller are final. The auction is open to the
                                  public contingent upon proper behavior. <br />
                                  <br />
                                </small>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Form.Group controlId="bidderInitials">
                                  <Form.Label>Terms Agreement:</Form.Label>
                                  <FormControl
                                    as="select"
                                    value={this.state.bidderInitials}
                                    placeholder="Do you agree to the terms above?"
                                    onChange={this.handleChange}
                                  >
                                    <option value="">
                                      Do you agree to the terms above?
                                    </option>
                                    <option value="Yes, I Agree">
                                      Yes, I Agree
                                    </option>
                                    <option value="">No</option>
                                  </FormControl>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm>
                                <LoaderButton
                                  block
                                  bsstyle="primary"
                                  bssize="large"
                                  disabled={!this.validateForm()}
                                  type="submit"
                                  isLoading={farm.isLoading}
                                  text="Place Bid"
                                  loadingText="Saving…"
                                />
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          "Sorry, This farm auction has ended!"
                        )}
                      </Col>
                    </Row>
                    {farm.farmAgentName && (
                      <Row className="row">
                        <Col xs={12} md={12}>
                          <hr />
                        </Col>

                        <Col xs={12} md={12}>
                          <strong>Agent Information</strong>
                        </Col>
                        <Col xs={12} md={12}>
                          <br />
                        </Col>
                        <Col xs={12} md={2}>
                          <strong>Name:</strong>
                        </Col>
                        <Col xs={12} md={3}>
                          {farm.farmAgentName}
                        </Col>
                        {!this.isEmptyOrSpaces(farm.farmAgentLicensedIn) && (
                          <React.Fragment>
                            <Col xs={12} md={2}>
                              <strong>State(s) Licensed:</strong>
                            </Col>
                            <Col xs={12} md={3}>
                              {farm.farmAgentLicensedIn}
                            </Col>
                          </React.Fragment>
                        )}
                        {!this.isEmptyOrSpaces(farm.farmAgentWorkPhone) && (
                          <React.Fragment>
                            <Col xs={12} md={2}>
                              <strong>Work Phone:</strong>
                            </Col>
                            <Col xs={12} md={3}>
                              {farm.farmAgentWorkPhone}
                            </Col>
                          </React.Fragment>
                        )}
                        {!this.isEmptyOrSpaces(farm.farmAgentCellPhone) && (
                          <React.Fragment>
                            <Col xs={12} md={2}>
                              <strong>Cell Phone:</strong>
                            </Col>
                            <Col xs={12} md={3}>
                              {farm.farmAgentCellPhone}
                            </Col>
                          </React.Fragment>
                        )}
                        {!this.isEmptyOrSpaces(farm.farmAgentAddress) && (
                          <React.Fragment>
                            <Col xs={12} md={2}>
                              <strong>Address:</strong>
                            </Col>
                            <Col xs={12} md={3}>
                              {farm.farmAgentAddress}
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>
                    )}
                  </Container>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  onUpdatedFgFarm = (prevQuery, newData) => {
    let data = {
      listFgFarms: {
        items: []
      }
    };
    if (prevQuery && prevQuery.listFgFarms && prevQuery.listFgFarms.items) {
      data.listFgFarms.items[0] = newData.onUpdateFgFarm;

      this.setState({
        farmAuctionBidAmount: newData.onUpdateFgFarm.farmAuctionBidAmount,
        farmEndDateTime: newData.onUpdateFgFarm.farmEndDateTime
      });

      return data;
    }
  };

  render() {
    return (
      <div className="FarmAuctionBid">
        <Connect
          query={graphqlOperation(ListFgFarmByFarmKey, {
            farmKey: this.props.match.params.id
          })}
          subscription={graphqlOperation(OnUpdateFgFarm, {
            filter: {
              farmKey: {
                eq: this.props.match.params.id
              }
            }
          })}
          onSubscriptionMsg={this.onUpdatedFgFarm}
        >
          {({ data, loading, errors }) => {
            if (loading) {
              return <div>Loading...</div>;
            }
            if (!data.fgFarmByFarmKey) return;
            let farm = data.fgFarmByFarmKey.items[0];
            return this.renderBid(farm);
          }}
        </Connect>
      </div>
    );
  }
}
