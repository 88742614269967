/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fgFarmByAdLevel = /* GraphQL */ `
  query FgFarmByAdLevel(
    $adLevel: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByAdLevel(
      adLevel: $adLevel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        farmKey
        attachment
        farmName
        farmStatusNumber
        farmSaleType
        farmTotalPrice
        farmTotalAcres
        farmTags
        farmAddress
        farmCity
        farmState
        farmPostalCd
        farmCounty
      }
      nextToken
    }
  }
`;
export const fgFarmByFarmSaleType = `query FgFarmByFarmSaleType(
  $farmSaleType: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgFarmFilterInput
  $limit: Int
  $nextToken: String
) {
  fgFarmByFarmSaleType(
    farmSaleType: $farmSaleType
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      farmKey
      attachment
      farmName
      farmStatusNumber
      farmSaleType
      farmTotalPrice
      farmTotalAcres
      farmTags
      farmAddress
      farmCity
      farmState
      farmPostalCd
      farmCounty
    }
    nextToken
  }
}
`;
export const fgFarmByUserId = `query FgFarmByUserId(
  $userId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgFarmFilterInput
  $limit: Int
  $nextToken: String
) {
  fgFarmByUserId(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      farmKey
      attachment
      farmName
      farmStatusNumber
      farmSaleType
      farmTotalPrice
      farmTotalAcres
      farmTags
      farmAddress
      farmCity
      farmState
      farmPostalCd
      farmCounty
    }
    nextToken
  }
}
`;
export const listFgFarms = `query ListFgFarms(
  $filter: ModelFgFarmFilterInput
  $limit: Int
  $nextToken: String
) {
  listFgFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userKey
      userAttachment
      adLevel
      adRank
      orgKey
      lastUpdatedAt
    }
    nextToken
  }
}
`;
export const fgFarmByFarmKey = `query FgFarmByFarmKey(
  $farmKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgFarmFilterInput
  $limit: Int
  $nextToken: String
) {
  fgFarmByFarmKey(
    farmKey: $farmKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userKey
      userAttachment
      adLevel
      adRank
      orgKey
      lastUpdatedAt
    }
    nextToken
  }
}
`;
export const fgFarmAuctionBidByFarmKey = `query FgFarmAuctionBidByFarmKey(
  $farmKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgFarmAuctionBidFilterInput
  $limit: Int
  $nextToken: String
) {
  fgFarmAuctionBidByFarmKey(
    farmKey: $farmKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      farmKey
      bidAmountMax
      bidderId
      bidderInitials
      createdAt
    }
    nextToken
  }
}
`;
export const fgFarmRentOfferByFarmKey = `query FgFarmRentOfferByFarmKey(
  $farmKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgFarmRentOfferFilterInput
  $limit: Int
  $nextToken: String
) {
  fgFarmRentOfferByFarmKey(
    farmKey: $farmKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      createdAt
    }
    nextToken
  }
}
`;
export const fgOrgByKey = `query FgOrgByKey(
  $key: String
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFgOrgFilterInput
  $limit: Int
  $nextToken: String
) {
  fgOrgByKey(
    key: $key
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
    nextToken
  }
}
`;
export const listFgOrgs = `query ListFgOrgs(
  $filter: ModelFgOrgFilterInput
  $limit: Int
  $nextToken: String
) {
  listFgOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
    nextToken
  }
}
`;
