import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Form, FormControl } from "react-bootstrap";
import { s3Upload } from "../libs/awsLib";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./AboutUsEdit.css";

export default class AboutUsEdit extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      aboutUs: null,
      heading: "",
      description: "",
      attachmentURL: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const aboutUs = await this.getAboutUs();
      const { heading, description, attachment } = aboutUs;

      if (attachment) {
        attachmentURL = await Storage.get(attachment);
      }

      this.setState({
        aboutUs,
        heading,
        description,
        attachmentURL
      });
    } catch (e) {
      alert(e);
    }
  }

  getAboutUs() {
    return API.get("events", `/aboutUs/aboutUs/${config.ORG_ID}`);
  }

  validateForm() {
    return (
      this.state.heading &&
      this.state.heading.length > 0 &&
      this.state.description &&
      this.state.description.length > 0
    );
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = aboutUs => {
    this.setState({
      [aboutUs.target.id]: aboutUs.target.value
    });
  };

  handleFileChange = aboutUs => {
    this.file = aboutUs.target.files[0];
  };

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  handleSubmit = async aboutUs => {
    let attachment;

    aboutUs.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveAboutUs({
        heading: this.isEmptyOrSpaces(this.state.heading)
          ? null
          : this.state.heading,
        description: this.isEmptyOrSpaces(this.state.description)
          ? null
          : this.state.description,
        attachment: attachment || this.state.aboutUs.attachment
      });
      this.props.history.push("/aboutUs");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  saveAboutUs(aboutUs) {
    return API.put("events", `/aboutUs/aboutUs/${config.ORG_ID}`, {
      body: aboutUs
    });
  }

  render() {
    return (
      <div className="AboutUsEdit">
        <h3>Edit About Us</h3>
        {this.state.aboutUs && (
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="heading">
              <Form.Label>Page heading:</Form.Label>
              <FormControl
                type="text"
                value={this.state.heading}
                placeholder="Enter the aboutUsanization name"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Page Content:</Form.Label>
              <FormControl
                componentClass="textarea"
                value={this.state.description}
                placeholder="Enter the page content"
                onChange={this.handleChange}
              />
            </Form.Group>
            {this.state.aboutUs.attachment && (
              <Form.Group>
                <Form.Label>Attach Page Image:</Form.Label>
                <FormControl.Static>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.attachmentURL}
                  >
                    {this.formatFilename(this.state.aboutUs.attachment)}
                  </a>
                </FormControl.Static>
              </Form.Group>
            )}
            <Form.Group controlId="file">
              {!this.state.aboutUs.attachment && (
                <Form.Label>Attachment</Form.Label>
              )}
              <FormControl onChange={this.handleFileChange} type="file" />
            </Form.Group>
            <LoaderButton
              block
              bsstyle="primary"
              bssize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save Changes"
              loadingText="Saving…"
            />
          </Form>
        )}
      </div>
    );
  }
}
