import React, { Component } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { fgFarmByFarmSaleType as FgFarmByFarmSaleType } from "../graphql_custom/queries";
import ListingCardCols from "../components/ListingCardCols";
import "./ForSale.css";

export default class ForSale extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      filterState: "",
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      this.setState({ filterState: this.props.match.params.id });

      this.getFarms(this.props.match.params.id);

      this.setState({ isLoading: false });
    } catch (e) {
      console.log(e);
    }
  }

  getFarms = (filterState) => {
    this.setState({ isLoading: true });

    try {
      var filterVal = "";
      if (this.isEmptyOrSpaces(filterState)) {
        filterVal = {
          farmStatusNumber: {
            lt: 700,
          },
        };
      } else {
        filterVal = {
          farmStatusNumber: {
            lt: 700,
          },
          farmState: {
            eq: filterState,
          },
        };
      }

      API.graphql(
        graphqlOperation(FgFarmByFarmSaleType, {
          farmSaleType: "For Sale",
          filter: filterVal,
          sortDirection: "DESC",
          limit: 48,
        })
      ).then((graphQLResponse) => {
        if (
          graphQLResponse &&
          graphQLResponse.data &&
          graphQLResponse.data.fgFarmByFarmSaleType
        ) {
          const farms = graphQLResponse.data.fgFarmByFarmSaleType.items;

          this.setState({ farms });
        } else {
          this.setState({ farms: [] });
        }
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({ isLoading: false });
  };

  handleFilterStateChange = (event) => {
    var filterSt = event.target.value;
    this.setState({ filterState: filterSt });

    this.getFarms(filterSt);

    this.props.history.push("/for-sale/" + filterSt);
  };

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  render() {
    return (
      <div className="ForSale">
        <Row>
          <Col xs={12} md={6}>
            <h4 className="mb-3">
              {this.props.match.params.id} For Sale Listings
            </h4>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="filterState">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Filter By State:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={this.state.filterState}
                  onChange={this.handleFilterStateChange}
                >
                  <option value="">-- View All-- </option>
                  <option value="Alabama">Alabama</option>
                  <option value="Alaska">Alaska</option>
                  <option value="Arizona">Arizona</option>
                  <option value="Arkansas">Arkansas</option>
                  <option value="California">California</option>
                  <option value="Colorado">Colorado</option>
                  <option value="Connecticut">Connecticut</option>
                  <option value="Delaware">Delaware</option>
                  <option value="Florida">Florida</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Idaho">Idaho</option>
                  <option value="Illinois">Illinois</option>
                  <option value="Indiana">Indiana</option>
                  <option value="Iowa">Iowa</option>
                  <option value="Kansas">Kansas</option>
                  <option value="Kentucky">Kentucky</option>
                  <option value="Louisiana">Louisiana</option>
                  <option value="Maine">Maine</option>
                  <option value="Maryland">Maryland</option>
                  <option value="Massachusetts">Massachusetts</option>
                  <option value="Michigan">Michigan</option>
                  <option value="Minnesota">Minnesota</option>
                  <option value="Mississippi">Mississippi</option>
                  <option value="Missouri">Missouri</option>
                  <option value="Montana">Montana</option>
                  <option value="Nebraska">Nebraska</option>
                  <option value="Nevada">Nevada</option>
                  <option value="New Hampshire">New Hampshire</option>
                  <option value="New Jersey">New Jersey</option>
                  <option value="New Mexico">New Mexico</option>
                  <option value="New York">New York</option>
                  <option value="North Carolina">North Carolina</option>
                  <option value="North Dakota">North Dakota</option>
                  <option value="Ohio">Ohio</option>
                  <option value="Oklahoma">Oklahoma</option>
                  <option value="Oregon">Oregon</option>
                  <option value="Pennsylvania">Pennsylvania</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Rhode Island">Rhode Island</option>
                  <option value="South Carolina">South Carolina</option>
                  <option value="South Dakota">South Dakota</option>
                  <option value="Tennessee">Tennessee</option>
                  <option value="Texas">Texas</option>
                  <option value="Utah">Utah</option>
                  <option value="Vermont">Vermont</option>
                  <option value="Virginia">Virginia</option>
                  <option value="Virgin Islands">Virgin Islands</option>
                  <option value="Washington">Washington</option>
                  <option value="West Virginia">West Virginia</option>
                  <option value="Wisconsin">Wisconsin</option>
                  <option value="Wyoming">Wyoming</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="well well-sm">
          <a href={`/dashboard`}>List your farm on FarmGround.com!</a>
        </div>
        <hr />
        <Container>
          <Row className="show-grid">
            {!this.state.isLoading ? (
              this.state.farms.length > 0 ? (
                <ListingCardCols
                  farms={this.state.farms}
                  edit={false}
                ></ListingCardCols>
              ) : (
                <span className="bold">
                  <i>
                    No farms listed yet for the state of{" "}
                    {this.state.filterState}! Be the first and list your farm
                    now!
                  </i>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              )
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
