import React from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { getFgUser as GetFgUser } from "../graphql/queries";
import { getFgOrg as GetFgOrg } from "../graphql/queries";
import { updateFgOrg as UpdateFgOrg } from "../graphql/mutations";
import { updateFgUser as UpdateFgUser } from "../graphql/mutations";
import { createFgOrg as CreateFgOrg } from "../graphql/mutations";
import { listFgUsers as ListFgUsers } from "../graphql/queries";
import { onUpdateFgUser as OnUpdateFgUser } from "../graphql/subscriptions";
import {
  Tabs,
  Tab,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { s3UploadWithId } from "../libs/awsLib";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./OrgEdit.css";

export default class OrgEdit extends React.Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isEdit: false,
      org: null,
      attachmentURL: null,
      id: "",
      attachment: "",
      orgType: "Agent",
      email: "",
      phone: "",
      name: "",
      description: "",
      stateLicensedIn: [],
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      rank: 0,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const graphQLUserResponse = await API.graphql(
        graphqlOperation(GetFgUser, { id: this.props.userId })
      );

      if (
        graphQLUserResponse &&
        graphQLUserResponse.data &&
        graphQLUserResponse.data.getFgUser
      ) {
        const usr = graphQLUserResponse.data.getFgUser;

        let orgId = usr.hasOwnProperty("orgId") ? usr.orgId : null;
        let orgAdmin = usr.hasOwnProperty("orgAdmin") ? usr.orgAdmin : null;

        if (
          !this.isEmptyOrSpaces(orgId) &&
          !this.isEmptyOrSpaces(orgAdmin) &&
          orgAdmin === "true"
        ) {
          const graphQLResponse = await API.graphql(
            graphqlOperation(GetFgOrg, { id: orgId })
          );

          if (
            graphQLResponse &&
            graphQLResponse.data &&
            graphQLResponse.data.getFgOrg
          ) {
            this.setState({ isEdit: true });

            const org = graphQLResponse.data.getFgOrg;

            const {
              id,
              attachment,
              orgType,
              email,
              phone,
              name,
              description,
              stateLicensedIn,
              address1,
              address2,
              city,
              state,
              zip,
              key,
              rank,
            } = org;

            let attachmentURL;
            if (attachment) {
              attachmentURL = await Storage.vault.get(attachment);
            }

            this.setState({
              id,
              attachment,
              orgType,
              email,
              phone,
              name,
              description,
              stateLicensedIn,
              address1,
              address2: this.isEmptyOrSpaces(address2) ? "" : address2,
              city,
              state,
              zip,
              orgKey: key,
              orgId: id,
              attachmentURL,
              rank,
            });
          }
        }
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }

  validateForm() {
    return (
      this.state.name &&
      this.state.name.length > 0 &&
      this.state.stateLicensedIn &&
      this.state.stateLicensedIn.length &&
      this.state.email &&
      this.state.email.length &&
      this.state.phone &&
      this.state.phone.length &&
      this.state.address1 &&
      this.state.address1.length > 0 &&
      this.state.city &&
      this.state.city.length > 0 &&
      this.state.state &&
      this.state.state.length > 0 &&
      this.state.zip &&
      this.state.zip.length > 0 &&
      this.state.orgType &&
      this.state.orgType.length
    );
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  handleChange = (user) => {
    this.setState({
      [user.target.id]: user.target.value,
    });
  };

  handleOrgApprovedChange = (event) => {
    const orgApprovedVal = event.target.checked ? "true" : "false";
    const orgAdminVal = event.target.checked ? this.state.orgAdmin : "false";
    const orgKeyVal = this.state.orgKey;

    try {
      API.graphql(
        graphqlOperation(UpdateFgUser, {
          input: {
            id: event.target.id,
            orgAdmin: orgAdminVal,
            orgApproved: orgApprovedVal,
            orgKey: orgKeyVal,
          },
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  handleOrgAdminChange = (event) => {
    const orgAdminVal = event.target.checked ? "true" : "false";
    const orgApprovedVal = event.target.checked
      ? "true"
      : this.state.orgApproved;
    const orgKeyVal = this.state.orgKey;

    try {
      API.graphql(
        graphqlOperation(UpdateFgUser, {
          input: {
            id: event.target.id,
            orgAdmin: orgAdminVal,
            orgApproved: orgApprovedVal,
            orgKey: orgKeyVal,
          },
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  handleMultiSelectChange = (e) => {
    var options = e.target.options;

    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [e.target.id]: value });
  };

  handleFileChange = (user) => {
    this.file = user.target.files[0];
  };

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  handleSubmit = async (farm) => {
    let attachment;

    farm.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3UploadWithId(this.props.userId, this.file);
      }

      if (this.state.isEdit) {
        await API.graphql(
          graphqlOperation(UpdateFgOrg, {
            input: {
              id: this.state.id,
              key: this.isEmptyOrSpaces(this.state.orgKey)
                ? (
                    this.state.name +
                    " " +
                    this.state.city +
                    " " +
                    this.state.state
                  )
                    .replace(/[^A-Za-z0-9\s\/]/g, "")
                    .replace(/\s+/g, "-")
                    .replace(/\//g, "-")
                : this.state.orgKey,
              orgType: this.isEmptyOrSpaces(this.state.orgType)
                ? null
                : this.state.orgType,
              email: this.isEmptyOrSpaces(this.state.email)
                ? null
                : this.state.email,
              phone: this.isEmptyOrSpaces(this.state.phone)
                ? null
                : this.state.phone,
              name: this.isEmptyOrSpaces(this.state.name)
                ? null
                : this.state.name,
              description: this.isEmptyOrSpaces(this.state.description)
                ? null
                : this.state.description,
              stateLicensedIn: this.isEmptyOrSpaces(this.state.stateLicensedIn)
                ? null
                : this.state.stateLicensedIn,
              address1: this.isEmptyOrSpaces(this.state.address1)
                ? null
                : this.state.address1,
              address2: this.isEmptyOrSpaces(this.state.address2)
                ? null
                : this.state.address2,
              city: this.isEmptyOrSpaces(this.state.city)
                ? null
                : this.state.city,
              state: this.isEmptyOrSpaces(this.state.state)
                ? null
                : this.state.state,
              zip: this.isEmptyOrSpaces(this.state.zip) ? null : this.state.zip,
              attachment: this.isEmptyOrSpaces(attachment)
                ? this.isEmptyOrSpaces(this.state.attachment)
                  ? null
                  : this.state.attachment
                : attachment,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(CreateFgOrg, {
            input: {
              id: this.props.userId,
              key: (
                this.state.name +
                " " +
                this.state.city +
                " " +
                this.state.state
              )
                .replace(/[^A-Za-z0-9\s\/]/g, "")
                .replace(/\s+/g, "-")
                .replace(/\//g, "-"),
              orgType: this.isEmptyOrSpaces(this.state.orgType)
                ? null
                : this.state.orgType,
              email: this.isEmptyOrSpaces(this.state.email)
                ? null
                : this.state.email,
              phone: this.isEmptyOrSpaces(this.state.phone)
                ? null
                : this.state.phone,
              name: this.isEmptyOrSpaces(this.state.name)
                ? null
                : this.state.name,
              description: this.isEmptyOrSpaces(this.state.description)
                ? null
                : this.state.description,
              stateLicensedIn: this.isEmptyOrSpaces(this.state.stateLicensedIn)
                ? null
                : this.state.stateLicensedIn,
              address1: this.isEmptyOrSpaces(this.state.address1)
                ? null
                : this.state.address1,
              address2: this.isEmptyOrSpaces(this.state.address2)
                ? null
                : this.state.address2,
              city: this.isEmptyOrSpaces(this.state.city)
                ? null
                : this.state.city,
              state: this.isEmptyOrSpaces(this.state.state)
                ? null
                : this.state.state,
              zip: this.isEmptyOrSpaces(this.state.zip) ? null : this.state.zip,
              attachment: this.isEmptyOrSpaces(attachment)
                ? this.isEmptyOrSpaces(this.state.attachment)
                  ? null
                  : this.state.attachment
                : attachment,
              rank: 0,
            },
          })
        );
      }

      this.props.history.push("/dashboard");
    } catch (e) {
      alert(e);
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  renderUsersCols(users) {
    return [{}].concat(users).map((user, i) =>
      i !== 0 ? (
        <Col xs={6} md={3} className="card-col" key={i}>
          <Card key={i}>
            <span className="card-img-top">
              {!this.isEmptyOrSpaces(user.attachment) ? (
                <Card.Img
                  variant="top"
                  className="img-fluid"
                  src={this.getImageUrl(user.attachment)}
                  alt={
                    this.isEmptyOrSpaces(user.givenName)
                      ? "View more details..."
                      : "View more details about " + user.givenName.trim()
                  }
                  responsive="true"
                />
              ) : (
                <Card.Img variant="top" src="./images/carousel.png" alt="" />
              )}
            </span>
            <Card.Body>
              <Card.Text>
                <span className="bold">
                  <h6>{user.givenName.trim() + " " + user.familyName}</h6>
                  {this.isEmptyOrSpaces(user.email) ? "?" : user.email.trim()}
                  <br />
                  {"Phone: " + user.phone}
                </span>
                <hr />
                {user.address1}
                <br />
                {user.city + ", " + user.state + " " + user.zip}
                <hr />
                <Form.Group controlId={user.id}>
                  <Form.Check
                    type="checkbox"
                    label="Approved"
                    disabled={user.id === this.props.userId}
                    onChange={this.handleOrgApprovedChange}
                    defaultChecked={user.orgApproved === "true"}
                  />
                  <Form.Text className="text-muted">
                    User is part of your Agency?
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId={user.id}>
                  <Form.Check
                    type="checkbox"
                    label="Agency Administrator"
                    disabled={user.id === this.props.userId}
                    onChange={this.handleOrgAdminChange}
                    defaultChecked={user.orgAdmin === "true"}
                  />{" "}
                  <Form.Text className="text-muted">
                    User can administor your Agency and Users?
                  </Form.Text>
                </Form.Group>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ) : (
        ""
      )
    );
  }

  render() {
    return (
      <div className="OrgEdit">
        <h3>Agency Profile</h3>
        <br />
        <Container>
          {!this.state.isLoading && (
            <Tabs defaultActiveKey="profile" id="uncontrolled-tab">
              <Tab eventKey="profile" title="Manage Profile">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col sm>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <Form.Group controlId="name">
                        <Form.Label>*Agency Name:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.name}
                          placeholder="Enter your agency name"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group controlId="orgType">
                        <Form.Label>*Type:</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.orgType}
                          onChange={this.handleChange}
                        >
                          <option value="">Select your org type</option>
                          <option value="Agent">Real Estate Agency</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <Form.Group controlId="description">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="2"
                          value={this.state.description}
                          placeholder="Enter a description"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>{" "}
                    <Col sm>
                      <Form.Group controlId="stateLicensedIn">
                        <Form.Label>*States Licensed In:</Form.Label>
                        <Form.Control
                          as="select"
                          multiple
                          value={this.state.stateLicensedIn}
                          onChange={this.handleMultiSelectChange}
                        >
                          <option value="Alabama">Alabama</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Arizona">Arizona</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="Delaware">Delaware</option>
                          <option value="District of Columbia">
                            District of Columbia
                          </option>
                          <option value="Florida">Florida</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Guam">Guam</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Idaho">Idaho</option>
                          <option value="Illinois">Illinois</option>
                          <option value="Indiana">Indiana</option>
                          <option value="Iowa">Iowa</option>
                          <option value="Kansas">Kansas</option>
                          <option value="Kentucky">Kentucky</option>
                          <option value="Louisiana">Louisiana</option>
                          <option value="Maine">Maine</option>
                          <option value="Maryland">Maryland</option>
                          <option value="Massachusetts">Massachusetts</option>
                          <option value="Michigan">Michigan</option>
                          <option value="Minnesota">Minnesota</option>
                          <option value="Mississippi">Mississippi</option>
                          <option value="Missouri">Missouri</option>
                          <option value="Montana">Montana</option>
                          <option value="Nebraska">Nebraska</option>
                          <option value="Nevada">Nevada</option>
                          <option value="New Hampshire">New Hampshire</option>
                          <option value="New Jersey">New Jersey</option>
                          <option value="New Mexico">New Mexico</option>
                          <option value="New York">New York</option>
                          <option value="North Carolina">North Carolina</option>
                          <option value="North Dakota">North Dakota</option>
                          <option value="Northern Marianas Islands">
                            Northern Marianas Islands
                          </option>
                          <option value="Ohio">Ohio</option>
                          <option value="Oklahoma">Oklahoma</option>
                          <option value="Oregon">Oregon</option>
                          <option value="Pennsylvania">Pennsylvania</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Rhode Island">Rhode Island</option>
                          <option value="South Carolina">South Carolina</option>
                          <option value="South Dakota">South Dakota</option>
                          <option value="Tennessee">Tennessee</option>
                          <option value="Texas">Texas</option>
                          <option value="Utah">Utah</option>
                          <option value="Vermont">Vermont</option>
                          <option value="Virginia">Virginia</option>
                          <option value="Virgin Islands">Virgin Islands</option>
                          <option value="Washington">Washington</option>
                          <option value="West Virginia">West Virginia</option>
                          <option value="Wisconsin">Wisconsin</option>
                          <option value="Wyoming">Wyoming</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          You may Ctrl-click (Windows) or command-click (Mac) to
                          select more than one
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <Form.Group controlId="email">
                        <Form.Label>*Email:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.email}
                          placeholder="Enter your email address"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm>
                      <Form.Group controlId="phone">
                        <Form.Label>*Phone:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.phone}
                          placeholder="Enter your main phone number"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <Form.Group controlId="address1">
                        <Form.Label>*Street Address 1:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.address1}
                          placeholder="Enter the street address"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group controlId="address2">
                        <Form.Label>Street Address 2:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.address2}
                          placeholder="Enter the street address"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <Form.Group controlId="city">
                        <Form.Label>*City:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.city}
                          placeholder="Enter city the farm is located"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group controlId="state">
                        <Form.Label>*State:</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.state}
                          onChange={this.handleChange}
                        >
                          <option value="">Select US State</option>
                          <option value="Alabama">Alabama</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Arizona">Arizona</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="Delaware">Delaware</option>
                          <option value="District of Columbia">
                            District of Columbia
                          </option>
                          <option value="Florida">Florida</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Guam">Guam</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Idaho">Idaho</option>
                          <option value="Illinois">Illinois</option>
                          <option value="Indiana">Indiana</option>
                          <option value="Iowa">Iowa</option>
                          <option value="Kansas">Kansas</option>
                          <option value="Kentucky">Kentucky</option>
                          <option value="Louisiana">Louisiana</option>
                          <option value="Maine">Maine</option>
                          <option value="Maryland">Maryland</option>
                          <option value="Massachusetts">Massachusetts</option>
                          <option value="Michigan">Michigan</option>
                          <option value="Minnesota">Minnesota</option>
                          <option value="Mississippi">Mississippi</option>
                          <option value="Missouri">Missouri</option>
                          <option value="Montana">Montana</option>
                          <option value="Nebraska">Nebraska</option>
                          <option value="Nevada">Nevada</option>
                          <option value="New Hampshire">New Hampshire</option>
                          <option value="New Jersey">New Jersey</option>
                          <option value="New Mexico">New Mexico</option>
                          <option value="New York">New York</option>
                          <option value="North Carolina">North Carolina</option>
                          <option value="North Dakota">North Dakota</option>
                          <option value="Northern Marianas Islands">
                            Northern Marianas Islands
                          </option>
                          <option value="Ohio">Ohio</option>
                          <option value="Oklahoma">Oklahoma</option>
                          <option value="Oregon">Oregon</option>
                          <option value="Pennsylvania">Pennsylvania</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Rhode Island">Rhode Island</option>
                          <option value="South Carolina">South Carolina</option>
                          <option value="South Dakota">South Dakota</option>
                          <option value="Tennessee">Tennessee</option>
                          <option value="Texas">Texas</option>
                          <option value="Utah">Utah</option>
                          <option value="Vermont">Vermont</option>
                          <option value="Virginia">Virginia</option>
                          <option value="Virgin Islands">Virgin Islands</option>
                          <option value="Washington">Washington</option>
                          <option value="West Virginia">West Virginia</option>
                          <option value="Wisconsin">Wisconsin</option>
                          <option value="Wyoming">Wyoming</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm>
                      <Form.Group controlId="zip">
                        <Form.Label>*Postal Code:</Form.Label>
                        <FormControl
                          type="text"
                          value={this.state.zip}
                          placeholder="Enter the postal code"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      {this.isEmptyOrSpaces(this.state.attachmentURL) && (
                        <Form.Group>
                          <Form.Label>Primary Photo:</Form.Label>
                        </Form.Group>
                      )}
                      <Form.Group controlId="file">
                        {!this.isEmptyOrSpaces(this.state.attachmentURL) && (
                          <Form.Group>
                            <Form.Label>Primary Photo: </Form.Label>
                            <br />
                            <img
                              src={this.getImageUrl(this.state.attachment)}
                              alt={this.state.farmName}
                            />
                          </Form.Group>
                        )}
                        <FormControl
                          onChange={this.handleFileChange}
                          type="file"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm>
                      <LoaderButton
                        block
                        bsstyle="primary"
                        bssize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save Changes"
                        loadingText="Saving…"
                      />
                    </Col>
                  </Row>
                </Form>
              </Tab>

              {this.state.isEdit && (
                <Tab eventKey="agents" title="Manage Agents">
                  <Row>
                    <Col xs={12} md={12}>
                      <br />
                    </Col>
                    <Col xs={12} md={12}>
                      <h5>Agents:</h5>
                    </Col>
                    <Connect
                      query={graphqlOperation(ListFgUsers, {
                        filter: {
                          orgId: {
                            eq: this.state.orgId,
                          },
                        },
                      })}
                      subscription={graphqlOperation(OnUpdateFgUser, {
                        filter: {
                          orgId: {
                            eq: this.state.orgId,
                          },
                        },
                      })}
                      onSubscriptionMsg={this.onUpdatedFgUser}
                    >
                      {({ data, loading, errors }) => {
                        if (loading) {
                          return <div>Loading...</div>;
                        }
                        if (!data.listFgUsers) return;
                        let users = data.listFgUsers.items;
                        return this.renderUsersCols(users);
                      }}
                    </Connect>
                  </Row>
                </Tab>
              )}
            </Tabs>
          )}
        </Container>
      </div>
    );
  }
}
