import React, { Component } from "react";
import "./FarmRentOfferConfirm.css";

export default class FarmRentOfferConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null
    };
  }
  render() {
    return (
      <div className="FarmRentOfferConfirm">
        <br />
        <h2>Rent Offer Confirmation</h2>
        <br />
        <h4>Your offer has been received!</h4>
        <br />
        <strong>
          Thank you for making an offer on this farm. Once the listing reaches
          it's end date, your offer will be reviewd and considered by the land
          owner. When a decesion is made by the land owner, all individauls who
          made an offer will be notified of the choice. If you would like to
          make a different offer, please resubmit the offer form and your most
          recent offer will be considered.
        </strong>
        <br />
        <br />
        <strong>- FarmGround.com Staff</strong>
        <br />
        <br />
      </div>
    );
  }
}
