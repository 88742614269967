import React from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { listFgOrgs as ListFgOrgs } from "../graphql/queries";
import { getFgUser as GetFgUser } from "../graphql/queries";
import { getFgOrg as GetFgOrg } from "../graphql/queries";
import { updateFgUser as UpdateFgUser } from "../graphql/mutations";
import { createFgUser as CreateFgUser } from "../graphql/mutations";
import { Form, FormControl, Container, Row, Col } from "react-bootstrap";
import { s3UploadWithId } from "../libs/awsLib";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./UserEdit.css";

export default class UserEdit extends React.Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isEdit: true,
      usr: null,
      attachmentURL: null,
      id: "",
      attachment: "",
      userType: "",
      email: "",
      phone: "",
      givenName: "",
      familyName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      orgId: "",
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const graphQLOrgResponse = await API.graphql(
        graphqlOperation(ListFgOrgs, {})
      );

      // Sort by Create Date DESC
      graphQLOrgResponse.data.listFgOrgs.items.sort(function (a, b) {
        return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
      });

      const orgs = graphQLOrgResponse.data.listFgOrgs.items;

      this.setState({ orgs: orgs });

      const graphQLResponse = await API.graphql(
        graphqlOperation(GetFgUser, { id: this.props.userId })
      );

      if (graphQLResponse.data.getFgUser === null) {
        this.setState({ isEdit: false });
      }

      if (this.state.isEdit) {
        const usr = graphQLResponse.data.getFgUser;

        const {
          id,
          attachment,
          userType,
          email,
          phone,
          givenName,
          familyName,
          address1,
          address2,
          city,
          state,
          zip,
          orgId,
        } = usr;

        let attachmentURL;
        if (attachment) {
          attachmentURL = await Storage.vault.get(attachment);
        }

        this.setState({
          usr,
          id,
          attachment,
          userType,
          email,
          phone,
          givenName,
          familyName,
          address1,
          address2,
          city,
          state,
          zip,
          orgId,
          attachmentURL,
        });
      } else {
        this.setState({
          userType: "Individual",
        });
      }

      this.setState({ isLoading: false });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  }

  validateForm() {
    return (
      this.state.givenName &&
      this.state.givenName.length > 0 &&
      this.state.familyName &&
      this.state.familyName.length > 0 &&
      this.state.email &&
      this.state.email.length > 0 &&
      this.state.phone &&
      this.state.phone.length > 0 &&
      this.state.address1 &&
      this.state.address1.length > 0 &&
      this.state.city &&
      this.state.city.length > 0 &&
      this.state.state &&
      this.state.state.length > 0 &&
      this.state.zip &&
      this.state.zip.length > 0 &&
      ((this.state.userType === "Agent" &&
        this.state.orgId &&
        this.state.orgId.length > 0) ||
        this.state.userType === "Individual")
    );
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  handleChange = (user) => {
    this.setState({
      [user.target.id]: user.target.value,
    });
  };

  handleAgentCheckbox = (chckbx) => {
    const isAgent = chckbx.target.checked ? true : false;

    if (isAgent) {
      this.setState({
        userType: "Agent",
      });
    } else {
      this.setState({
        userType: "Individual",
      });
    }
  };

  handleFileChange = (user) => {
    this.file = user.target.files[0];
  };

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  handleSubmit = async (farm) => {
    let attachment;

    farm.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3UploadWithId(this.props.userId, this.file);
      }

      let orgKeyVar = null;
      let orgAdminVar = "false";
      let orgApprovedVar = "false";

      if (!this.isEmptyOrSpaces(this.state.orgId)) {
        if (
          this.state.usr &&
          !this.isEmptyOrSpaces(this.state.usr.orgId) &&
          this.state.orgId === this.state.usr.orgId
        ) {
          //No Change
          orgAdminVar = this.state.usr.orgAdmin;
          orgApprovedVar = this.state.usr.orgApproved;
          orgKeyVar = this.state.usr.orgKey;
        } else {
          if (this.state.orgId === this.props.userId) {
            //Creator of Org
            orgAdminVar = "true";
            orgApprovedVar = "true";
          }

          const graphQLOrgResponse = await API.graphql(
            graphqlOperation(GetFgOrg, { id: this.state.orgId })
          );

          if (
            graphQLOrgResponse &&
            graphQLOrgResponse.data &&
            graphQLOrgResponse.data.getFgOrg
          ) {
            const org = graphQLOrgResponse.data.getFgOrg;

            const { key } = org;

            orgKeyVar = key;
          }
        }
      }

      if (this.state.isEdit) {
        await API.graphql(
          graphqlOperation(UpdateFgUser, {
            input: {
              id: this.props.userId,
              key: (
                this.state.givenName.replace(/\s+/g, "-").replace(/\//g, "-") +
                "-" +
                this.state.familyName.replace(/\s+/g, "-").replace(/\//g, "-") +
                "-" +
                this.props.userId.replace(/[^0-9]/g, "")
              ).replace(/,/g, ""),
              userType: this.isEmptyOrSpaces(this.state.userType)
                ? null
                : this.state.userType,
              email: this.isEmptyOrSpaces(this.state.email)
                ? null
                : this.state.email,
              phone: this.isEmptyOrSpaces(this.state.phone)
                ? null
                : this.state.phone,
              givenName: this.isEmptyOrSpaces(this.state.givenName)
                ? null
                : this.state.givenName,
              familyName: this.isEmptyOrSpaces(this.state.familyName)
                ? null
                : this.state.familyName,
              address1: this.isEmptyOrSpaces(this.state.address1)
                ? null
                : this.state.address1,
              address2: this.isEmptyOrSpaces(this.state.address2)
                ? null
                : this.state.address2,
              city: this.isEmptyOrSpaces(this.state.city)
                ? null
                : this.state.city,
              state: this.isEmptyOrSpaces(this.state.state)
                ? null
                : this.state.state,
              zip: this.isEmptyOrSpaces(this.state.zip) ? null : this.state.zip,
              orgId: this.isEmptyOrSpaces(this.state.orgId)
                ? null
                : this.state.orgId,
              orgKey: orgKeyVar,
              attachment: this.isEmptyOrSpaces(attachment)
                ? this.isEmptyOrSpaces(this.state.attachment)
                  ? null
                  : this.state.attachment
                : attachment,
              orgAdmin: orgAdminVar,
              orgApproved: orgApprovedVar,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(CreateFgUser, {
            input: {
              id: this.props.userId,
              key: (
                this.state.givenName.replace(/\s+/g, "-").replace(/\//g, "-") +
                "-" +
                this.state.familyName.replace(/\s+/g, "-").replace(/\//g, "-") +
                "-" +
                this.props.userId.replace(/[^0-9]/g, "")
              ).replace(/,/g, ""),
              userType: this.isEmptyOrSpaces(this.state.userType)
                ? null
                : this.state.userType,
              email: this.isEmptyOrSpaces(this.state.email)
                ? null
                : this.state.email,
              phone: this.isEmptyOrSpaces(this.state.phone)
                ? null
                : this.state.phone,
              givenName: this.isEmptyOrSpaces(this.state.givenName)
                ? null
                : this.state.givenName,
              familyName: this.isEmptyOrSpaces(this.state.familyName)
                ? null
                : this.state.familyName,
              address1: this.isEmptyOrSpaces(this.state.address1)
                ? null
                : this.state.address1,
              address2: this.isEmptyOrSpaces(this.state.address2)
                ? null
                : this.state.address2,
              city: this.isEmptyOrSpaces(this.state.city)
                ? null
                : this.state.city,
              state: this.isEmptyOrSpaces(this.state.state)
                ? null
                : this.state.state,
              zip: this.isEmptyOrSpaces(this.state.zip) ? "" : this.state.zip,
              orgId: this.isEmptyOrSpaces(this.state.orgId)
                ? null
                : this.state.orgId,
              orgKey: orgKeyVar,
              attachment: this.isEmptyOrSpaces(attachment)
                ? this.isEmptyOrSpaces(this.state.attachment)
                  ? null
                  : this.state.attachment
                : attachment,
              orgAdmin: orgAdminVar,
              orgApproved: orgApprovedVar,
            },
          })
        );
      }

      this.props.history.push("/dashboard");
    } catch (e) {
      alert(e);
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  renderAgencies(orgs) {
    return [{}].concat(orgs).map((org, i) =>
      i !== 0 ? (
        <option value={org.id} key={i}>
          {org.name + " (" + org.city + ", " + org.state + ")"}
        </option>
      ) : (
        ""
      )
    );
  }

  render() {
    return (
      <div className="UserEdit">
        <h3>My User Profile</h3>
        {!this.state.isLoading && (
          <Container>
            <br />
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm>
                  <Form.Group controlId="userType">
                    <Form.Label>*User Type:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Check if you are a licensed Real Estate Agent"
                      defaultChecked={this.state.userType === "Agent"}
                      onChange={this.handleAgentCheckbox}
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  {this.state.userType === "Agent" && (
                    <Form.Group controlId="orgId">
                      <Form.Label>*Agency:</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.orgId}
                        onChange={this.handleChange}
                      >
                        <option value="">Select your Agency...</option>
                        {this.renderAgencies(this.state.orgs)}
                      </Form.Control>
                      <a href="/agency/profile">Add Your Agency</a>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <Form.Group controlId="givenName">
                    <Form.Label>*First Name:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.givenName}
                      placeholder="Enter your first name"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId="familyName">
                    <Form.Label>*Last Name:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.familyName}
                      placeholder="Enter your last name"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <Form.Group controlId="email">
                    <Form.Label>*Email:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.email}
                      placeholder="Enter your email address"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col sm>
                  <Form.Group controlId="phone">
                    <Form.Label>*Phone:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.phone}
                      placeholder="Enter your main phone number"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <Form.Group controlId="address1">
                    <Form.Label>*Street Address 1:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.address1}
                      placeholder="Enter the street address"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col sm>
                  <Form.Group controlId="address2">
                    <Form.Label>Street Address 2:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.address2}
                      placeholder="Enter the street address"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <Form.Group controlId="city">
                    <Form.Label>*City:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.city}
                      placeholder="Enter city the farm is located"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId="state">
                    <Form.Label>*State:</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.state}
                      onChange={this.handleChange}
                    >
                      <option value="">Select US State</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <Form.Group controlId="zip">
                    <Form.Label>*Postal Code:</Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.zip}
                      placeholder="Enter the postal code"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm></Col>
              </Row>
              <Row>
                <Col sm>
                  {this.isEmptyOrSpaces(this.state.attachmentURL) && (
                    <Form.Group>
                      <Form.Label>Your Photo/Logo:</Form.Label>
                    </Form.Group>
                  )}
                  <Form.Group controlId="file">
                    {!this.isEmptyOrSpaces(this.state.attachmentURL) && (
                      <Form.Group>
                        <Form.Label>Your Photo/Logo: </Form.Label>
                        <br />
                        <img
                          src={this.getImageUrl(this.state.attachment)}
                          alt={this.state.farmName}
                        />
                      </Form.Group>
                    )}
                    <FormControl onChange={this.handleFileChange} type="file" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <LoaderButton
                    block
                    bsstyle="primary"
                    bssize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Save Changes"
                    loadingText="Saving…"
                  />
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </div>
    );
  }
}
