import React from "react";
import { Container, Row } from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getFgUser as GetFgUser } from "../graphql/queries";
import { fgFarmByUserId as FgFarmByUserId } from "../graphql_custom/queries";
import ListingCardCols from "../components/ListingCardCols";
import "./Dashboard.css";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      userId: null,
      farms: [],
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    var usrId = this.props.userId;

    if (!usrId || usrId.trim().length === 0) {
      usrId = sessionStorage.getItem("userId");
    }

    this.userUserId(usrId);

    if (usrId || usrId.trim().length > 0) {
      try {
        const userResponse = await API.graphql(
          graphqlOperation(GetFgUser, { id: usrId })
        );

        if (userResponse.data.getFgUser !== null) {
          const usr = userResponse.data.getFgUser;

          const {
            id,
            attachment,
            userType,
            email,
            phone,
            givenName,
            familyName,
            address1,
            address2,
            city,
            state,
            zip,
            orgId,
            orgAdmin,
            orgApproved,
          } = usr;

          let attachmentURL;
          if (attachment) {
            attachmentURL = await Storage.vault.get(attachment);
          }

          this.setState({
            id,
            attachment,
            userType,
            email,
            phone,
            givenName,
            familyName,
            address1,
            address2,
            city,
            state,
            zip,
            orgId,
            orgAdmin,
            orgApproved,
            attachmentURL,
          });
        } else {
          this.props.history.push("/user/profile");
        }

        const graphQLResponse = await API.graphql(
          graphqlOperation(FgFarmByUserId, {
            userId: usrId,
            limit: 50,
          })
        );

        const farms = graphQLResponse.data.fgFarmByUserId.items;
        this.setState({ farms: farms });

        this.setState({ isLoading: false });
      } catch (e) {
        console.error(e);
      }
    }
  }

  userUserId = (usrid) => {
    this.setState({ userId: usrid });
  };

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  setUserId(userId) {
    this.setState({ userId: userId });
  }

  render() {
    return (
      <div className="Dashboard">
        <h2 className="mb-3">
          {!this.state.isLoading && this.state.givenName + "'s"} Dashboard
        </h2>

        <div className="well well-sm sub-header">
          <a href={`/new/listing`} className="sub-header-nav-link">
            Create a New Listing
          </a>{" "}
          <a href={`/user/profile`} className="sub-header-nav-link">
            My Profile
          </a>{" "}
          {!this.isEmptyOrSpaces(this.state.orgId) &&
            !this.isEmptyOrSpaces(this.state.orgAdmin) &&
            this.state.orgAdmin === "true" && (
              <a href={`/agency/profile`} className="sub-header-nav-link">
                My Agency
              </a>
            )}
        </div>
        <hr />
        <h4 className="mb-3">My Listings</h4>

        <Container>
          <Row className="show-grid">
            {!this.state.isLoading ? (
              this.state.farms.length > 0 ? (
                <ListingCardCols
                  farms={this.state.farms}
                  edit={true}
                ></ListingCardCols>
              ) : (
                <span className="bold">
                  <br />
                  You have no listings! Start creating yours first listing now
                  by clicking the Create a New Listing button above! It is quick
                  and easy!
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              )
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
