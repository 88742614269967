import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { listFgFarms as ListFgFarms } from "../graphql/queries";
import { fgFarmRentOfferByFarmKey as FgFarmRentOfferByFarmKey } from "../graphql_custom/queries";
import { createFgFarmRentOffer as CreateFgFarmRentOffer } from "../graphql/mutations";
import { onCreateFgFarmRentOffer as OnCreateFgFarmRentOffer } from "../graphql/subscriptions";
import { Form, FormControl, Container, Card, Row, Col } from "react-bootstrap";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateTimeLocal from "../components/DateTimeLocal.js";
import Countdown from "../components/Countdown.js";
import CurrencyInput from "react-currency-input";
import LoaderButton from "../components/LoaderButton";
import uuid from "uuid/v4";
import "./FarmRentOffer.css";

const FARM_ID = uuid();

export default class FarmRentOffer extends React.Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: true,
      editorState: EditorState.createEmpty(),
      farm: null,
      attachment: null,
      attachmentURL: null,
      id: "",
      farmKey: "",
      farmName: "",
      farmDetails: "",
      farmType: "",
      farmTotalAcres: "",
      farmCounty: "",
      farmState: "",
      farmStatusNumber: 0,
      farmSaleType: "",
      farmListingNumber: "",
      farmTotalPrice: "",
      farmPricePerAcre: "",
      farmForRentDetails: "",
      farmStartDateTime: "",
      farmEndDateTime: "",
      farmAgentName: "",
      farmAgentLicensedIn: "",
      farmAgentWorkPhone: "",
      farmAgentCellPhone: "",
      farmAgentEmail: "",
      farmAgentAddress: "",
      offerAmount: "",
      offerComments:
        "<p><strong>Farming Experience:</strong></p><p>text goes here or remove heading</p><p><strong>Plans for Farm if Awarded:</strong></p><p>text goes here or remove heading</p><p><strong>Other Comments:</strong></p><p>text goes here or remove heading</p>",
      offerInitials: "",
      orgId: "",
      userId: "",
    };
  }

  async componentDidMount() {
    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(ListFgFarms, {
          filter: {
            farmKey: {
              eq: this.props.match.params.id,
            },
          },
        })
      );
      const farms = graphQLResponse.data.listFgFarms.items;

      var farmItem = null;
      if (Array.isArray(farms)) {
        farmItem = farms[0];
      } else {
        farmItem = farms;
      }

      const {
        id,
        farmKey,
        farmName,
        farmDetails,
        farmType,
        farmTotalAcres,
        farmCounty,
        farmState,
        farmStatusNumber,
        farmSaleType,
        farmPhotos,
        farmDocs,
        farmListingNumber,
        farmViewed,
        farmTotalPrice,
        farmPricePerAcre,
        farmForSaleDetails,
        farmForRentDetails,
        farmStartDateTime,
        farmEndDateTime,
        farmAuctionDetails,
        farmAgentName,
        farmAgentLicensedIn,
        farmAgentWorkPhone,
        farmAgentCellPhone,
        farmAgentEmail,
        farmAgentAddress,
        attachment,
        orgId,
        userId,
      } = farmItem;

      var usaTime = new Date(farmEndDateTime).toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });
      usaTime = new Date(usaTime);

      const farmEndDateTimeLocal = usaTime.toLocaleString();

      this.setState({
        id,
        farmKey,
        farmName,
        farmDetails,
        farmType,
        farmTotalAcres,
        farmCounty,
        farmState,
        farmStatusNumber,
        farmSaleType,
        farmPhotos,
        farmDocs,
        farmListingNumber,
        farmViewed,
        farmTotalPrice,
        farmPricePerAcre,
        farmForSaleDetails,
        farmForRentDetails,
        farmStartDateTime,
        farmEndDateTime,
        farmEndDateTimeLocal,
        farmAuctionDetails,
        farmAgentName,
        farmAgentLicensedIn,
        farmAgentWorkPhone,
        farmAgentCellPhone,
        farmAgentEmail,
        farmAgentAddress,
        orgId,
        userId,
        attachment,
      });

      //Editor
      const blocksFromHtml = htmlToDraft(this.state.offerComments);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });

      this.setState({ isLoading: false });
    } catch (e) {
      alert(e);
    }
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = (farm) => {
    this.setState({
      [farm.target.id]: farm.target.value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleOfferAmountChange = (event) => {
    this.setState({
      offerAmount: event.target.value,
    });
  };

  handleFileChange = (farm) => {
    this.file = farm.target.files[0];
  };

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://s3.amazonaws.com/fg-file-storage/public/" + attachment;
    return picUrl;
  }

  validateForm() {
    return (
      Date.parse(new Date(this.state.farmEndDateTime)) >
        Date.parse(new Date()) &&
      this.state.offerAmount &&
      !isNaN(this.state.offerAmount) &&
      this.state.offerComments &&
      this.state.offerComments.length > 1 &&
      this.state.offerInitials &&
      this.state.offerInitials.length > 1 &&
      parseInt(this.state.offerAmount) >= 1
    );
  }

  handleSubmit = async (farm) => {
    farm.preventDefault();

    this.setState({ isLoading: true });

    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    const markup = draftToHtml(rawContentState);

    try {
      await API.graphql(
        graphqlOperation(CreateFgFarmRentOffer, {
          input: {
            id: FARM_ID,
            offerAmount: parseInt(this.state.offerAmount).toFixed(2),
            offerComments: markup,
            offerInitials: this.state.offerInitials,
            offerorId: this.props.userId.replace(/[^0-9]/g, ""),
            farmId: this.state.id,
            farmKey: this.state.farmKey,
            userId: this.props.userId,
          },
        })
      );

      this.props.history.push(
        "/farm/rent/offer/confirm/" + +this.props.match.params.id
      );
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  renderRentOffers(offers) {
    return [{}].concat(offers).map((offer, i) =>
      i !== 0 ? (
        <Row key={i}>
          <Col xs={4} md={4}>
            <DateTimeLocal date={offer.createdAt} />
          </Col>
          <Col xs={4} md={4}>
            ${offer.offerAmount}
          </Col>
          <Col xs={4} md={4}>
            {offer.offerorId.replace(/[^1-7]/g, "").replace(/[3-5]/g, "*")}
          </Col>
        </Row>
      ) : (
        ""
      )
    );
  }

  onCreatedFgFarmRentOffer = (prevQuery, newData) => {
    let data = {
      listFgFarmRentOffers: {
        items: [],
      },
    };
    if (
      prevQuery &&
      prevQuery.listFgFarmRentOffers &&
      prevQuery.listFgFarmRentOffers.items
    ) {
      let newQuery = prevQuery.listFgFarmRentOffers.items.concat(
        newData.onCreateFgFarmRentOffer
      );
      data.listFgFarmRentOffers.items = newQuery;
      return data;
    }
  };

  renderOffer() {
    return (
      <Container>
        <h3>Make A Rent Offer</h3>
        <Row className="show-grid">
          <Col xs={12} md={12} className="card-col">
            <Card>
              <Card.Header as="h6">
                {this.isEmptyOrSpaces(this.state.farmSaleType)
                  ? ""
                  : this.state.farmSaleType.trim() + ": "}
                {this.isEmptyOrSpaces(this.state.farmName)
                  ? "No Content"
                  : this.state.farmName.trim()}
              </Card.Header>
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(this.state.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                    src={this.getImageUrl(this.state.attachment)}
                    alt={
                      this.isEmptyOrSpaces(this.state.farmName)
                        ? "Farm Land"
                        : this.state.farmName.trim()
                    }
                    responsive="true"
                  />
                ) : (
                  <Card.Img variant="top" src="./images/carousel.png" alt="" />
                )}
              </span>
              <Card.Body>
                <Container>
                  <Row className="show-grid">
                    <Col xs={12} md={12} className="card-col">
                      <h5>
                        {this.isEmptyOrSpaces(this.state.farmSaleType)
                          ? ""
                          : this.state.farmSaleType.trim() + ": "}
                        {this.isEmptyOrSpaces(this.state.farmName)
                          ? "No Content"
                          : this.state.farmName.trim()}
                      </h5>
                    </Col>
                  </Row>
                  <Row className="show-grid">
                    <Col xs={12} md={7} className="card-col">
                      <Row className="row">
                        <Col xs={12} md={4}>
                          <strong>County:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          {this.state.farmCounty + " County"}
                        </Col>
                        <Col xs={12} md={4}>
                          <strong>State:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          {this.state.farmState}
                        </Col>
                        <Col xs={12} md={4}>
                          <strong>Total Acres:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          {this.isEmptyOrSpaces(this.state.farmTotalAcres)
                            ? "No Content"
                            : this.state.farmTotalAcres.trim()}
                        </Col>
                        <Col xs={12} md={4}>
                          <strong>Listing Number:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          {this.state.farmListingNumber}
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col xs={12} md={12}>
                          <hr />
                        </Col>
                        <Col xs={12} md={4}>
                          <strong>End Date/Time:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          {this.state.farmEndDateTimeLocal}
                        </Col>
                        <Col xs={12} md={4}>
                          <strong>Time Left:</strong>
                        </Col>
                        <Col xs={12} md={8}>
                          <Countdown date={this.state.farmEndDateTime} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <hr />
                        </Col>
                        <Col xs={12} md={12}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.farmForRentDetails,
                            }}
                          />
                        </Col>
                        <Col xs={12} md={12}>
                          <hr />
                        </Col>
                        <Col xs={12} md={12}>
                          <Row>
                            <Col xs={12} md={12}>
                              <strong>Offer History</strong>
                            </Col>
                            <Col xs={12} md={12}>
                              <br />
                            </Col>
                            <Col xs={4} md={4}>
                              <strong>Date/Time</strong>
                            </Col>
                            <Col xs={4} md={4}>
                              <strong>Offer Amount / Acre</strong>
                            </Col>
                            <Col xs={4} md={4}>
                              <strong>Offeror Id</strong>
                            </Col>
                          </Row>
                          <Connect
                            query={graphqlOperation(FgFarmRentOfferByFarmKey, {
                              farmKey: this.props.match.params.id,
                              sortDirection: "DESC",
                            })}
                            subscription={graphqlOperation(
                              OnCreateFgFarmRentOffer,
                              {
                                filter: {
                                  farmKey: {
                                    eq: this.props.match.params.id,
                                  },
                                },
                              }
                            )}
                            onSubscriptionMsg={this.onCreatedFgFarmRentOffer}
                          >
                            {({ data, loading, errors }) => {
                              if (loading) {
                                return <div>Loading...</div>;
                              }
                              if (!data.fgFarmRentOfferByFarmKey) return;
                              return this.renderRentOffers(
                                data.fgFarmRentOfferByFarmKey.items
                              );
                            }}
                          </Connect>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={5} className="card-col offer-col">
                      {Date.parse(new Date(this.state.farmEndDateTime)) >
                      Date.parse(new Date()) ? (
                        <Form onSubmit={this.handleSubmit}>
                          <Row>
                            <Col>
                              <br />
                              <h5>Rent Offer Form</h5>
                              <hr />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm>
                              <Form.Group controlId="offerAmount">
                                <Form.Label>Offer Amount Per Acre:</Form.Label>
                                <CurrencyInput
                                  value={this.state.offerAmount}
                                  className="form-control"
                                  onChangeEvent={this.handleOfferAmountChange}
                                  aria-label="Offer Amount Per Acre (to the nearest dollar)"
                                />
                                <Form.Text className="text-muted">
                                  Amount offered is per acre (350.00).
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group controlId="offerComments">
                                <Form.Label>*Offer Comments:</Form.Label>
                                <Form.Text className="text-muted">
                                  Below is a sample of some items you might want
                                  to include. Please update, add and delete as
                                  you feel fit! This information will be used by
                                  the land owner when making their final
                                  selection.
                                </Form.Text>
                                <Editor
                                  editorState={this.state.editorState}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={this.onEditorStateChange}
                                  toolbar={{
                                    options: [
                                      "inline",
                                      "fontSize",
                                      "list",
                                      "link",
                                    ],
                                    fontSize: {
                                      options: [10, 11, 12, 14],
                                    },
                                    fontFamily: {
                                      options: ["Arial", "Verdana"],
                                    },
                                    list: {
                                      inDropdown: true,
                                      dropdownClassName: undefined,
                                      options: ["unordered", "ordered"],
                                    },
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <strong>Terms:</strong>
                            </Col>
                            <Col xs={12} md={12}>
                              <small className="text-muted form-text">
                                Immediately upon conclusion of the offering the
                                selected leasee will enter into a real estate
                                contract and deposit with the leaser of this
                                land or other approved holding account the
                                proper non-refundable earnest deposit. The lease
                                is not contingent upon buyer financing, all
                                offers are cash offers. This lease is subject to
                                all easements, covenants, leases, and
                                restrictions of record. Leaser must do their due
                                diligence prior to making an offer. All property
                                is leased on an "As is – Where is" basis with no
                                warranties or guarantees, expressed or implied,
                                made by the Agency or Leaser unless specifically
                                stated. You agree by offering that you will
                                enroll any CRP acres in your name upon gaining
                                ownership or abide by continuing requires of any
                                government programs or conservation programs on
                                the farm or pay any necessary penalties should
                                you choose not to. The leaser reserves the right
                                to accept or reject any and all bids. The
                                successful leasee(s) acknowledges that they are
                                representing themselves or have hired
                                representation using their own financial means
                                to complete the auction sales transaction. Any
                                updated made will take precedence over any
                                previous material or oral statements. Offering
                                remotely does not guarantee anonymity. All
                                decisions of the Leaser are final. The offering
                                is open to the public contingent upon proper
                                behavior.
                                <br />
                                <br />
                              </small>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm>
                              <Form.Group controlId="offerInitials">
                                <Form.Label>Terms Agreement:</Form.Label>
                                <FormControl
                                  as="select"
                                  value={this.state.offerInitials}
                                  placeholder="Do you agree to the terms above?"
                                  onChange={this.handleChange}
                                >
                                  <option value="">
                                    Do you agree to the terms above?
                                  </option>
                                  <option value="Yes, I Agree">
                                    Yes, I Agree
                                  </option>
                                  <option value="">No</option>
                                </FormControl>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm>
                              <LoaderButton
                                block
                                bsstyle="primary"
                                bssize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Submit Offer"
                                loadingText="Saving…"
                              />
                            </Col>
                          </Row>
                        </Form>
                      ) : (
                        "Sorry, We are no longer taking offers on this farm!"
                      )}
                    </Col>
                  </Row>
                </Container>
                {this.state.farmAgentName && (
                  <Row className="row">
                    <Col xs={12} md={12}>
                      <hr />
                    </Col>
                    <Col xs={12} md={12}>
                      <strong>Agent Information</strong>
                    </Col>
                    <Col xs={12} md={12}>
                      <br />
                    </Col>
                    <Col xs={12} md={2}>
                      <strong>Name:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                      {this.state.farmAgentName}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>State(s) Licensed:</strong>
                    </Col>
                    <Col xs={12} md={4}>
                      {this.state.farmAgentLicensedIn}
                    </Col>
                    <Col xs={12} md={2}>
                      <strong>Work Phone:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                      {this.state.farmAgentWorkPhone}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>Cell Phone:</strong>
                    </Col>
                    <Col xs={12} md={4}>
                      {this.state.farmAgentCellPhone}
                    </Col>
                    <Col xs={12} md={2}>
                      <strong>Email:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                      {this.state.farmAgentEmail}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>Address:</strong>
                    </Col>
                    <Col xs={12} md={4}>
                      {this.state.farmAgentAddress}
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    return (
      <div className="FarmRentOffer">
        {!this.state.isLoading && this.renderOffer()}
      </div>
    );
  }
}
