import React from "react";

class DateTimeLocal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: ""
    };
  }

  componentDidMount() {
    const date = this.calculateDate(this.props.date);
    date ? this.setState(date) : this.stop();
  }

  calculateDate(endDate) {
    const newDate = {
      date: ""
    };

    var usaTime = new Date(this.props.date).toLocaleString("en-US", {
      timeZone: "America/Chicago"
    });
    usaTime = new Date(usaTime);
    const date = usaTime.toLocaleString();

    newDate.date = date;

    return newDate;
  }

  render() {
    return <span>{this.state.date}</span>;
  }
}

export default DateTimeLocal;
