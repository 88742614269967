import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "./Home.css";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="Home">
        <div className="padd-top-25">
          <Col xs={12} md={12} className="card-col" key={4}>
            <h1>Welcome to FarmGround.com</h1>
            <hr />

            <div className="elementor-row">
              <div className="elementor-text-editor elementor-clearfix">
                <h1>FarmGround.com Privacy Policy</h1>
                <p>
                  <em>Last updated: April 5, 2019</em>
                </p>
              </div>
            </div>

            <div className="elementor-text-editor elementor-clearfix">
              <h2>
                <span styleClass="color: inherit; font-family: inherit; font-size: 2rem;">
                  1. Introduction
                </span>
              </h2>
              <p>
                <em>
                  This Privacy Policy describes how FarmGround.com collects and
                  uses Personal Data about you through the use of our Websites,
                  and through email, text, and other electronic communications
                  between you and FarmGround.com.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  FarmGround.com, Inc., and our affiliates and subsidiaries (
                </span>
                <b>“Tillable”</b>
                <span styleClass="font-weight: 400;"> or</span>
                <b>&nbsp;“We”</b>
                <span styleClass="font-weight: 400;">
                  ) respect your privacy and are committed to protecting it
                  through our compliance with this policy.
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  This Privacy Policy (our “
                </span>
                <b>Privacy Policy</b>
                <span styleClass="font-weight: 400;">
                  ”) describes the types of information we may collect from you
                  or that you may provide when you visit the websites{" "}
                </span>
                <a href="about:blank">
                  <span styleClass="font-weight: 400;">FarmGround.com</span>
                </a>
                <span styleClass="font-weight: 400;">
                  , and any subdomain of such websites (each, a “
                </span>
                <b>Website</b>
                <span styleClass="font-weight: 400;">
                  ” and collectively, the “
                </span>
                <b>Websites</b>
                <span styleClass="font-weight: 400;">
                  ”) and our practices for collecting, using, maintaining,
                  protecting, and disclosing that information.
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  This policy applies to information we collect:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">on our Websites;</span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    in email, text, and other electronic messages between you
                    and our Websites;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    when you contact us by telephone or in person (for example
                    at a summit or other conference); and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    when you interact with our advertising and applications on
                    third-party websites and services, if those applications or
                    advertising include links to this policy.
                  </span>
                  <span styleClass="font-weight: 400;">
                    <br />
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  It does not apply to information collected by:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    us offline or through any other means, including on any
                    other website operated by a third-party; or&nbsp;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    any third party, including through any application or
                    content (including advertising) that may link to or be
                    accessible from or on the Website.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  Please read this policy carefully to understand our policies
                  and practices regarding your information and how we will treat
                  it. If you do not agree with our policies and practices, your
                  choice is not to use our Websites. By accessing or using these
                  Websites, you agree to this Privacy Policy. This Privacy
                  Policy may change from time to time. Your continued use of
                  these Websites after we make changes is deemed to be
                  acceptance of those changes, so please check this Privacy
                  Policy periodically for updates.
                </span>
              </p>
              <h2>2. Children Under the Age of 18</h2>
              <p>
                <em>
                  Our Websites are not intended for children under the age of 18
                  and children under the age of 18 are not permitted to use our
                  Websites. We will remove any information about a child under
                  the age of 18 if we become aware of it.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  Our Websites are not intended for children under 18 years of
                  age. No one under age 18 may provide any information to or on
                  the Websites. We do not knowingly collect Personal Data from
                  children under 18. If you are under 18, do not use or provide
                  any information on our Websites or on or through any of its
                  features/register on the Websites, make any purchases through
                  the Websites, use any of the interactive or public comment
                  features of our Websites or provide any information about
                  yourself to us, including your name, address, telephone
                  number, email address, or any screen name or user name you may
                  use. If we learn we have collected or received Personal Data
                  from a child under 18 without verification of parental
                  consent, we will delete that information. If you believe we
                  might have any information from a child under 18, please
                  contact us at{" "}
                </span>
                <a href="mailto:contact@farmground.com">
                  <span styleClass="font-weight: 400;">
                    contact@farmground.com
                  </span>
                </a>
                <span styleClass="font-weight: 400;">.</span>
              </p>
              <h2>3. Information We Collect About You and How We Collect It</h2>
              <p>
                <em>
                  We collect different types of information about you, including
                  information that may directly identify you, information that
                  is about you but individually does not personally identify
                  you, and information that we combine with our other users.
                  This includes information that we collect directly from you or
                  through automated collection technologies.
                </em>
              </p>
              <p>
                <b>Generally</b>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We collect several types of information from and about users
                  of our Websites:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    by which you may be personally identified, such as name,
                    postal address, e-mail address, telephone number, electronic
                    information number, real property purchased, leased or
                    rented, or any other identifier by which you may be
                    contacted online or offline (“
                  </span>
                  <b>Personal Data</b>
                  <span styleClass="font-weight: 400;">”);</span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    that is about you but individually does not identify you,
                    such as web site traffic data, location data, logs,
                    referring/exit pages, date and time of your visit to our
                    Websites, error information, clickstream data, and other
                    communication data and the resources that you access and use
                    on the Websites; and/or about your internet connection, the
                    equipment you use to access our Websites and usage details.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  We collect this information:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    directly from you when you provide it to us;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    automatically as you navigate through the Websites.
                    Information collected automatically may include usage
                    details, IP addresses, and information collected through
                    cookies, web beacons, and other tracking technologies; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    From third parties, for example, our other users and
                    affiliates who use our Websites and who may enter your
                    Personal Data onto our Websites.
                  </span>
                </li>
              </ul>
              <p>
                <b>
                  <br />
                  Information You Provide to Us
                </b>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  The information we collect on or through our Websites are:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    information that you provide by filling in forms on our
                    Websites. This includes information provided at the time of
                    registering to use our Websites, posting a listing for a
                    property or responding to a listing for a property, or
                    requesting further services. If you choose to send an
                    inquiry or commitment request through the Websites for a
                    property, your personal information, including your email
                    address and any other information you supply (unless the
                    Site specifies otherwise), will be visible to the property
                    owner or manager in question so that they might reply
                    directly to you. We may also ask you for information when
                    you report a problem with our Websites;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    records and copies of your correspondence (including email
                    addresses), if you contact us;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    your responses to surveys that we might ask you to complete
                    for research purposes and feedback about the services;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    details of the transactions you carry out through our
                    Websites and of the fulfillment of your transactions you
                    enter into with other users of our Website. You may be
                    required to provide financial information before engaging in
                    transactions on or through our Websites; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    your search queries on the Websites.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  The Personal Data we collect from you is required to enter
                  into a contract with FarmGround.com, for FarmGround.com to
                  perform under the contract, and to provide you with our
                  products and services. If you refuse to provide such Personal
                  Data or withdraw your consent to our processing of Personal
                  Data, then in some cases we may not be able to enter into the
                  contract or fulfill our obligations to you under it.&nbsp;
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  You also may provide information to be published or displayed
                  (hereinafter, “
                </span>
                <b>posted</b>
                <span styleClass="font-weight: 400;">
                  ”) on public areas of the Websites or transmitted to other
                  users of the Websites or third parties (collectively, “
                </span>
                <b>User Contributions</b>
                <span styleClass="font-weight: 400;">
                  ”). Your User Contributions are posted on and transmitted to
                  others at your own risk. Although we limit access to certain
                  pages and you may set certain privacy settings for such
                  information while creating or responding to listings
                  (including listing publicly and limiting the transmission of
                  posts to certain listings), please be aware that no security
                  measures are perfect or impenetrable. Additionally, we cannot
                  control the actions of other users of the Websites with whom
                  you may choose to share your User Contributions. Therefore, we
                  cannot and do not guarantee that your User Contributions will
                  not be viewed by unauthorized persons.
                </span>
              </p>
              <p>
                <b>
                  Information We Collect Through Automatic Data Collection
                  Technologies
                </b>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  As you navigate through and interact with our Websites, we may
                  use automatic data collection technologies to collect certain
                  information about your equipment, browsing actions, and
                  patterns as described above. We may also collect information
                  about your computer and internet connection, i.e. your IP
                  address, operating system, and browser type.&nbsp;
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  The information we collect automatically may include Personal
                  Data or we may maintain it or associate it with Personal Data
                  we collect in other ways or receive from third parties. It
                  helps us to improve our Websites and to deliver a better and
                  more personalized service by enabling us to:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    estimate our audience size and usage patterns;&nbsp;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    store information about your preferences, allowing us to
                    customize how our Websites appears to you according to your
                    individual interests;&nbsp;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    speed up your searches; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    recognize you when you return to our Websites.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  The technologies we use for this automatic data collection may
                  include:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <b>Cookies (or browser cookies)</b>
                  <span styleClass="font-weight: 400;">
                    . A cookie is a small file placed on the hard drive of your
                    computer or mobile device. On your computer, you may refuse
                    to accept browser cookies by activating the appropriate
                    setting on your browser, and you may have similar
                    capabilities on your mobile device in the preferences for
                    your operating system or browser. However, if you select
                    this setting you may be unable to access certain parts of
                    our Websites. Unless you have adjusted your browser or
                    operating system setting so that it will refuse cookies, our
                    system will issue cookies when you direct your browser to
                    our Websites.
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <b>Google Analytics</b>
                  <span styleClass="font-weight: 400;">.</span>{" "}
                  <span styleClass="font-weight: 400;">
                    We use Google Analytics, a web analytics service provided by
                    Google, Inc. (“
                  </span>
                  <b>Google</b>
                  <span styleClass="font-weight: 400;">
                    ”) to collect certain information relating to your use of
                    our Websites. Google Analytics uses cookies to help our
                    websites analyze how users use the site. You can find out
                    more about how Google uses data when you visit our Websites
                    by visiting “How Google uses data when you use our partners’
                    sites or apps”, (located at{" "}
                  </span>
                  <a href="http://www.google.com/policies/privacy/partners/">
                    <span styleClass="font-weight: 400;">
                      www.google.com/policies/privacy/partners/
                    </span>
                  </a>
                  <span styleClass="font-weight: 400;">).</span>
                </li>
                <li styleClass="font-weight: 400;">
                  <b>Web beacons.</b>
                  <span styleClass="font-weight: 400;">
                    {" "}
                    We gather and share information concerning the use of the
                    Site by property owners and managers and farmers with one or
                    more third-party tracking companies for the purpose of
                    reporting statistics. To do this, some of the pages you
                    visit on our Site use electronic images placed in the web
                    page code, called pixel tags (also called “clear GIFs” or
                    “web beacons”) that can serve many of the same purposes as
                    cookies. Web beacons may be used to track the traffic
                    patterns of users from one page to another in order to
                    maximize web traffic flow. Our third-party advertising
                    service providers may also use web beacons to recognize you
                    when you visit the Site and to help determine how you found
                    the Site.
                  </span>

                  <span styleClass="font-weight: 400;">.</span>
                </li>
              </ul>
              <p>
                <b>
                  <br />
                  Information From Third Parties
                </b>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We may obtain information about you from other sources,
                  including through third party services and organizations to
                  supplement information provided by you. This supplemental
                  information allows us to verify information provided to us and
                  to enhance our ability to provide you with the Services.
                </span>
              </p>
              <h2>4. How We Use Your Information</h2>
              <p>
                <em>
                  We use your Personal Data for various purposes described
                  below, including to:
                </em>
              </p>
              <ul>
                <li>
                  <em>provide our Websites to you;</em>
                </li>
                <li>
                  <em>
                    provide you with information you request from us;&nbsp;
                  </em>
                </li>
                <li>
                  <em>enforce our rights arising from contracts;</em>
                </li>
                <li>
                  <em>notify you about changes; and</em>
                </li>
                <li>
                  <em>provide you with notices about your account.</em>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  We use information that we collect about you or that you
                  provide to us, including any Personal Data:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to present our Websites and their contents to you;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to provide you with information or services that you request
                    from us;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to let our other users know about your listings and to
                    facilitate transactions between you and the other users of
                    our Websites;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to provide you with notices about your account, including
                    expiration and renewal notices;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to notify you about changes to our Websites or any services
                    we offer or provide though them;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to allow you to participate in interactive features on our
                    Websites;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to fulfill any other purpose for which you provide it;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to maintain, secure, or improve our Websites and services;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to administer surveys, sweepstakes, promotions and contests;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to provide you with newsletters or other promotional content
                    related to our services;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to respond to your questions or inquiries;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    in any other way we may describe when you provide the
                    information; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    for any other purpose with your consent.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  With your consent, we may also use your information to contact
                  you about our own services that may be of interest to you. If
                  you wish to opt-out to this use, you may do so at any time by
                  logging into the Websites and adjusting our user preferences
                  in your account profile by checking or unchecking the relevant
                  boxes.
                </span>
              </p>
              <h2>5. Disclosure of Your Information</h2>
              <p>
                <em>
                  We do not share, sell, or otherwise disclose your Personal
                  Data for purposes other than those outlined in this Privacy
                  Policy. We disclose your Personal Data to a few third parties,
                  including:
                </em>
              </p>
              <ul>
                <li>
                  <em>with your consent to authorized third parties; </em>
                </li>
                <li>
                  <em>other users of our Websites;</em>
                </li>
                <li>
                  <em>our subsidiaries and our affiliates</em>
                </li>
                <li>
                  <em>
                    our third-party service providers that we use to support our
                    business;
                  </em>
                </li>
                <li>
                  <em>
                    to a company we merge, acquire, or that buys us, or in the
                    event of change in structure of our company in any form;
                  </em>
                </li>
                <li>
                  <em>to comply with our legal obligations; and</em>
                </li>
                <li>
                  <em>to enforce our rights.</em>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  We do not share, sell, or otherwise disclose your Personal
                  Data for purposes other than those outlined in this Privacy
                  Policy. However, we may disclose aggregated information about
                  our users, and information that does not identify any
                  individual, without restriction.&nbsp;
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We may disclose Personal Data that we collect or you provide
                  as described in this privacy policy:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    where you have provided consent, we share your information,
                    including personal information, such as when you authorize a
                    third party application such as Facebook to access your
                    FarmGround.com account;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to other users of our Websites, for example, if you choose
                    to make that information available to them; for example, if
                    you choose to communicate with the other users of our
                    Websites, we may transmit the contents of that
                    communication, your contact information, and any other
                    information you provide to the recipients;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to our subsidiaries and affiliates;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to contractors, service providers, and other third parties
                    we use to support our business and who are bound by
                    contractual obligations to keep Personal Data confidential
                    and use it only for the purposes for which we disclose it to
                    them. We may share your Personal Data with a third party
                    payment processor to enable you to access and use certain
                    features of the services. Our payment processors’ privacy
                    policies may be found at{" "}
                  </span>
                  <a href="https://www.dwolla.com/legal/privacy/">
                    <span styleClass="font-weight: 400;">
                      https://www.dwolla.com/legal/privacy/
                    </span>
                  </a>
                  <span styleClass="font-weight: 400;">;</span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of FarmGround.com’s
                    assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which Personal Data
                    held by FarmGround.com about our Websites’ users is among
                    the assets transferred; and&nbsp;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    for any other purpose disclosed by us when you provide the
                    information.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  We may also disclose your Personal Data:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    to enforce or apply our Websites’ terms and conditions{" "}
                  </span>
                  <a href="https://farmground.com/terms-and-conditions/">
                    <span styleClass="font-weight: 400;">
                      https://farmground.com/terms-and-conditions/
                    </span>
                  </a>
                  <span styleClass="font-weight: 400;">
                    {" "}
                    and other agreements, including for billing and collection
                    purposes; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    if we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of FarmGround.com,
                    our customers, or others. This includes exchanging
                    information with other companies and organizations for the
                    purposes of fraud protection and credit risk reduction.
                  </span>
                </li>
              </ul>
              <h2>6. Choices About How We Use and Disclose Your Information</h2>
              <p>
                <em>
                  We offer you choices on how you can opt out of our use of
                  tracking technology and our advertising to you.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We strive to provide you with choices regarding the Personal
                  Data you provide to us. We have created mechanisms to provide
                  you with control over your Personal Data:&nbsp;
                </span>
              </p>
              <ul>
                <li>
                  <b>
                    Tracking Technologies and Advertising.
                    <span styleClass="font-weight: 400;">
                      {" "}
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when cookies are being sent. If
                      you disable or refuse cookies, please note that some parts
                      of this site may then be inaccessible or not function
                      properly.
                    </span>
                  </b>
                </li>
                <li>
                  <b>Promotional Offers from FarmGround.com.</b>
                  <span styleClass="font-weight: 400;">
                    {" "}
                    You may receive email from us for a variety of reasons; for
                    example, if you took an action through the Websites or you
                    posted a listing for your property and a user sent you a
                    message. If we have sent you a promotional email, you may
                    unsubscribe by clicking the unsubscribe link at the bottom
                    of the email. This opt out does not apply to information
                    provided to FarmGround.com for the processing of a service
                    or other transaction.
                  </span>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  We do not control third parties’ collection or use of your
                  information to serve interest-based advertising. However,
                  these third parties may provide you with ways to choose not to
                  have your information collected or used in this way. You can
                  also opt out of receiving targeted ads from members of the NAI
                  on its website.
                </span>
              </p>
              <h2>
                7. Your Rights Regarding Your Information and Accessing and
                Correcting Your Information
              </h2>
              <p>
                <em>
                  You may review and change your Personal Data at any time by
                  logging into the Website or the Application and visiting your
                  user profile page.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  You can review and change your Personal Data or close your
                  account by logging into the Websites and visiting your
                  “Account Settings” page. You may also notify us through the
                  Contact Information below of any changes or errors in any
                  Personal Data we have about you to ensure that it is complete,
                  accurate, and as current as possible or to close your account.
                  We cannot delete your Personal Data except by also deleting
                  your account. We may also not be able to accommodate your
                  request if we believe it would violate any law or legal
                  requirement or cause the information to be incorrect.&nbsp;
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  If you contact us to access or change your Personal Data or to
                  close your account, we may ask you for additional information
                  to verify your identity. We reserve the right to limit or deny
                  your request if you have failed to provide sufficient
                  information to verify your identity or to satisfy our legal
                  and business requirements.
                </span>
              </p>
              <h2 id="your-california-privacy-rights">
                8. Your California Privacy Rights
              </h2>
              <p>
                <em>
                  Under some circumstances, California law may provide you with
                  the right to obtain a list of third parties (if any) that we
                  provide your Personal Data to for their own direct marketing
                  purposes.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  California Civil Code Section 1798.83 (California’s “Shine the
                  Light” law) permits users of our Websites that are California
                  residents and who provide Personal Data in obtaining products
                  and services for personal, family, or household use to request
                  certain information regarding our disclosure of Personal Data
                  to third parties for their own direct marketing purposes. If
                  applicable, this information would include the categories of
                  Personal Data and the names and addresses of those businesses
                  with which we shared your Personal Data with for the
                  immediately prior calendar year (e.g. requests made in 2019
                  will receive information regarding such activities in 2018).
                  You may request this information once per calendar year.
                </span>
              </p>
              <h2>9. Do Not Track Signals</h2>
              <p>
                <em>
                  We may use automated data collection technologies to track you
                  across websites. We currently do not honor do-not-track
                  signals that may be sent by some browsers.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We also may use automated data collection technologies to
                  collect information about your online activities over time and
                  across third-party websites or other online services
                  (behavioral tracking). Some web browsers permit you to
                  broadcast a signal to websites and online services indicating
                  a preference that they “do not track” your online activities.
                  At this time, we do not honor such signals and we do not
                  modify what information we collect or how we use that
                  information based upon whether such a signal is broadcast or
                  received by us.
                </span>
              </p>
              <h2>10. Data Security</h2>
              <p>
                <em>
                  Information transmitted over the Internet is not completely
                  secure, but we do our best to protect your Personal Data. You
                  can help protect your Personal Data and other information by
                  keeping your password to our Websites confidential.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We have implemented measures designed to secure your Personal
                  Data from accidental loss and from unauthorized access, use,
                  alteration, and disclosure.&nbsp;
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  The safety and security of your information also depends on
                  you. Where we have given you (or where you have chosen) a
                  password for access to certain parts of our Websites, you are
                  responsible for keeping this password confidential. We ask you
                  not to share your password with anyone. We urge you to be
                  careful about giving out information in public areas of the
                  Websites like message boards. The information you share in
                  public areas may be viewed by any user of the Websites.
                </span>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. Although we do our best to
                  protect your Personal Data, we cannot guarantee the security
                  of your Personal Data transmitted to our Websites. Any
                  transmission of Personal Data is at your own risk. We are not
                  responsible for circumvention of any privacy settings or
                  security measures contained on the Websites.
                </span>
              </p>
              <h2>
                11. Consent to Processing of Personal Data in the United States
              </h2>
              <p>
                <em>
                  We may process your Personal Data outside of your home
                  country, including to the United States. We only do this when
                  we are legally permitted to do so and when we have appropriate
                  safeguards in place to protect your Personal Data.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We do not market our goods or services to residents of Europe,
                  however, if you are a resident of the European Economic Area
                  (“
                </span>
                <b>EEA</b>
                <span styleClass="font-weight: 400;">
                  ”), in order to provide our Websites, products, and services
                  to you, we may send and store your Personal Data outside of
                  the EEA, including to the United States. Accordingly, your
                  Personal Data may be transferred outside the country where you
                  reside or are located, including to countries that may not or
                  do not provide an equivalent level of protection for your
                  Personal Data. By using our Websites, you represent that you
                  have read and understood the above and hereby consent to the
                  storage and processing of Personal Data on our Websites.
                </span>
              </p>
              <h2>12. Data Retention Periods</h2>
              <p>
                <em>
                  We retain your Personal Data for as long as you keep your
                  account open. In some instances, we may keep it after you
                  close your account, for example, we may keep it:
                </em>
              </p>
              <ul>
                <li>
                  <em>on our backup and disaster recovery systems;</em>
                </li>
                <li>
                  <em>
                    for as long as. necessary to protect our legal interests;
                    and
                  </em>
                </li>
                <li>
                  <em>to comply with other legal requirements.</em>
                </li>
              </ul>
              <p>
                <span styleClass="font-weight: 400;">
                  <br />
                  FarmGround.com will retain your Personal Data for the entire
                  time that you keep your account open. After you close your
                  account, we may retain your Personal Data:
                </span>
              </p>
              <ul>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    for as long as necessary to comply with any legal
                    requirement;&nbsp;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    on our backup and disaster recovery systems in accordance
                    with our backup and disaster recovery policies and
                    procedures;
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    for as long as necessary to protect our legal interests or
                    otherwise pursue our legal rights and remedies; and
                  </span>
                </li>
                <li styleClass="font-weight: 400;">
                  <span styleClass="font-weight: 400;">
                    for data that has been aggregated or otherwise rendered
                    anonymous in such a manner that you are no longer
                    identifiable, indefinitely.
                  </span>
                </li>
              </ul>
              <h2>13. Changes to Our Privacy Policy</h2>
              <p>
                <em>
                  We will post any changes to our Privacy Policy on our
                  Websites. If we make material changes to our Privacy Policy,
                  we may notify you of such changes through your contact
                  information and invite you to review the changes.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  We may from time to time modify this Privacy Policy. We will
                  promptly make any changes available at{" "}
                </span>
                <a href="https://www.farmground.com/privacy-policy">
                  <span styleClass="font-weight: 400;">
                    www.farmground.com/privacy-policy
                  </span>
                </a>
                <span styleClass="font-weight: 400;">
                  . Changes will be effective as soon as the revised Privacy
                  Policy is posted unless otherwise stated. In certain
                  circumstances, we will provide a more prominent notice before
                  the changes goes into effect (including, for certain of our
                  Services, email notifications of material changes to this
                  Privacy Policy). The date this Privacy Policy was last revised
                  is identified at the top of the page. You are responsible for
                  periodically visiting our Websites and this Privacy Policy to
                  check for any changes (and, where applicable, for ensuring we
                  have an up-to-date email address for you).
                </span>
              </p>
              <h2>14. Contact Information</h2>
              <p>
                <em>
                  You may contact us through the contact information&nbsp;below.
                </em>
              </p>
              <p>
                <span styleClass="font-weight: 400;">
                  If you have any questions, concerns, complaints or suggestions
                  regarding our Privacy Policy, have any requests related to
                  your Personal Data pursuant to applicable laws, or otherwise
                  need to contact us, you must contact us through the “Contact
                  Us” page on our Websites.&nbsp;
                </span>
              </p>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}
