/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFgUser = /* GraphQL */ `
  mutation CreateFgUser($input: CreateFgUserInput!) {
    createFgUser(input: $input) {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const updateFgUser = /* GraphQL */ `
  mutation UpdateFgUser($input: UpdateFgUserInput!) {
    updateFgUser(input: $input) {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const deleteFgUser = /* GraphQL */ `
  mutation DeleteFgUser($input: DeleteFgUserInput!) {
    deleteFgUser(input: $input) {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const createFgOrg = /* GraphQL */ `
  mutation CreateFgOrg($input: CreateFgOrgInput!) {
    createFgOrg(input: $input) {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const updateFgOrg = /* GraphQL */ `
  mutation UpdateFgOrg($input: UpdateFgOrgInput!) {
    updateFgOrg(input: $input) {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const deleteFgOrg = /* GraphQL */ `
  mutation DeleteFgOrg($input: DeleteFgOrgInput!) {
    deleteFgOrg(input: $input) {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const createFgFarm = /* GraphQL */ `
  mutation CreateFgFarm($input: CreateFgFarmInput!) {
    createFgFarm(input: $input) {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const updateFgFarm = /* GraphQL */ `
  mutation UpdateFgFarm($input: UpdateFgFarmInput!) {
    updateFgFarm(input: $input) {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const deleteFgFarm = /* GraphQL */ `
  mutation DeleteFgFarm($input: DeleteFgFarmInput!) {
    deleteFgFarm(input: $input) {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const createFgFarmAuctionBid = /* GraphQL */ `
  mutation CreateFgFarmAuctionBid($input: CreateFgFarmAuctionBidInput!) {
    createFgFarmAuctionBid(input: $input) {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const updateFgFarmAuctionBid = /* GraphQL */ `
  mutation UpdateFgFarmAuctionBid($input: UpdateFgFarmAuctionBidInput!) {
    updateFgFarmAuctionBid(input: $input) {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const deleteFgFarmAuctionBid = /* GraphQL */ `
  mutation DeleteFgFarmAuctionBid($input: DeleteFgFarmAuctionBidInput!) {
    deleteFgFarmAuctionBid(input: $input) {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const createFgFarmRentOffer = /* GraphQL */ `
  mutation CreateFgFarmRentOffer($input: CreateFgFarmRentOfferInput!) {
    createFgFarmRentOffer(input: $input) {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const updateFgFarmRentOffer = /* GraphQL */ `
  mutation UpdateFgFarmRentOffer($input: UpdateFgFarmRentOfferInput!) {
    updateFgFarmRentOffer(input: $input) {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const deleteFgFarmRentOffer = /* GraphQL */ `
  mutation DeleteFgFarmRentOffer($input: DeleteFgFarmRentOfferInput!) {
    deleteFgFarmRentOffer(input: $input) {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const createFgState = /* GraphQL */ `
  mutation CreateFgState($input: CreateFgStateInput!) {
    createFgState(input: $input) {
      id
      state
      counties
      displayFlag
    }
  }
`;
export const updateFgState = /* GraphQL */ `
  mutation UpdateFgState($input: UpdateFgStateInput!) {
    updateFgState(input: $input) {
      id
      state
      counties
      displayFlag
    }
  }
`;
export const deleteFgState = /* GraphQL */ `
  mutation DeleteFgState($input: DeleteFgStateInput!) {
    deleteFgState(input: $input) {
      id
      state
      counties
      displayFlag
    }
  }
`;
