import React from "react";
import { Container, Row } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { fgFarmByFarmSaleType as FgFarmByFarmSaleType } from "../graphql_custom/queries";
import ListingCardCols from "../components/ListingCardCols";
import "./ForRent.css";

export default class ForRent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(FgFarmByFarmSaleType, {
          farmSaleType: "For Rent",
          filter: {
            farmStatusNumber: {
              lt: 700,
            },
          },
          sortDirection: "DESC",
          limit: 36,
        })
      );

      const farms = graphQLResponse.data.fgFarmByFarmSaleType.items;

      this.setState({ farms });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  render() {
    return (
      <div className="ForRent">
        <h4 className="mb-3">For Rent Listings</h4>
        <div className="well well-sm">
          <a href={`/dashboard`}>Create your listing on FarmGround.com!</a>
        </div>
        <hr />
        <Container>
          <Row className="show-grid">
            {!this.state.isLoading ? (
              this.state.farms.length > 0 ? (
                <ListingCardCols
                  farms={this.state.farms}
                  edit={false}
                ></ListingCardCols>
              ) : (
                <span className="bold">
                  <i>
                    No farms listed yet! Be the first and list your farm now!
                  </i>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              )
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
