import React from "react";
import { Container, Row } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { listFgFarms as ListFgFarms } from "../graphql/queries";
import ListingCardCols from "../components/ListingCardCols";
import "./Dashboard.css";

export default class DashboardAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const graphQLResponse = await API.graphql(
        graphqlOperation(ListFgFarms, { limit: 100 })
      );

      // Sort by Create Date DESC
      graphQLResponse.data.listFgFarms.items.sort(function (a, b) {
        return a.createdAt < b.createdAt
          ? 1
          : b.createdAt < a.createdAt
          ? -1
          : 0;
      });

      const farms = graphQLResponse.data.listFgFarms.items;

      this.setState({ farms: farms });

      if (farms && farms.length > 0) {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://s3.amazonaws.com/fg-file-storage/public/" + attachment;
    return picUrl;
  }

  setUserId(userId) {
    this.setState({ userId: userId });
  }

  render() {
    return (
      <div className="Dashboard">
        <h2 className="mb-3">Admin Dashboard</h2>
        <hr />
        <h4 className="mb-3">All Farms</h4>
        <hr />
        <Container>
          <Row className="show-grid">
            {!this.state.isLoading ? (
              this.state.farms.length > 0 ? (
                <ListingCardCols
                  farms={this.state.farms}
                  edit={true}
                ></ListingCardCols>
              ) : (
                <span className="bold">
                  No farms listed yet! Create yours now! It is quick and easy!
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>
              )
            ) : (
              ""
            )}
          </Row>
        </Container>
        <br />
        <br />
      </div>
    );
  }
}
