import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Nav, Navbar, Row, Col, Image } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import config from "./config";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: null,
      isAuthenticating: null,
      isAdmin: null,
      userId: null,
      isLoading: null
    };
  }

  async componentDidMount() {
    this.setState({
      isAuthenticated: false,
      isAuthenticating: true,
      isAdmin: false,
      userId: "",
      isLoading: true
    });

    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
        // Set isAdmin if they are in Admin group
        await Auth.currentAuthenticatedUser()
          .then(res => {
            var sub = res.signInUserSession.idToken.payload["sub"];
            this.userUserId(sub);

            var adminGroups =
              res.signInUserSession.idToken.payload["cognito:groups"];
            if (
              adminGroups &&
              (adminGroups.includes(config.ORG_ADMIN_GROUP) ||
                adminGroups.includes("Admin"))
            ) {
              this.userIsAdmin(true);
            } else {
              this.userIsAdmin(false);
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isLoading: false, isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  userUserId = usrid => {
    this.setState({ userId: usrid });
    sessionStorage.setItem("userId", usrid);
  };

  userIsAdmin = admin => {
    this.setState({ isAdmin: admin });
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);
    this.userIsAdmin(false);
    this.userUserId("");

    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAdmin: this.state.isAdmin,
      userId: this.state.userId,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      !this.state.isLoading && (
        <div className="App">
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>
                <Link to="/">
                  <Image
                    src="/images/logo-only-white.png"
                    height="24px"
                    alt={config.ORG_NAME}
                  />
                </Link>{" "}
                <Link to="/">{config.ORG_NAME}</Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link eventKey={1} href="/for-sale">
                    For Sale
                  </Nav.Link>
                  <Nav.Link eventKey={2} href="/for-rent">
                    For Rent
                  </Nav.Link>
                  <Nav.Link eventKey={3} href="/auctions">
                    Online Auctions
                  </Nav.Link>
                  <Nav.Link eventKey={3} href="/agents">
                    Real Estate Agents
                  </Nav.Link>
                </Nav>
                {this.state.isAuthenticated ? (
                  <Nav>
                    <Nav.Link eventKey={7} href="/dashboard">
                      My Dashboard
                    </Nav.Link>
                    <Nav.Link eventKey={8} onClick={this.handleLogout}>
                      Logout
                    </Nav.Link>
                  </Nav>
                ) : (
                  <Nav>
                    <Nav.Link eventKey={9} href="/login">
                      Login
                    </Nav.Link>
                    <Nav.Link eventKey={10} href="/signUp">
                      Sign Up
                    </Nav.Link>
                  </Nav>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container>
            <Routes childProps={childProps} history={this.props.history} />
            <br />
            <br />
            <br />
          </Container>
          <footer className="footerbar bg-dark">
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <p></p>
                  <span className="navbar-brand footer-nav">
                    <a href="/">
                      <img
                        src="/images/logo-only-white.png"
                        height="24px"
                        alt="FarmGround.com"
                      />
                    </a>{" "}
                    <a href="/">FarmGround.com</a>
                  </span>
                  <div className="ml-auto nav footer-nav">
                    <a
                      href="/for-sale"
                      data-rb-event-key="1"
                      className="nav-link"
                    >
                      For Sale
                    </a>
                    <a
                      href="/for-rent"
                      data-rb-event-key="2"
                      className="nav-link"
                    >
                      For Rent
                    </a>
                    <a
                      href="/auctions"
                      data-rb-event-key="3"
                      className="nav-link"
                    >
                      Auctions
                    </a>
                    <a
                      href="/agents"
                      data-rb-event-key="3"
                      className="nav-link"
                    >
                      Real Estate Agents
                    </a>
                    <a
                      href="/aboutUs"
                      data-rb-event-key="5"
                      className="nav-link"
                    >
                      About
                    </a>
                    <a
                      href="/contactUs"
                      data-rb-event-key="6"
                      className="nav-link"
                    >
                      Contact
                    </a>
                  </div>
                  <div className="ml-auto nav footer-nav">
                    <a href="/privacy-policy" className="nav-link">
                      Privacy
                    </a>
                    <a href="/terms-and-conditions" className="nav-link">
                      Terms
                    </a>
                    <a href="/sitemap" className="nav-link">
                      Sitemap
                    </a>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <p></p>
                  <p>
                    Copyright © 2019-2020 {config.ORG_NAME}. All rights
                    reserved.
                  </p>
                  <p>
                    The information contained on this page is believed to be
                    reliable by it's submitter. However, this information is not
                    guarantee and we urge you to do further investigation on
                    your own.
                  </p>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      )
    );
  }
}
export default withRouter(App);
