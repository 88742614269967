import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listFgUsers as ListFgUsers } from "../graphql/queries";
import { getFgOrg as GetFgOrg } from "../graphql/queries";
import { listFgFarms as ListFgFarms } from "../graphql/queries";
import { Col, Card, Row } from "react-bootstrap";
import ContactSeller from "../components/ContactSeller";
import "./Seller.css";
import { Container } from "aws-amplify-react";

export default class Seller extends Component {
  constructor(props) {
    console.log("constructor");

    super(props);

    this.state = {
      isLoading: true,
      farms: [],
    };
  }

  async componentDidMount() {
    try {
      const graphQLUsrResponse = await API.graphql(
        graphqlOperation(ListFgUsers, {
          filter: {
            key: {
              eq: this.props.match.params.id,
            },
          },
        })
      );

      const users = graphQLUsrResponse.data.listFgUsers.items;

      const {
        id,
        key,
        attachment,
        userType,
        email,
        phone,
        givenName,
        familyName,
        address1,
        address2,
        city,
        state,
        zip,
        orgId,
        orgAdmin,
        orgApproved,
      } = users[0];

      let attachmentURL;
      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        id,
        key,
        attachment,
        userType,
        email,
        phone,
        givenName,
        familyName,
        address1,
        address2: this.isEmptyOrSpaces(address2) ? "" : address2,
        city,
        state,
        zip,
        orgId,
        orgAdmin,
        orgApproved,
        attachmentURL,
      });

      console.log(this.state);

      if (
        !this.isEmptyOrSpaces(orgId) &&
        !this.isEmptyOrSpaces(orgApproved) &&
        orgApproved === "true"
      ) {
        const graphQLOrgResponse = await API.graphql(
          graphqlOperation(GetFgOrg, { id: orgId })
        );

        const org = graphQLOrgResponse.data.getFgOrg;

        const { key, attachment, name, description, stateLicensedIn } = org;

        let attachmentURL;
        if (attachment) {
          attachmentURL = await Storage.vault.get(attachment);
        }

        this.setState({
          key,
          orgAttachment: attachment,
          name,
          description,
          stateLicensedIn,
          attachmentURL,
        });
      }

      const graphQLResponse = await API.graphql(
        graphqlOperation(ListFgFarms, {
          filter: {
            userKey: {
              eq: this.props.match.params.id,
            },
            farmStatusNumber: {
              lt: 700,
            },
          },
        })
      );

      // Sort by Create Date DESC
      graphQLResponse.data.listFgFarms.items.sort(function (a, b) {
        return a.createdAt < b.createdAt
          ? 1
          : b.createdAt < a.createdAt
          ? -1
          : 0;
      });

      const farms = graphQLResponse.data.listFgFarms.items;

      this.setState({ farms: farms });

      this.setState({ isLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";

    return picUrl;
  }

  renderFarmsCols(farms) {
    return [{}].concat(farms).map((farm, i) =>
      i !== 0 ? (
        <Col xs={12} md={4} className="card-col" key={farm.id}>
          <a href={`/listing/${farm.farmKey}`} title="Click to view listing...">
            <Card key={i}>
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(farm.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid card-img-top-sm"
                    src={this.getImageUrl(farm.attachment)}
                    alt={
                      this.isEmptyOrSpaces(farm.farmName)
                        ? "View more details..."
                        : "View more details about " + farm.farmName.trim()
                    }
                    responsive
                  />
                ) : (
                  <Card.Img variant="top" src="./images/carousel.png" alt="" />
                )}
              </span>
              <Card.Body>
                <span className="bold">
                  <h6>
                    {farm.farmStatusNumber === 600
                      ? farm.farmSaleType === "For Rent"
                        ? "Rented"
                        : "Sold"
                      : farm.farmStatusNumber === 400
                      ? farm.farmSaleType === "For Rent"
                        ? "Rental Pending"
                        : "Sale Pending"
                      : farm.farmSaleType === "For Rent" ||
                        farm.farmSaleType === "Online Auction"
                      ? farm.farmSaleType.trim()
                      : farm.farmTotalPrice}
                  </h6>
                  {this.isEmptyOrSpaces(farm.farmTotalAcres)
                    ? "?"
                    : farm.farmTotalAcres.trim()}{" "}
                  Acres
                </span>
                <hr />
                <span className="property-card--address-pin-and-address">
                  <span className="property-card--address-pin"></span>
                  <span className="property-card--address">
                    <span className="location">
                      {farm.farmAddress}
                      <br />
                      {farm.farmCity +
                        ", " +
                        farm.farmState +
                        " " +
                        farm.farmPostalCd}
                      <br />
                      {"(" + farm.farmCounty + " County)"}
                    </span>
                  </span>
                </span>
              </Card.Body>
            </Card>{" "}
          </a>
        </Col>
      ) : (
        ""
      )
    );
  }

  renderNoFarms() {
    return (
      <Row>
        <Col xs={12} md={12}>
          No events are currently scheduled for this team.
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className="Seller">
        {!this.state.isLoading && (
          <Container>
            <Row className="show-grid">
              <Col xs={12} md={8}>
                <Row className="show-grid">
                  <Col xs={12} md={4}>
                    {!this.isEmptyOrSpaces(this.state.attachment) ? (
                      <Card.Img
                        variant="top"
                        className="img-fluid"
                        src={this.getImageUrl(this.state.attachment)}
                        alt={
                          this.isEmptyOrSpaces(this.state.givenName)
                            ? "View more details..."
                            : "View more details about " +
                              this.state.givenName.trim()
                        }
                        responsive
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src="./images/carousel.png"
                        alt=""
                      />
                    )}
                  </Col>
                  <Col xs={12} md={8}>
                    <span className="bold">
                      <h6>
                        {this.state.givenName + " " + this.state.familyName}
                      </h6>
                      <span>{this.state.phone}</span>
                    </span>
                    <hr />
                    <span className="property-card--address-pin-and-address">
                      <span className="property-card--address-pin"></span>
                      <span className="property-card--address">
                        <address className="location">
                          <span>
                            {this.state.address1}
                            <br />
                            {this.state.city +
                              ", " +
                              this.state.state +
                              " " +
                              this.state.zip}
                          </span>
                        </address>
                      </span>
                    </span>
                  </Col>
                </Row>
                <Col xs={12} md={12}>
                  <hr />
                </Col>
                {!this.isEmptyOrSpaces(this.state.orgAttachment) ? (
                  <Row className="show-grid">
                    <Col xs={12} md={4}>
                      <Card.Img
                        variant="top"
                        className="img-fluid"
                        src={this.getImageUrl(this.state.orgAttachment)}
                        alt={
                          this.isEmptyOrSpaces(this.state.name)
                            ? "View more details..."
                            : "View more details about " +
                              this.state.name.trim()
                        }
                        responsive
                      />
                    </Col>
                    <Col xs={12} md={8}>
                      <h6>
                        <a
                          href={"/agent/" + this.state.key}
                          title="Click to view agency..."
                        >
                          {this.state.name}
                        </a>
                      </h6>
                      <span>{this.state.description}</span>
                      <br />
                      <span>
                        <br />
                        States Licensed In: {" " + this.state.stateLicensedIn}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="show-grid">
                  <Col xs={12} md={12}>
                    <hr />
                    <h5>Seller's Active Listings:</h5>
                  </Col>
                  {!this.state.isLoading ? (
                    this.state.farms.length > 0 ? (
                      this.renderFarmsCols(this.state.farms)
                    ) : (
                      <Col xs={12} md={12}>
                        <i>Seller currently has no active listings!</i>
                      </Col>
                    )
                  ) : (
                    "Loading..."
                  )}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <ContactSeller
                  toEmail={this.state.email}
                  toKey={this.state.key}
                  toName={this.state.givenName + " " + this.state.familyName}
                ></ContactSeller>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}
