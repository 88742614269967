export default {
  ORG_ID: "farmgroundcom",
  ORG_NAME: "FarmGround.com",
  ORG_WEBSITE_NAME: "FarmGround.com",
  ORG_PAGE_TITLE: "FarmGround.com Buy Sell Auction",
  ORG_ADMIN_GROUP: "FG-ADMIN",
  ORG_URL: "https://www.farmground.com",
  ORG_FROM_EMAIL: "contact@farmground.com",
  ORG_EMAIL: "contact@farmground.com",
  MAX_ATTACHMENT_SIZE: 5000000,
  CONTACT_US_SUBJECT: "FarmGround.com - Contact Us Form",
  CONTACT_US_TOPIC_ARN:
    "arn:aws:sns:us-east-1:984351765331:com-farmground-contact-us",
  TEST_TOPIC_ARN: "arn:aws:sns:us-east-1:984351765331:com-longhorns-test",
  RECAPTCHA_SITE_KEY: "6LcFsuMUAAAAAFykCR8JIlJmjpCMp1BH6u2P8aH-",
  IDENTITY_POOL_ID: "us-east-1:a8f60c72-373c-4982-a4bd-9c9a418dbb4b",
  REGION: "us-east-1",
  s3: {
    REGION: "us-east-1",
    BUCKET: "fg-file-storage"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.podiumit.com"
  },
  apiGatewayPayment: {
    REGION: "us-east-1",
    URL: "https://vs7ki3wfwd.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_nft1uEbbZ",
    APP_CLIENT_ID: "7fbe85tkm9gmd6bl5lbgtuo94g",
    IDENTITY_POOL_ID: "us-east-1:a8f60c72-373c-4982-a4bd-9c9a418dbb4b"
  },
  braintreegateway: {
    MERCHANT_ID: "nfhdjmhcdnxrhc9m",
    PUBLIC_KEY: "m4kgbdnh559cphnc",
    PRIVATE_KEY: "f54e248a22647dd54fcf28082f8f0457"
  },
  braintreegatewaysandbox: {
    MERCHANT_ID: "bhgnyhdr58jv5ssb",
    PUBLIC_KEY: "vwg5tnt89pm33tx2",
    PRIVATE_KEY: "24281529e6e5219eb848e2b0fa1e442b"
  }
};
