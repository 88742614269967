/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFgUser = /* GraphQL */ `
  subscription OnCreateFgUser {
    onCreateFgUser {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const onUpdateFgUser = /* GraphQL */ `
  subscription OnUpdateFgUser {
    onUpdateFgUser {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const onDeleteFgUser = /* GraphQL */ `
  subscription OnDeleteFgUser {
    onDeleteFgUser {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const onCreateFgOrg = /* GraphQL */ `
  subscription OnCreateFgOrg {
    onCreateFgOrg {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const onUpdateFgOrg = /* GraphQL */ `
  subscription OnUpdateFgOrg {
    onUpdateFgOrg {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const onDeleteFgOrg = /* GraphQL */ `
  subscription OnDeleteFgOrg {
    onDeleteFgOrg {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const onCreateFgFarm = /* GraphQL */ `
  subscription OnCreateFgFarm {
    onCreateFgFarm {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const onUpdateFgFarm = /* GraphQL */ `
  subscription OnUpdateFgFarm {
    onUpdateFgFarm {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const onDeleteFgFarm = /* GraphQL */ `
  subscription OnDeleteFgFarm {
    onDeleteFgFarm {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const onCreateFgFarmAuctionBid = /* GraphQL */ `
  subscription OnCreateFgFarmAuctionBid {
    onCreateFgFarmAuctionBid {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const onUpdateFgFarmAuctionBid = /* GraphQL */ `
  subscription OnUpdateFgFarmAuctionBid {
    onUpdateFgFarmAuctionBid {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const onDeleteFgFarmAuctionBid = /* GraphQL */ `
  subscription OnDeleteFgFarmAuctionBid {
    onDeleteFgFarmAuctionBid {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const onCreateFgFarmRentOffer = /* GraphQL */ `
  subscription OnCreateFgFarmRentOffer {
    onCreateFgFarmRentOffer {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const onUpdateFgFarmRentOffer = /* GraphQL */ `
  subscription OnUpdateFgFarmRentOffer {
    onUpdateFgFarmRentOffer {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const onDeleteFgFarmRentOffer = /* GraphQL */ `
  subscription OnDeleteFgFarmRentOffer {
    onDeleteFgFarmRentOffer {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const onCreateFgState = /* GraphQL */ `
  subscription OnCreateFgState {
    onCreateFgState {
      id
      state
      counties
      displayFlag
    }
  }
`;
export const onUpdateFgState = /* GraphQL */ `
  subscription OnUpdateFgState {
    onUpdateFgState {
      id
      state
      counties
      displayFlag
    }
  }
`;
export const onDeleteFgState = /* GraphQL */ `
  subscription OnDeleteFgState {
    onDeleteFgState {
      id
      state
      counties
      displayFlag
    }
  }
`;
