import React from "react";
import { Row, Col, Card, ButtonToolbar, Button } from "react-bootstrap";
import "./ListingCardCols.css";

class ListingCardCols extends React.Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isLoading: null,
    };
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://d1o6iidkcnk8kk.cloudfront.net/public/" +
      attachment +
      "?width=462";
    return picUrl;
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  renderFarmTags(tags) {
    return [{}].concat(tags).map((tag, i) =>
      i !== 0 ? (
        <span key={i}>
          {tag ? (
            i === 1 ? (
              <span>
                {tag.substring(tag.indexOf("name=") + 5, tag.indexOf(", id=")) +
                  " "}
              </span>
            ) : (
              <span>
                <span className="dot"></span>
                {" " +
                  tag.substring(
                    tag.indexOf("name=") + 5,
                    tag.indexOf(", id=")
                  ) +
                  " "}
              </span>
            )
          ) : (
            ""
          )}
        </span>
      ) : (
        ""
      )
    );
  }

  renderFarmsCols(farms) {
    return [{}].concat(farms).map((farm, i) =>
      i !== 0 ? (
        <Col xs={12} md={4} className="card-col" key={i}>
          <Card>
            <a
              href={`/listing/${farm.farmKey}`}
              title="Click to view details..."
            >
              <span className="card-img-top">
                {!this.isEmptyOrSpaces(farm.attachment) ? (
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                    src={this.getImageUrl(farm.attachment)}
                    alt={
                      this.isEmptyOrSpaces(farm.farmName)
                        ? "Farm Land"
                        : farm.farmName.trim()
                    }
                    responsive="true"
                  />
                ) : (
                  <Card.Img
                    variant="top"
                    src="./images/logo-blk-900x500.png"
                    alt=""
                  />
                )}
              </span>
              <Card.Body>
                <Row>
                  <Col xs={6} md={6} className="card-body-heading">
                    {farm.farmStatusNumber === 600
                      ? farm.farmSaleType === "For Rent"
                        ? "Rented"
                        : "Sold"
                      : farm.farmStatusNumber === 400
                      ? farm.farmSaleType === "For Rent"
                        ? "Rental Pending"
                        : "Sale Pending"
                      : farm.farmSaleType === "For Rent" ||
                        farm.farmSaleType === "Online Auction"
                      ? farm.farmSaleType.trim()
                      : farm.farmTotalPrice}
                  </Col>
                  <Col xs={6} md={6} className="card-body-heading">
                    <span className="float-right">
                      {this.isEmptyOrSpaces(farm.farmTotalAcres)
                        ? "?"
                        : farm.farmTotalAcres.trim()}{" "}
                      Acres
                    </span>
                  </Col>
                  {farm.farmTags && (
                    <Col xs={12} md={12}>
                      <b>{this.renderFarmTags(farm.farmTags)}</b>
                    </Col>
                  )}
                </Row>
                <hr />
                <div className="property-card--address-pin-and-address">
                  <div className="property-card--address-pin"></div>
                  <div className="property-card--address">
                    <span className="location">
                      {farm.farmAddress}
                      <br />
                      {farm.farmCity +
                        ", " +
                        farm.farmState +
                        " " +
                        farm.farmPostalCd}
                    </span>
                    {"(" + farm.farmCounty + " County)"}
                  </div>
                </div>
              </Card.Body>
            </a>
            {this.props.edit && (
              <Card.Footer>
                <Row>
                  <Col xs={6} md={6} className="bold">
                    Status:{" "}
                    {farm.farmStatusNumber === 200
                      ? "Active"
                      : farm.farmStatusNumber === 400
                      ? farm.farmSaleType === "For Rent"
                        ? "Rental Pending"
                        : "Sale Pending"
                      : farm.farmStatusNumber === 600
                      ? farm.farmSaleType === "For Rent"
                        ? "Rented"
                        : "Sold"
                      : farm.farmStatusNumber === 700
                      ? "Hidden"
                      : ""}
                  </Col>
                  <Col xs={6} md={6}>
                    <ButtonToolbar className="float-right">
                      {farm.farmSaleType === "Online Auction" ? (
                        <Button
                          href={`/farm/auction/${farm.id}`}
                          variant="primary"
                          className="card-footer-btn"
                          size="sm"
                        >
                          Edit Auction Details
                        </Button>
                      ) : farm.farmSaleType === "For Sale" ? (
                        <Button
                          href={`/farm/sell/${farm.id}`}
                          variant="primary"
                          className="card-footer-btn buttonSpace"
                          size="sm"
                        >
                          Edit For Sale Details
                        </Button>
                      ) : farm.farmSaleType === "For Rent" ? (
                        <Button
                          href={`/farm/rent/${farm.id}`}
                          variant="primary"
                          className="card-footer-btn buttonSpace"
                          size="sm"
                        >
                          Edit For Rent Details
                        </Button>
                      ) : (
                        ""
                      )}
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Card.Footer>
            )}
          </Card>
        </Col>
      ) : (
        ""
      )
    );
  }

  render() {
    return (
      <div className="Listings">
        <Row className="show-grid">
          {this.renderFarmsCols(this.props.farms)}
        </Row>
      </div>
    );
  }
}
export default ListingCardCols;
