import React, { Component } from "react";
import { Form, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AWS from "aws-sdk";
import config from "../config";
import "./AboutUs.css";
import ReCAPTCHA from "react-google-recaptcha";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isLoading: null,
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
      content:
        "<p>Please complete the form below to send our staff any questions you might about FarmGround.com.  Please contact the agents directly if you have a question about a particular listings.</p>"
    };
  }

  validateForm() {
    return (
      this.state.name.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.email.length > 0 &&
      this.state.subject.length > 0 &&
      this.state.message.length > 0
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    // Initialize the Amazon Cognito credentials provider
    AWS.config.region = "us-east-1"; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.IDENTITY_POOL_ID
    });

    if (this.recaptchaRef) {
      const recaptchaValue = this.recaptchaRef.current.getValue();

      if (!recaptchaValue || recaptchaValue == null) {
        alert("Please complete the reCaptcha dialog! - " + recaptchaValue);
        this.setState({ isLoading: false });
        return;
      }
    }

    var sns = new AWS.SNS();

    var params = {
      //Message: 'Hello topic', /* required */
      Message:
        "Name: " +
        this.state.name +
        "\n" +
        " Phone: " +
        this.state.phone +
        "\n" +
        " Email: " +
        this.state.email +
        "\n" +
        " Subject: " +
        this.state.subject +
        "\n" +
        " Message: " +
        this.state.message +
        "\n\n",
      Subject: config.CONTACT_US_SUBJECT,
      TopicArn: config.CONTACT_US_TOPIC_ARN
    };

    sns.publish(params, function(err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
      } else {
        //console.log(data); // successful response
      }
    });

    this.props.history.push("/contactUs/confirm");
  };

  render() {
    return (
      <div className="ContactUs">
        <h1>Contact Us</h1>
        <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Your Name:</Form.Label>
            <FormControl
              type="text"
              value={this.state.name}
              placeholder="Enter your name"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone:</Form.Label>
            <FormControl
              type="text"
              value={this.state.phone}
              placeholder="Enter your phone number"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email Address:</Form.Label>
            <FormControl
              type="text"
              value={this.state.email}
              placeholder="Enter your email address"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="subject">
            <Form.Label>Subject:</Form.Label>
            <FormControl
              type="text"
              value={this.state.subject}
              placeholder="Enter your message subject"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>Message:</Form.Label>
            <FormControl
              as="textarea"
              rows="3"
              value={this.state.message}
              placeholder="Enter your message"
              onChange={this.handleChange}
            />
          </Form.Group>
          <ReCAPTCHA
            sitekey={config.RECAPTCHA_SITE_KEY}
            ref={this.recaptchaRef}
          />
          <LoaderButton
            block
            bsstyle="primary"
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Send Message"
            loadingText="Sending..."
          />
        </Form>
      </div>
    );
  }
}
