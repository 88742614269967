import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createFgFarm as CreateFgFarm } from "../graphql/mutations";
import { getFgUser as GetFgUser } from "../graphql/queries";
import { getFgOrg as GetFgOrg } from "../graphql/queries";
import { updateFgOrg as UpdateFgOrg } from "../graphql/mutations";
import {
  Container,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import DropIn from "braintree-web-drop-in-react";
import uuid from "uuid/v4";
import "./NewListing.css";

const FARM_ID = uuid();

export default class NewListing extends Component {
  instance;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false,
      declinedError: false,
      clientToken: null,
      show: false,
      farmSaleType: "For Sale",
      adLevel: "MAX",
      adFee: "15.95",
      pushPath: "/farm/sell/" + FARM_ID,
    };
  }

  async componentDidMount() {
    // Get a client token for authorization from your server
    API.post("payment", "/token", {}).then((response) => {
      this.setState({
        clientToken: response.response.result.clientToken,
      });
      this.setState({
        isLoading: false,
      });
    });
  }

  async buy() {
    this.setState({ isProcessing: true });

    //Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();

    API.post("payment", "/purchase", {
      body: {
        nonce,
        amount: this.state.adFee,
        orderId: FARM_ID,
      },
    })
      .then((response) => {
        console.log("response", response);
        this.setState({ show: false, isLoading: true });
        this.createFarmListing();
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({
          declinedError: true,
          isProcessing: false,
          show: false,
          isLoading: false,
        });
      });
  }

  async free() {
    // Send the nonce to your server
    this.setState({ isProcessing: true });

    this.createFarmListing();
  }

  handleHideModal = async (farm) => {
    farm.preventDefault();
    this.setState({ show: false });
  };

  handleForSaleMaxSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Sale",
      adLevel: "MAX",
      adFee: "9.95",
      pushPath: "/farm/sell/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleForSalePlusSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Sale",
      adLevel: "PLUS",
      adFee: "5.95",
      pushPath: "/farm/sell/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleForSaleFreeSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Sale",
      adLevel: "FREE",
      adFee: "0.00",
      pushPath: "/farm/sell/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleForRentMaxSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Rent",
      adLevel: "MAX",
      adFee: "9.95",
      pushPath: "/farm/rent/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleForRentPlusSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Rent",
      adLevel: "PLUS",
      adFee: "5.95",
      pushPath: "/farm/rent/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleForRentFreeSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "For Rent",
      adLevel: "FREE",
      adFee: "0.00",
      pushPath: "/farm/rent/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleOnlineAuctioneMaxSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "Online Auction",
      adLevel: "MAX",
      adFee: "24.95",
      pushPath: "/farm/auction/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  handleOnlineAuctionPlusSubmit = async (farm) => {
    farm.preventDefault();
    this.setState({
      farmSaleType: "Online Auction",
      adLevel: "PLUS",
      adFee: "15.95",
      pushPath: "/farm/auction/" + FARM_ID,
      show: true,
      declinedError: false,
      isProcessing: false,
    });
  };

  createFarmListing() {
    const adLevelVar = this.state.adLevel;
    try {
      API.graphql(
        graphqlOperation(CreateFgFarm, {
          input: {
            id: FARM_ID,
            farmStatusNumber: 700,
            farmSaleType: this.state.farmSaleType,
            adLevel: adLevelVar,
            userId: this.props.userId,
            farmTags: ["{name=" + this.state.farmSaleType + ", id=0}"],
            createdAt: new Date().toISOString(),
          },
        })
      ).then((response) => {
        if (adLevelVar !== "FREE") {
          API.graphql(
            graphqlOperation(GetFgUser, { id: this.props.userId })
          ).then((response) => {
            if (response.data.getFgUser !== null) {
              const usr = response.data.getFgUser;
              const { orgId } = usr;
              if (!this.isEmptyOrSpaces(orgId)) {
                API.graphql(graphqlOperation(GetFgOrg, { id: orgId })).then(
                  (response) => {
                    if (response.data.getFgOrg != null) {
                      const org = response.data.getFgOrg;
                      const { id, rank } = org;
                      API.graphql(
                        graphqlOperation(UpdateFgOrg, {
                          input: {
                            id: id,
                            rank: rank + 1,
                          },
                        })
                      );
                    }
                  }
                );
              }
            }
          });
        }
      });

      setTimeout(() => {
        this.props.history.push(this.state.pushPath);
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  }

  isEmptyOrSpaces(str) {
    return !str || str === undefined || str === "" || str.length === 0;
  }

  render() {
    return (
      <div className="NewListing">
        {!this.state.isLoading && (
          <Modal
            show={this.state.show}
            onHide={this.handleHideModal}
            animation={false}
            size="lg"
            variant="primary"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {"Buy " +
                  this.state.adLevel +
                  " " +
                  this.state.farmSaleType +
                  " Listing ($"}
                <s>{this.state.adFee}</s>
                {" FREE)"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This listing option is currently FREE. Click the Create button
              below to continue.
            </Modal.Body>
            {!this.state.isProcessing && (
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleHideModal}>
                  Close
                </Button>
                <Button variant="success" onClick={this.free.bind(this)}>
                  Create Listing
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        )}
        <Container key="NewListing">
          <br />
          <h3>Create a new listing!</h3>
          <span className="sub-title">
            MAXIMIZE YOUR CHANCE OF SELLING YOUR LISTING BY SELECTING OUR MAX
            OPTION
          </span>
          <hr />
          {this.state.declinedError && (
            <Alert key={1} variant="danger">
              Transaction failed. Please double check your information and try
              again.
            </Alert>
          )}
          {this.state.isLoading ? (
            "Loading..."
          ) : (
            <Tabs>
              <Tab
                eventKey="for-sale"
                className="tab-nav-link"
                title="For Sale"
              >
                <br />
                <Row>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-max" as="h5">
                        FOR SALE MAX EXPOSURE
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>9.95</s> FREE (<i>Best Value</i>)
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Sale Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 32 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 12 PDF Documents</mark>
                          </li>
                          <li>
                            <mark>Ability to add 1 featured MP4 movie</mark>
                          </li>
                          <li>
                            <mark>Posted on FarmGround.com Facebook Page</mark>
                          </li>
                          <li>
                            <mark>Included on FarmGround.com's Homepage</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="success"
                          onClick={this.handleForSaleMaxSubmit}
                        >
                          Create Your Max For Sale Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-plus" as="h5">
                        FOR SALE PLUS
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>5.95</s> FREE
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Sale Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 18 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 6 PDF Documents</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="primary"
                          onClick={this.handleForSalePlusSubmit}
                        >
                          Create Your Plus For Sale Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-free" as="h5">
                        For Sale Free
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Free</Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Sale Listing</li>
                          <li>1 Featured Image</li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="secondary"
                          onClick={this.handleForSaleFreeSubmit}
                        >
                          Create Your Free For Sale Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="for-rent"
                className="tab-nav-link"
                title="For Rent"
              >
                <br />
                <Row>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-max" as="h5">
                        FOR RENT MAX EXPOSURE
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>9.95</s> FREE (<i>Best Value</i>)
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Rent Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 32 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 12 PDF Documents</mark>
                          </li>
                          <li>
                            <mark>Ability to add 1 featured MP4 movie</mark>
                          </li>
                          <li>
                            <mark>Posted on FarmGround.com Facebook Page</mark>
                          </li>
                          <li>
                            <mark>Included on FarmGround.com's Homepage</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>View All Offers</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="success"
                          onClick={this.handleForRentMaxSubmit}
                        >
                          Create Your Max For Rent Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-plus" as="h5">
                        FOR RENT PLUS
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>5.95</s> FREE
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Rent Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 18 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 6 PDF Documents</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>View All Offers</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="primary"
                          onClick={this.handleForRentPlusSubmit}
                        >
                          Create Your Plus For Rent Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4} className="card-col">
                    <Card>
                      <Card.Header className="card-header-free" as="h5">
                        For Rent Free
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Free</Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com For Rent Listing</li>
                          <li>1 Featured Image</li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>Listing View Counter</li>
                          <li>View All Offers</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="secondary"
                          onClick={this.handleForRentFreeSubmit}
                        >
                          Create Your Free For Rent Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="onine-auction"
                className="tab-nav-link"
                title="Auction"
              >
                <br />
                <Row>
                  <Col xs={12} md={6} className="card-col">
                    <Card>
                      <Card.Header className="card-header-max" as="h5">
                        ONLINE AUCTION MAX EXPOSURE
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>24.95</s> FREE (<i>Best Value</i>)
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com Online Aution Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 32 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 12 PDF Documents</mark>
                          </li>
                          <li>
                            <mark>Ability to add 1 featured MP4 movie</mark>
                          </li>
                          <li>
                            <mark>Posted on FarmGround.com Facebook Page</mark>
                          </li>
                          <li>
                            <mark>Included on FarmGround.com's Homepage</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>View All Bids and Winning Bidder</li>
                          <li>Listing View Counter</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="success"
                          onClick={this.handleOnlineAuctioneMaxSubmit}
                        >
                          Create Your Max Online Auction Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={6} className="card-col">
                    <Card>
                      <Card.Header className="card-header-plus" as="h5">
                        ONLINE AUCTION PLUS
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          $<s>15.95</s> FREE
                        </Card.Title>
                        WHAT YOU GET
                        <ul>
                          <li>1 FarmGround.com Online Auction Listing</li>
                          <li>1 Featured Image</li>
                          <li>
                            <mark>Up to 18 Additional Images</mark>
                          </li>
                          <li>
                            <mark>Up to 6 PDF Documents</mark>
                          </li>
                          <li>Online Contact Form</li>
                          <li>Editable Anytime</li>
                          <li>View All Bids and Winning Bidder</li>
                          <li>Listing View Counter</li>
                          <li>Listed Until Sold</li>
                        </ul>
                        <Button
                          variant="primary"
                          onClick={this.handleOnlineAuctionPlusSubmit}
                        >
                          Create Your Plus Online Auction Listing
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          )}
        </Container>
      </div>
    );
  }
}
