import React from "react";
import "./NotFound.css";

export default () => (
  <div className="NotFound">
    <h3>Sorry, the page you are looking for does not exist or has moved!</h3>
    <h4>
      Please use the navigation above to find the page you are looking for.
    </h4>
  </div>
);
