import React from "react";
import { Map, TileLayer, Popup, Polygon } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./MyMap.css";

var turf = require("@turf/turf");

class MyMap extends React.Component {
  constructor(props) {
    super(props);

    let lat = "";
    let lng = "";
    let pstns = [];
    let centroid = null;
    let attribution =
      "&copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors";
    let url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    if (this.props.lat) {
      lat = parseFloat(this.props.lat);
    }
    if (this.props.lng) {
      lng = parseFloat(this.props.lng);
    }

    if (this.props.pstns) {
      pstns = parseFloat(this.props.pstns);

      if (this.props.positions && this.props.positions.length > 3) {
        let polygon = turf.polygon([this.props.positions]);
        centroid = turf.centroid(polygon);
      }
    }

    if (this.props.satellite) {
      attribution = "&copy FarmGround.com";
      url =
        "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFybWdyb3VuZGNvbSIsImEiOiJjazh5eXNkdHYwMmhwM2drdGdndG5yZGZxIn0.vvo6LL-lN0iQsdv9hjy4iA";
    }

    this.state = {
      isLoading: null,
      attribution: attribution,
      url: url,
      lat: lat,
      lng: lng,
      zoom: 14,
      positionFirst: null,
      positions: pstns,
    };
  }

  calculateAcres = () => {
    if (this.state.positions.length > 3) {
      let positions = this.state.positions;
      positions = positions.concat([this.state.positionFirst]);
      let polygon = turf.polygon([positions]);
      console.log("polygon", polygon);
      let area = turf.area(polygon);
      console.log("area", area);
      let centroid = turf.centroid(polygon);
      console.log("centroid", centroid);
      let acres = turf.round(turf.convertArea(area, "meters", "acres") * 10, 2);
      console.log("acres", acres);
      this.setState({
        acres: acres,
      });
    }
  };

  addPosition = (e) => {
    const newPos = [e.latlng.lat, e.latlng.lng];

    if (this.state.positionFirst == null) {
      this.setState({
        positionFirst: newPos,
      });
    }

    console.log("newPos", newPos);
    this.setState({
      positions: this.state.positions.concat([newPos]),
    });

    console.log("positions", this.state.positions);

    this.calculateAcres();
  };

  resetPoly = (e) => {
    this.setState({
      acres: "",
      zoom: 14,
      positionFirst: null,
      positions: [],
    });
  };

  render() {
    return (
      <span>
        <Map
          center={[this.state.lat, this.state.lng]}
          zoom={this.state.zoom}
          style={{ width: "100%", height: "500px" }}
          onClick={this.addPosition}
        >
          <TileLayer
            attribution={this.state.attribution}
            url={this.state.url}
          />

          <Polygon positions={this.state.positions}>
            <Popup>
              {"Listing: " +
                this.props.farmName +
                " - " +
                this.state.acres +
                " Acres (M/L)"}
            </Popup>
          </Polygon>

          {/* <Marker position={[this.state.lat, this.state.lng]} icon>
            <Popup>
              {"Listing: " +
                this.props.farmName +
                " - " +
                this.state.acres +
                " Acres (M/L)"}
            </Popup>
          </Marker> */}
        </Map>
        <button onClick={this.resetPoly}>Reset</button>
      </span>
    );
  }
}
export default MyMap;
