/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFgUser = /* GraphQL */ `
  query GetFgUser($id: ID!) {
    getFgUser(id: $id) {
      id
      key
      attachment
      userType
      email
      phone
      givenName
      familyName
      address1
      address2
      city
      state
      zip
      orgId
      orgKey
      orgAdmin
      orgApproved
    }
  }
`;
export const listFgUsers = /* GraphQL */ `
  query ListFgUsers(
    $filter: ModelFgUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        attachment
        userType
        email
        phone
        givenName
        familyName
        address1
        address2
        city
        state
        zip
        orgId
        orgKey
        orgAdmin
        orgApproved
      }
      nextToken
    }
  }
`;
export const getFgOrg = /* GraphQL */ `
  query GetFgOrg($id: ID!) {
    getFgOrg(id: $id) {
      id
      key
      attachment
      orgType
      email
      phone
      name
      description
      website
      stateLicensedIn
      address1
      address2
      city
      state
      zip
      rank
    }
  }
`;
export const listFgOrgs = /* GraphQL */ `
  query ListFgOrgs(
    $filter: ModelFgOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        attachment
        orgType
        email
        phone
        name
        description
        website
        stateLicensedIn
        address1
        address2
        city
        state
        zip
        rank
      }
      nextToken
    }
  }
`;
export const getFgFarm = /* GraphQL */ `
  query GetFgFarm($id: ID!) {
    getFgFarm(id: $id) {
      id
      attachment
      createdAt
      farmAddress
      farmAgentAddress
      farmAgentCellPhone
      farmAgentEmail
      farmAgentLicensedIn
      farmAgentName
      farmAgentWorkPhone
      farmAuctionBidAmount
      farmAuctionBidderId
      farmCity
      farmCounty
      farmDetails
      farmDocs
      farmEndDateTime
      farmGoogleMapLink
      farmKey
      farmListingNumber
      farmName
      farmPhotos
      farmPostalCd
      farmPricePerAcre
      farmSaleType
      farmStartDateTime
      farmState
      farmStatus
      farmStatusNumber
      farmTotalAcres
      farmTotalPrice
      farmTags
      farmType
      farmVideos
      farmViewed
      userId
      userKey
      userAttachment
      mapPositions
      mapLat
      mapLng
      adLevel
      adRank
      adStatus
      orgId
      orgKey
      lastUpdatedAt
    }
  }
`;
export const listFgFarms = /* GraphQL */ `
  query ListFgFarms(
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const getFgFarmAuctionBid = /* GraphQL */ `
  query GetFgFarmAuctionBid($id: ID!) {
    getFgFarmAuctionBid(id: $id) {
      id
      farmId
      farmKey
      bidAmountMax
      bidderId
      userId
      bidderInitials
      createdAt
    }
  }
`;
export const listFgFarmAuctionBids = /* GraphQL */ `
  query ListFgFarmAuctionBids(
    $filter: ModelFgFarmAuctionBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgFarmAuctionBids(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        farmId
        farmKey
        bidAmountMax
        bidderId
        userId
        bidderInitials
        createdAt
      }
      nextToken
    }
  }
`;
export const getFgFarmRentOffer = /* GraphQL */ `
  query GetFgFarmRentOffer($id: ID!) {
    getFgFarmRentOffer(id: $id) {
      id
      farmId
      farmKey
      offerAmount
      offerComments
      offerInitials
      offerorId
      userId
      createdAt
    }
  }
`;
export const listFgFarmRentOffers = /* GraphQL */ `
  query ListFgFarmRentOffers(
    $filter: ModelFgFarmRentOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgFarmRentOffers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        farmId
        farmKey
        offerAmount
        offerComments
        offerInitials
        offerorId
        userId
        createdAt
      }
      nextToken
    }
  }
`;
export const getFgState = /* GraphQL */ `
  query GetFgState($id: ID!) {
    getFgState(id: $id) {
      id
      state
      counties
      displayFlag
    }
  }
`;
export const listFgStates = /* GraphQL */ `
  query ListFgStates(
    $filter: ModelFgStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFgStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        counties
        displayFlag
      }
      nextToken
    }
  }
`;
export const fgUserByKey = /* GraphQL */ `
  query FgUserByKey(
    $key: String
    $familyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgUserByKey(
      key: $key
      familyName: $familyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        attachment
        userType
        email
        phone
        givenName
        familyName
        address1
        address2
        city
        state
        zip
        orgId
        orgKey
        orgAdmin
        orgApproved
      }
      nextToken
    }
  }
`;
export const fgOrgByKey = /* GraphQL */ `
  query FgOrgByKey(
    $key: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgOrgByKey(
      key: $key
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        attachment
        orgType
        email
        phone
        name
        description
        website
        stateLicensedIn
        address1
        address2
        city
        state
        zip
        rank
      }
      nextToken
    }
  }
`;
export const fgFarmByAdLevel = /* GraphQL */ `
  query FgFarmByAdLevel(
    $adLevel: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByAdLevel(
      adLevel: $adLevel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const fgFarmByFarmStatus = /* GraphQL */ `
  query FgFarmByFarmStatus(
    $farmStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByFarmStatus(
      farmStatus: $farmStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const fgFarmByFarmSaleType = /* GraphQL */ `
  query FgFarmByFarmSaleType(
    $farmSaleType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByFarmSaleType(
      farmSaleType: $farmSaleType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const fgFarmByFarmKey = /* GraphQL */ `
  query FgFarmByFarmKey(
    $farmKey: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByFarmKey(
      farmKey: $farmKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const fgFarmByUserId = /* GraphQL */ `
  query FgFarmByUserId(
    $userId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachment
        createdAt
        farmAddress
        farmAgentAddress
        farmAgentCellPhone
        farmAgentEmail
        farmAgentLicensedIn
        farmAgentName
        farmAgentWorkPhone
        farmAuctionBidAmount
        farmAuctionBidderId
        farmCity
        farmCounty
        farmDetails
        farmDocs
        farmEndDateTime
        farmGoogleMapLink
        farmKey
        farmListingNumber
        farmName
        farmPhotos
        farmPostalCd
        farmPricePerAcre
        farmSaleType
        farmStartDateTime
        farmState
        farmStatus
        farmStatusNumber
        farmTotalAcres
        farmTotalPrice
        farmTags
        farmType
        farmVideos
        farmViewed
        userId
        userKey
        userAttachment
        mapPositions
        mapLat
        mapLng
        adLevel
        adRank
        adStatus
        orgId
        orgKey
        lastUpdatedAt
      }
      nextToken
    }
  }
`;
export const fgFarmAuctionBidByFarmKey = /* GraphQL */ `
  query FgFarmAuctionBidByFarmKey(
    $farmKey: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmAuctionBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmAuctionBidByFarmKey(
      farmKey: $farmKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        farmId
        farmKey
        bidAmountMax
        bidderId
        userId
        bidderInitials
        createdAt
      }
      nextToken
    }
  }
`;
export const fgFarmRentOfferByFarmKey = /* GraphQL */ `
  query FgFarmRentOfferByFarmKey(
    $farmKey: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFgFarmRentOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fgFarmRentOfferByFarmKey(
      farmKey: $farmKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        farmId
        farmKey
        offerAmount
        offerComments
        offerInitials
        offerorId
        userId
        createdAt
      }
      nextToken
    }
  }
`;
