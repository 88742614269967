import React, { Component } from "react";
import { Row, Col, Form, FormControl, ListGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phone: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      var email = this.state.email.toLowerCase();

      const newUser = await Auth.signUp({
        username: email,
        password: this.state.password,
        attributes: {
          given_name: "john",
          family_name: "doe",
          phone_number: "+15151112222",
        },
      });
      this.setState({
        newUser,
      });
    } catch (e) {
      if (e.name === "UsernameExistsException") {
        try {
          await Auth.signIn(this.state.email, this.state.password);
          this.props.userHasAuthenticated(true);
          this.props.history.push("/");
        } catch (e) {
          if (e.name === "UsernameExistsException") {
            alert("User already exists. Resending Verify Code");
            Auth.resendSignUp({
              username: this.state.email,
            });
          } else {
            alert(e.message);
            this.setState({ isLoading: false });
          }
        }
      } else {
        alert(e.message);
      }
    }

    this.setState({ isLoading: false });
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  renderConfirmationFormOld() {
    return (
      <Form onSubmit={this.handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" bssize="large">
          <Form.Label>Confirmation Code</Form.Label>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <Form.Text className="text-muted">
            Please check your email for the code.
          </Form.Text>
        </Form.Group>
        <LoaderButton
          block
          bssize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </Form>
    );
  }

  renderConfirmationForm() {
    return (
      <div id="verify">
        <h4>Almost done.....</h4>
        <br />
        <h5>
          Please check your email and click on the validation link to complete
          your sign up process.
        </h5>
        <br />
        <br />
        <h6>
          The validation email will be from <i>contact@farmground.com</i>. It
          may take 10-15 minutes for the email to arrive. Please check your junk
          email folders if you do not recieve an email validation email after
          this amount of time.
        </h6>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  renderForm() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="email" bssize="large">
              <Form.Label>Email</Form.Label>
              <FormControl
                autoFocus
                type="email"
                value={this.state.email}
                placeholder="Enter your main email address..."
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="password" bssize="large">
              <Form.Label>Password</Form.Label>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" bssize="large">
              <Form.Label>Confirm Password</Form.Label>
              <FormControl
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                type="password"
              />
            </Form.Group>
            <LoaderButton
              block
              bssize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Signup"
              loadingText="Signing up…"
            />
            <br />
            Already have an account? <a href="/login">Login Here!</a>
          </Form>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <strong>Password Requirements:</strong>
          <ListGroup>
            <ListGroup.Item>Minimum length of 8</ListGroup.Item>
            <ListGroup.Item>Must contain a number</ListGroup.Item>
            <ListGroup.Item>Must contain a special character</ListGroup.Item>
            <ListGroup.Item>Must contain an uppercase letter</ListGroup.Item>
            <ListGroup.Item>Must contain a lowercase letter</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className="Signup">
        <h4>Sign Up</h4>
        <div className="msg-center">
          <br />
          <br />
        </div>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
