import React, { Component } from "react";
import "./AboutUs.css";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUs">
        <img
          src="../images/og-image.jpg"
          alt="About Us"
          className="img-fluid pageImage"
          width="400"
        />
        <div>
          <br />
          <h4 className="text-center">
            The FarmGround.com website features one of the largest and most
            effective
            <br /> marketplaces to discover, buy and sell rural real estate.
          </h4>
          <br />
          <br />
          <h6 className="text-center">
            Leading Rural Real Estate Listing Site
          </h6>
          <br />
          <br />
          <p>
            Our goal for FarmGround.com is to connect active buyers and land
            owners with the best local real estate professionals throughout the
            United States. This premier rural property listing site drives top
            search engine visibility, social media and mobile devices traffic to
            our agents' listings to help them get more exposure to their
            offerings and sell and rent their properties quicker than ever.
          </p>
          <br />
          <p>
            Properties listed on FarmGround.com will include farms, ranches,
            timberland, hunting land, mountain property, lake houses, river
            homes, beachfront homes and country homes on acreage.
          </p>
          <br />
          <p>
            Thank you for visting and please check back regulary as we continue
            to grow!
          </p>
          <br />
          <p>- FarmGround.com Staff</p>
        </div>
      </div>
    );
  }
}
