import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Alert,
  Carousel,
} from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { fgFarmByFarmKey as ListFgFarmByFarmKey } from "../graphql_custom/queries";
import { fgFarmAuctionBidByFarmKey as FgFarmAuctionBidByFarmKey } from "../graphql_custom/queries";
import { fgFarmRentOfferByFarmKey as FgFarmRentOfferByFarmKey } from "../graphql_custom/queries";
import { onUpdateFgFarm as OnUpdateFgFarm } from "../graphql/subscriptions";
import { onCreateFgFarmRentOffer as OnCreateFgFarmRentOffer } from "../graphql/subscriptions";
import { onCreateFgFarmAuctionBid as OnCreateFgFarmAuctionBid } from "../graphql/subscriptions";
import { Helmet } from "react-helmet";
import DateTimeLocal from "../components/DateTimeLocal.js";
import Countdown from "../components/Countdown.js";
import ContactSellerFarm from "../components/ContactSellerFarm";
import "./Farm.css";

export default class Farm extends Component {
  async componentDidMount() {
    try {
      this.updateVisit(this.props.match.params.id);
    } catch (e) {
      alert(e);
    }
  }

  updateVisit(farmKey) {
    return API.put("events", "/fg-farm/visit/" + this.props.match.params.id, {
      body: {
        farmVisits: "1",
      },
    });
  }

  isEmptyOrSpaces(str) {
    return (
      !str ||
      str === null ||
      str === undefined ||
      str === "" ||
      str.length === 0
    );
  }

  getImageUrl(attachment) {
    var picUrl =
      "https://s3.amazonaws.com/fg-file-storage/public/" + attachment;
    return picUrl;
  }

  renderVideos(frmVideos, attachment) {
    return [{}].concat(frmVideos).map((video, i) =>
      i !== 0 ? (
        <video
          key={i}
          id="video-background"
          poster={this.getImageUrl(attachment)}
          width="100%"
          height="*"
          controls
        >
          <source src={this.getImageUrl(video)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : null
    );
  }

  renderCarouselItems(frmName, frmPhotos) {
    return [{}].concat(frmPhotos).map((photo, i) =>
      i !== 0 ? (
        <Carousel.Item key={i}>
          <Image
            variant="top"
            className="img-fluid"
            alt={frmName}
            src={this.getImageUrl(photo)}
          />
        </Carousel.Item>
      ) : null
    );
  }

  renderCarousel(attchmnt, frmName, frmPhotos) {
    return (
      <Carousel data-interval="false" responsive="true">
        {attchmnt ? (
          <Carousel.Item key="a">
            <Image
              variant="top"
              className="img-fluid"
              alt={frmName}
              src={this.getImageUrl(attchmnt)}
            />
          </Carousel.Item>
        ) : (
          ""
        )}
        {frmPhotos ? this.renderCarouselItems(frmName, frmPhotos) : ""}
      </Carousel>
    );
  }

  renderAuctionBids(bids) {
    return [{}].concat(bids).map((bid, i) =>
      i !== 0 ? (
        <Row key={i}>
          <Col xs={4} md={4}>
            <DateTimeLocal date={bid.createdAt} />
          </Col>
          <Col xs={4} md={4}>
            ${bid.bidAmountMax}
          </Col>
          <Col xs={4} md={4}>
            {bid.bidderId.replace(/[^1-7]/g, "").replace(/[3-5]/g, "*")}
          </Col>
        </Row>
      ) : (
        ""
      )
    );
  }

  onCreatedFgFarmAuctionBid = (prevQuery, newData) => {
    let data = {
      listFgFarmAuctionBids: {
        items: [],
      },
    };
    if (
      prevQuery &&
      prevQuery.listFgFarmAuctionBids &&
      prevQuery.listFgFarmAuctionBids.items
    ) {
      let newQuery = prevQuery.listFgFarmAuctionBids.items.concat(
        newData.onCreateFgFarmAuctionBid
      );
      data.listFgFarmAuctionBids.items = newQuery;

      return data;
    }
  };

  renderRentOffers(offers) {
    return [{}].concat(offers).map((offer, i) =>
      i !== 0 ? (
        <Row key={i}>
          <Col xs={4} md={4}>
            <DateTimeLocal date={offer.createdAt} />
          </Col>
          <Col xs={4} md={4}>
            ${offer.offerAmount}
          </Col>
          <Col xs={4} md={4}>
            {offer.offerorId.replace(/[^1-7]/g, "").replace(/[3-5]/g, "*")}
          </Col>
        </Row>
      ) : (
        ""
      )
    );
  }

  onCreatedFgFarmRentOffer = (prevQuery, newData) => {
    let data = {
      listFgFarmRentOffers: {
        items: [],
      },
    };
    if (
      prevQuery &&
      prevQuery.listFgFarmRentOffers &&
      prevQuery.listFgFarmRentOffers.items
    ) {
      let newQuery = prevQuery.listFgFarmRentOffers.items.concat(
        newData.onCreateFgFarmRentOffer
      );
      data.listFgFarmRentOffers.items = newQuery;
      return data;
    }
  };

  renderFarmDocs(docs) {
    return [{}].concat(docs).map((doc, i) =>
      i !== 0 ? (
        <Col key={i} xs={4} md={2} className="txt-center">
          <a
            href={this.getImageUrl(doc)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              key={i}
              src={"/images/PDF-download-icon.jpg"}
              variant="top"
              className="img-fluid img-center"
              alt={doc}
              responsive="true"
            />
            <span>
              <small>{doc.split("-", 3)[2]}</small>
            </span>
          </a>
        </Col>
      ) : (
        ""
      )
    );
  }

  renderFarmTags(tags) {
    return [{}]
      .concat(tags)
      .map((tag, i) =>
        i !== 0 ? (
          <span key={i}>
            {tag
              ? i === 1
                ? tag.substring(tag.indexOf("name=") + 5, tag.indexOf(", id="))
                : ", " +
                  tag.substring(tag.indexOf("name=") + 5, tag.indexOf(", id="))
              : ""}
          </span>
        ) : (
          ""
        )
      );
  }

  renderFarm(farm) {
    return (
      <div className="Farm">
        <Helmet>
          <title>
            {farm.farmSaleType +
              " " +
              farm.farmTotalAcres +
              " Acres in " +
              farm.farmCounty +
              " County, " +
              farm.farmState +
              " - FarmGround.com"}
          </title>
          {!this.isEmptyOrSpaces(farm.attachment) ? (
            <meta
              property="og:image"
              content={this.getImageUrl(farm.attachment)}
            />
          ) : (
            <meta
              property="og:image"
              content="https://www.farmground.com/images/og-image.jpg"
            ></meta>
          )}
          <meta
            property="og:image:alt"
            content="https://www.farmground.com/images/og-image.jpg"
          ></meta>
          <meta
            property="og:title"
            content={
              farm.farmSaleType +
              " " +
              farm.farmTotalAcres +
              " Acres in " +
              farm.farmCounty +
              " County, " +
              farm.farmState +
              " - FarmGround.com"
            }
          ></meta>
          <meta
            property="og:description"
            content={
              farm.farmSaleType +
              " " +
              farm.farmTotalAcres +
              " Acres in " +
              farm.farmCounty +
              ", " +
              farm.farmState +
              " - FarmGround.com"
            }
          ></meta>
          <meta
            property="og:url"
            content={"https://www.farmground.com/listing/" + farm.farmKey}
          ></meta>
          <meta
            name="description"
            content={
              farm.farmSaleType +
              " " +
              farm.farmTotalAcres +
              " Acres in " +
              farm.farmCounty +
              ", " +
              farm.farmState +
              " - FarmGround.com"
            }
          />
        </Helmet>
        <Container>
          <Row className="show-grid">
            <Col xs={12} md={12} className="card-col">
              <Card>
                <span className="card-img-top">
                  {!this.isEmptyOrSpaces(farm.farmVideos) ? (
                    this.renderVideos(farm.farmVideos, farm.attachment)
                  ) : !this.isEmptyOrSpaces(farm.farmPhotos) &&
                    farm.farmPhotos.length > 0 ? (
                    this.renderCarousel(
                      farm.attachment,
                      farm.farmName,
                      farm.farmPhotos
                    )
                  ) : !this.isEmptyOrSpaces(farm.attachment) ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      alt={farm.frmName}
                      src={this.getImageUrl(farm.attachment)}
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      src="./images/logo-blk-900x500.png"
                      alt={farm.frmName}
                    />
                  )}
                </span>
                <Card.Body>
                  <Container>
                    <Row className="show-grid">
                      <Col xs={12} md={8} className="card-col">
                        {this.props.userId &&
                        farm.farmAuctionBidderId ===
                          this.props.userId.replace(/[^0-9]/g, "") ? (
                          <Row className="show-grid">
                            <Col xs={12} md={12} className="card-col">
                              <Alert key={1} variant="success">
                                You are currently the high bidder!
                              </Alert>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <Row className="show-grid">
                          <Col xs={12} md={12} className="card-col">
                            <h5>
                              {this.isEmptyOrSpaces(farm.farmSaleType)
                                ? ""
                                : farm.farmSaleType.trim() + ": "}
                              {this.isEmptyOrSpaces(farm.farmName)
                                ? ""
                                : farm.farmName.trim()}
                            </h5>
                            <h6>
                              <address className="location">
                                <span>
                                  {farm.farmAddress +
                                    ", " +
                                    farm.farmCity +
                                    ", " +
                                    farm.farmState +
                                    " " +
                                    farm.farmPostalCd}
                                </span>
                              </address>
                            </h6>
                            {this.isEmptyOrSpaces(farm.farmTotalPrice) ? (
                              ""
                            ) : (
                              <div className="font-green">
                                {farm.farmTotalPrice.trim()}
                              </div>
                            )}
                            {this.isEmptyOrSpaces(farm.farmTotalAcres) ? (
                              ""
                            ) : (
                              <div className="font-green">
                                {farm.farmTotalAcres.trim() + " Acres (M/L)"}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row className="show-grid">
                          <Col xs={12} md={12} className="card-col">
                            <Row className="row">
                              <Col xs={12} md={12} className="val-col-padding">
                                <strong>County: </strong>
                                {farm.farmCounty + " County"}
                              </Col>
                              <Col xs={12} md={12} className="val-col-padding">
                                <strong>State: </strong>
                                {farm.farmState}
                              </Col>
                              <Col xs={12} md={12} className="val-col-padding">
                                <strong>Acres: </strong>
                                {farm.farmTotalAcres.trim() + " (M/L)"}
                              </Col>
                              <Col xs={12} md={12} className="val-col-padding">
                                <strong>Tags: </strong>
                                {this.renderFarmTags(farm.farmTags)}
                              </Col>
                              {!this.isEmptyOrSpaces(
                                farm.farmListingNumber
                              ) && (
                                <React.Fragment>
                                  <Col
                                    xs={12}
                                    md={12}
                                    className="val-col-padding"
                                  >
                                    <strong>Listing #: </strong>
                                    {farm.farmListingNumber}
                                  </Col>
                                </React.Fragment>
                              )}
                              <Col xs={12} md={12} className="val-col-padding">
                                <strong>Views: </strong>
                                {farm.farmViewed}
                              </Col>
                            </Row>
                            {farm.farmSaleType === "For Rent" ? (
                              <Row className="row">
                                <Col xs={12} md={12}>
                                  <hr />
                                </Col>{" "}
                                {farm.farmListingNumber === 200 && (
                                  <React.Fragment>
                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      <strong>End Date/Time: </strong>
                                      <DateTimeLocal
                                        date={farm.farmEndDateTime}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      <strong>Time Left: </strong>
                                      <Countdown date={farm.farmEndDateTime} />
                                    </Col>

                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      {Date.parse(
                                        new Date(farm.farmEndDateTime)
                                      ) > Date.parse(new Date()) ? (
                                        <Button
                                          href={`/farm/rent/offer/${farm.farmKey}`}
                                          variant="primary"
                                          className="card-footer-btn"
                                          size="sm"
                                        >
                                          Make Offer
                                        </Button>
                                      ) : (
                                        "Sorry, We are no longer taking offers!"
                                      )}
                                    </Col>
                                    <Col xs={12} md={12}>
                                      <hr />
                                    </Col>
                                  </React.Fragment>
                                )}
                                <Col xs={12} md={12}>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <strong>Offer Per Acre History:</strong>
                                    </Col>
                                    <Col xs={12} md={12}>
                                      <small className="text-muted form-text">
                                        Land owner may not neccessary chose the
                                        highest offer. It is up to their
                                        descression to choose the offer they
                                        deem best for their land.
                                      </small>
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Date/Time</strong>
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Offer / Acre</strong>
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Offeror Id</strong>
                                    </Col>
                                  </Row>
                                  <Connect
                                    query={graphqlOperation(
                                      FgFarmRentOfferByFarmKey,
                                      {
                                        farmKey: this.props.match.params.id,
                                        sortDirection: "DESC",
                                      }
                                    )}
                                    subscription={graphqlOperation(
                                      OnCreateFgFarmRentOffer,
                                      {
                                        filter: {
                                          farmKey: {
                                            eq: this.props.match.params.id,
                                          },
                                        },
                                      }
                                    )}
                                    onSubscriptionMsg={
                                      this.onCreatedFgFarmRentOffer
                                    }
                                  >
                                    {({ data, loading, errors }) => {
                                      if (loading) {
                                        return <div>Loading...</div>;
                                      }
                                      if (!data.fgFarmRentOfferByFarmKey)
                                        return;
                                      return this.renderRentOffers(
                                        data.fgFarmRentOfferByFarmKey.items
                                      );
                                    }}
                                  </Connect>
                                </Col>
                              </Row>
                            ) : farm.farmSaleType === "Online Auction" ? (
                              <Row className="row">
                                <Col xs={12} md={12}>
                                  <hr />
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  className="val-col-padding"
                                >
                                  <strong>High Bid / Acre: </strong>
                                  {"$" + farm.farmAuctionBidAmount}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  className="val-col-padding"
                                >
                                  <strong>High Bidder Id: </strong>
                                  {farm.farmAuctionBidderId ? farm.farmAuctionBidderId
                                    .replace(/[^1-7]/g, "")
                                    .replace(/[3-5]/g, "*") : ""}
                                  {farm.isHighBidder ? " (You)" : ""}
                                </Col>
                                {farm.farmListingNumber === 200 && (
                                  <React.Fragment>
                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      <strong>End Date/Time: </strong>
                                      <DateTimeLocal
                                        date={farm.farmEndDateTime}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      <strong>Time Left: </strong>
                                      <Countdown date={farm.farmEndDateTime} />
                                    </Col>
                                    <Col xs={12} md={12}>
                                      {Date.parse(
                                        new Date(farm.farmEndDateTime)
                                      ) > Date.parse(new Date()) ? (
                                        <Button
                                          href={`/farm/auction/bid/${farm.farmKey}`}
                                          variant="primary"
                                          className="card-footer-btn"
                                          size="sm"
                                        >
                                          Bid Now
                                        </Button>
                                      ) : (
                                        "Sorry, This auction has ended!"
                                      )}
                                    </Col>
                                  </React.Fragment>
                                )}
                                <Col xs={12} md={12}>
                                  <hr />
                                </Col>
                                <Col xs={12} md={12}>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <strong>Bid History</strong>
                                    </Col>
                                    <Col xs={12} md={12}>
                                      <br />
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Date/Time</strong>
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Bid / Acre</strong>
                                    </Col>
                                    <Col xs={4} md={4}>
                                      <strong>Bidder Id</strong>
                                    </Col>
                                  </Row>
                                  <Connect
                                    query={graphqlOperation(
                                      FgFarmAuctionBidByFarmKey,
                                      {
                                        farmKey: this.props.match.params.id,
                                        sortDirection: "DESC",
                                      }
                                    )}
                                    subscription={graphqlOperation(
                                      OnCreateFgFarmAuctionBid,
                                      {
                                        filter: {
                                          farmKey: {
                                            eq: this.props.match.params.id,
                                          },
                                        },
                                      }
                                    )}
                                    onSubscriptionMsg={
                                      this.onCreatedFgFarmAuctionBid
                                    }
                                  >
                                    {({ data, loading, errors }) => {
                                      if (loading) {
                                        return <div>Loading...</div>;
                                      }
                                      if (!data.fgFarmAuctionBidByFarmKey)
                                        return;

                                      return this.renderAuctionBids(
                                        data.fgFarmAuctionBidByFarmKey.items
                                      );
                                    }}
                                  </Connect>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="row">
                                <Col xs={12} md={12}>
                                  <hr />
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  className="val-col-padding"
                                >
                                  <strong>Total Price: </strong>
                                  {farm.farmTotalPrice}
                                </Col>
                                {!this.isEmptyOrSpaces(
                                  farm.farmPricePerAcre
                                ) && (
                                  <React.Fragment>
                                    <Col
                                      xs={12}
                                      md={12}
                                      className="val-col-padding"
                                    >
                                      <strong>Price Per Acre: </strong>
                                      {farm.farmPricePerAcre}
                                    </Col>
                                  </React.Fragment>
                                )}
                                <Col xs={12} md={12}>
                                  <hr />
                                </Col>
                              </Row>
                            )}
                          </Col>
                          <Col xs={12} md={12} className="card-col">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: farm.farmDetails,
                              }}
                            />
                          </Col>
                          <Col xs={12} md={12} className="card-col">
                            <strong>Map:</strong>
                          </Col>
                          <Col xs={12} md={12} className="card-col">
                            <div className="mapouter">
                              <div className="gmap_canvas">
                                {!this.isEmptyOrSpaces(
                                  farm.farmGoogleMapLink
                                ) && farm.farmGoogleMapLink.includes("@") ? (
                                  <iframe
                                    title="Farm Map"
                                    width="100%"
                                    height="500"
                                    id="gmap_canvas"
                                    src={
                                      "https://maps.google.com/maps?q=" +
                                      farm.farmGoogleMapLink
                                        .split("@")[1]
                                        .split(",")[0] +
                                      "," +
                                      farm.farmGoogleMapLink
                                        .split("@")[1]
                                        .split(",")[1] +
                                      "&t=k&z=15&ie=UTF8&iwloc=&output=embed"
                                    }
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                  ></iframe>
                                ) : (
                                  <iframe
                                    title="Farm Map"
                                    width="100%"
                                    height="500"
                                    id="gmap_canvas"
                                    src={
                                      "https://maps.google.com/maps?q=" +
                                      farm.farmAddress +
                                      ", " +
                                      farm.farmCity +
                                      ", " +
                                      farm.farmState +
                                      " " +
                                      farm.farmPostalCd +
                                      "&t=k&z=15&ie=UTF8&iwloc=&output=embed"
                                    }
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                  ></iframe>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {!this.isEmptyOrSpaces(farm.farmVideos) ? (
                          !this.isEmptyOrSpaces(farm.attachment) ? (
                            <Row className="show-grid">
                              <Col xs={12} md={12} className="card-col">
                                <hr />
                              </Col>
                              <Col xs={12} md={12} className="card-col">
                                <strong>Photos</strong>
                              </Col>
                              <Col xs={12} md={12} className="card-col">
                                {this.renderCarousel(
                                  null,
                                  farm.farmName,
                                  farm.farmPhotos
                                )}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {farm.farmDocs && (
                          <Row className="show-grid">
                            <Col xs={12} md={12} className="card-col">
                              <hr />
                            </Col>
                            <Col xs={12} md={12} className="card-col">
                              <strong>Files</strong>
                            </Col>
                            {this.renderFarmDocs(farm.farmDocs)}
                          </Row>
                        )}
                      </Col>
                      <Col xs={12} md={4} className="">
                        {farm.farmAgentName && (
                          <Row className="show-grid">
                            <Col xs={12} md={12} className="card-col">
                              <ContactSellerFarm
                                farm={farm}
                                agent={!this.isEmptyOrSpaces(farm.orgKey)}
                              ></ContactSellerFarm>
                            </Col>
                            <Col xs={12} md={12} className="card-col">
                              <strong>Contact Information</strong>
                            </Col>
                            <Col xs={12} md={12}>
                              <strong>
                                {!this.isEmptyOrSpaces(farm.orgKey) && "Agent "}
                                Name:
                              </strong>
                            </Col>
                            <Col xs={12} md={12}>
                              {farm.farmAgentName}
                            </Col>
                            <Col xs={12} md={12}>
                              <br />
                            </Col>
                            {!this.isEmptyOrSpaces(
                              farm.farmAgentLicensedIn
                            ) && (
                              <React.Fragment>
                                <Col xs={12} md={12}>
                                  <strong>State(s) Licensed:</strong>
                                </Col>
                                <Col xs={12} md={12}>
                                  {farm.farmAgentLicensedIn}
                                </Col>
                                <Col xs={12} md={12}>
                                  <br />
                                </Col>
                              </React.Fragment>
                            )}
                            {!this.isEmptyOrSpaces(farm.farmAgentWorkPhone) && (
                              <React.Fragment>
                                <Col xs={12} md={12}>
                                  <strong>Work Phone:</strong>
                                </Col>
                                <Col xs={12} md={12}>
                                  {farm.farmAgentWorkPhone}
                                </Col>
                                <Col xs={12} md={12}>
                                  <br />
                                </Col>
                              </React.Fragment>
                            )}
                            {!this.isEmptyOrSpaces(farm.farmAgentCellPhone) && (
                              <React.Fragment>
                                <Col xs={12} md={12}>
                                  <strong>Cell Phone:</strong>
                                </Col>
                                <Col xs={12} md={12}>
                                  {farm.farmAgentCellPhone}
                                </Col>
                                <Col xs={12} md={12}>
                                  <br />
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  onUpdatedFgFarm = (prevQuery, newData) => {
    let data = {
      listFgFarms: {
        items: [],
      },
    };
    if (prevQuery && prevQuery.listFgFarms && prevQuery.listFgFarms.items) {
      data.listFgFarms.items[0] = newData.onUpdateFgFarm;
      return data;
    }
  };

  render() {
    return (
      <Connect
        query={graphqlOperation(ListFgFarmByFarmKey, {
          farmKey: this.props.match.params.id,
        })}
        subscription={graphqlOperation(OnUpdateFgFarm, {
          filter: {
            farmKey: {
              eq: this.props.match.params.id,
            },
          },
        })}
        onSubscriptionMsg={this.onUpdatedFgFarm}
      >
        {({ data, loading, errors }) => {
          if (loading) {
            return <div>Loading...</div>;
          }
          if (!data.fgFarmByFarmKey) return;
          let farm = data.fgFarmByFarmKey.items[0];
          return this.renderFarm(farm);
        }}
      </Connect>
    );
  }
}
