import React, { Component } from "react";
import "./ContactUsConfirm.css";

export default class ContactUsConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null
    };
  }
  render() {
    return (
      <div className="ContactUsConfirm">
        <h1>Contact Us Confirmation</h1>
        <br />
        <h2>Your message has been received!</h2>
        <br />
        <h4>
          Thank you for contacting us. We will respond to your message as soon
          as possible.
        </h4>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
