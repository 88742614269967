import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Form, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    var email = this.state.email.toLowerCase();

    try {
      await this.resetPassword(email);

      this.props.history.push("/login");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  resetPassword(username) {
    Auth.forgotPassword(username)
      .then(data => console.log(data))
      .catch(err => console.log(err));

    var verificationCode = prompt(
      "Please input password reset verification code that was emailed to you.  Please check your junk email folder if you do not see the email in your inbox.  It may take 10 to 15 minutes for this email to arrive.",
      ""
    );
    var newPassword = prompt("Enter new password ", "");
    this.confirmPassword(username, verificationCode, newPassword);
  }

  // confirmPassword can be separately built out as follows...
  confirmPassword(username, verificationCode, newPassword) {
    Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  render() {
    return (
      <div className="Login">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="email" bssize="large">
            <Form.Label>Email</Form.Label>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <LoaderButton
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Reset Password"
            loadingText="Logging in…"
          />
        </Form>
        <div className="msg-center">
          <br />
          Don't have an account yet? <a href="/signUp">Sign Up Here!</a> It's
          Free!
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
